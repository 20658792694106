import React, { Component } from "react";
import NACustomerOrders from "../components/customerHandling/NACustomerOrders";
import { matchPath, Redirect, Route, Router, Switch } from "react-router-dom";
import withIdleTimer from "../common/WithIdleTimer";
import { invalidateSessionAndRedirect } from "../common/InvalidateSession";
import {
  ACCESSORIES_KEY,
  CANADA_ENGLISH,
  DELIVERY_TOOLS,
  DELIVERY_TOOLS_QR,
  ENGLISH,
  LINCOLN_ENGLISH,
  MODEL_E_KEY,
  PAGE,
  QUERY,
  MODEL_E_ORDER_DETAILS_KEY,
  SEARCH,
  SALESCODE_KEY,
} from "../common/Constants";
import NADealer from "./pure/NADealer";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import AccessoriesWrapper from "../components/accessories/AccessoriesWrapper";
import DealerGroupingClient from "../clients/DealerGroupingClient";
import { publish } from "../common/PubSub";
import ErrorScreen from "../errors/ErrorScreen";
import DealerDashboard from "../components/dashboard/dealerDashBoard/DealerDashboard";
import ProtectedRoute from "../common/ProtectedRoute";
import Footer from "../shared/Footer/Footer";
import LocalizedInfoClient from "../clients/LocalizedInfoClient";
import { logMsg } from "../common/Logger";
import PageError from "../errors/PageError";
import "../styles/sharedStyles.scss";
import BusinessSettings from "../components/businessSetting/BusinessSettings";
import Help from "../components/help/Help";
import PendingActionClient from "../clients/PendingActionClient";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../utils/EmpUtil";
import NADealerReporting from "../components/reporting/NADealerReporting";
import { withTranslation } from "react-i18next";
import Reporting from "../components/reporting/Reporting";
import DeliveryTools from "../components/deliveryTools/DeliveryTools";
import * as AnalyticsService from "../shared/services/analytics/AnalyticsService";
import SearchResultsWrapper from "../components/customerHandling/search/SearchResultsWrapper";
import ReleaseNotes from "../components/releaseNotes/ReleaseNotes";
import styles from "./App.module.scss";
import OrderDetailsPage from "../orders/order-details/OrderDetailsPage";
import { ModalProvider } from "../shared/components/EMPModal/ModalContext";

class NADealerApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      user: new NADealer(
        this.props.token,
        this.props.userDetails,
        this.props.history
      ),
      dealerGroupError: false,
      shouldNavigationBeRestricted: false,
    };
    this.displayPage = sessionStorage.getItem(PAGE);
    this.searchQuery = sessionStorage.getItem(QUERY);
    this.salesCode = sessionStorage.getItem(SALESCODE_KEY);
    this.localizedInfo = {};
    this.dealerChanged = this.dealerChanged.bind(this);
    this.getPendingActions = this.getPendingActions.bind(this);

    // Listening for route change
    this.props.history.listen(() => this.setNavigationRestriction());
  }

  componentDidMount() {
    AnalyticsService.initAnalytics();
    this.getLocalizedInfo();
  }

  getLocalizedInfo() {
    LocalizedInfoClient.getLocalizedInfo(this.state.user)
      .then((response) => {
        this.localizedInfo = Object.assign(
          {},
          { ...this.localizedInfo },
          { ...response }
        );
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        this.lang = localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : IS_CANADA_MARKET(this.state.user.market)
          ? CANADA_ENGLISH
          : ENGLISH;

        if (IS_USA_MARKET(this.state.user.market)) {
          if (!IS_FORD_BRAND(this.state.user.brand)) {
            this.lang = LINCOLN_ENGLISH;
            this.props.i18n.changeLanguage(this.lang);
            this.state.user.setLanguageCode(this.props.i18n.language);
          }
        }
        this.getDealerGroup();
      });
  }

  getDealerGroup = () => {
    DealerGroupingClient.getDealerGroup(
      this.state.user,
      this.salesCode,
      sessionStorage.getItem(PAGE)
    )
      .then((data) => {
        let dealer;
        this.state.user.setDealerGroup(data);

        if (this.state.user.isCanadaDealer()) {
          if (data.length > 1) {
            data = data.filter((dealer) =>
              dealer.commonId.endsWith(this.state.user.brand)
            ); //Filtering FORD brands dealers only for CAN WBDO
            dealer = data.filter(
              (dealer) =>
                dealer.dealerType === "MDLR" || dealer.dealerType === "HUB"
            )[0];
          } else {
            dealer = data[0];
          }
        } else {
          dealer = data.filter(
            (dealer) => dealer.salesCode === this.salesCode
          )[0];
        }
        this.updateCurrentDealer(dealer, true);
      })
      .catch(() => {
        this.setState({ dealerGroupError: true });
      })
      .finally(() => {
        const hasPendingActionPermission =
          this.state.user.permissions.rolePermissions.hasOwnProperty([
            "pendingActionQueue:view",
          ]);
        if (
          IS_CANADA_MARKET(this.state.user.market) &&
          hasPendingActionPermission
        ) {
          this.determinePendingActionStatuses();
          this.getPendingActions().finally(() =>
            this.performFinalActions.call(this)
          );
        } else {
          this.performFinalActions();
        }
      });
  };

  performFinalActions() {
    this.setNavigationRestriction();
    this.setState({ renderApp: true });
    this.props.hideOrShow(false);
  }

  setNavigationRestriction = () => {
    const isAllowedPath = this.isCurrentPathAllowedToShowHeaderAndFooter(
      this.props.history.location.pathname
    );
    this.setState({ shouldNavigationBeRestricted: !isAllowedPath });
  };

  isCurrentPathAllowedToShowHeaderAndFooter = (currentPathName) => {
    const paths = [
      "/dashboard",
      "/pricing/accessories",
      "/",
      "/dealer-reporting",
      "/business-settings",
      "/help",
      "/error",
      "/reporting",
      "/delivery-tools",
      "/delivery-tools/download",
      "/delivery-tools/times",
      "/delivery-tools/tasks",
      "/ecommerce-releases",
      "/customer-handling",
    ];

    if (paths.includes(currentPathName)) {
      return true;
    } else {
      const allowedOrderDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/order-details/:id`,
      });

      const allowedPurchaseDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/purchase-details/:id`,
      });

      const allowedModelEOrderDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/model-e-details/:id`,
      });

      const allowedCustomerHandlingTabs = matchPath(currentPathName, {
        path: `/customer-handling`,
      });

      const allowedSearchResultsWrapper = matchPath(currentPathName, {
        path: `/search/:keyword`,
      });

      const allowedNewOrderDetails = matchPath(currentPathName, {
        path: `/orders/:orderid`,
      });
      return (
        !!allowedOrderDetails ||
        !!allowedPurchaseDetails ||
        !!allowedModelEOrderDetails ||
        !!allowedCustomerHandlingTabs ||
        !!allowedSearchResultsWrapper ||
        !!allowedNewOrderDetails
      );
    }
  };

  updateCurrentDealer(dealer, showAll = false) {
    this.setState(
      { user: this.state.user.updateCurrentDealer(dealer, showAll) },
      () => {
        this.state.user.setLanguageCode(this.props.i18n.language);
      }
    );
  }

  dealerChanged(dealer, showAll = false) {
    this.updateCurrentDealer(dealer, showAll);
  }

  changeBrand = (brandCode) => {
    this.state.user.updateBrand(brandCode);
    let dealer = this.state.user.dealerGroup.filter((dealer) =>
      IS_USA_MARKET(this.state.user.market)
        ? dealer.salesCode.startsWith(brandCode)
        : dealer.commonId.endsWith(brandCode)
    )[0];
    this.setState(
      { user: this.state.user.updateCurrentDealer(dealer, false) },
      () => {
        publish("brandChanged", brandCode);
      }
    );
    if (brandCode === "F") {
      dealer = this.state.user.dealerGroup.filter(
        (dealer) => dealer.dealerType === "HUB" || dealer.dealerType === "MDLR"
      )[0];
      this.dealerChanged(dealer, true);
    }
    if (IS_USA_MARKET(this.state.user.market)) {
      if (brandCode === "L") {
        this.props.i18n.changeLanguage(LINCOLN_ENGLISH);
      } else {
        this.props.i18n.changeLanguage(ENGLISH);
      }
    }
  };

  determinePendingActionStatuses() {
    let hasNscCompletedBasicPAs = false;
    PendingActionClient.getHasNscCompletedPA(this.state.user, 1)
      .then((response) => {
        hasNscCompletedBasicPAs = response;
      })
      .then(() => {
        const user = this.state.user;
        user.hasNscCompletedBasicPAs = hasNscCompletedBasicPAs;
        this.setState({ user });
      });
  }

  async getPendingActions(dealer = this.state.user, updatePAOnly = true) {
    await PendingActionClient.getDealerPendingActionsNotifications(dealer).then(
      (data) => {
        if (updatePAOnly) {
          this.state.user.updatePendingActions(data.pendingActions);
        } else {
          this.setState({ user: dealer }, () => {
            this.state.user.updatePendingActions(data.pendingActions);
          });
        }
      }
    );
  }

  updateDealerPhoneAndEmail(dealer, email, phone) {
    const index = this.state.user.dealerGroup.indexOf(
      this.state.user.dealerGroup.filter(
        (d) => d.completeDealerId === dealer.dealerId
      )[0]
    );

    const dealerGroupCopy = JSON.parse(
      JSON.stringify(this.state.user.dealerGroup)
    );
    dealerGroupCopy[index].emailAddress = email;
    dealerGroupCopy[index].phoneNumber = phone;

    this.state.user.setDealerGroup(dealerGroupCopy);
  }

  updateDealerEmpMembership(dealer) {
    const index = this.state.user.dealerGroup.indexOf(
      this.state.user.dealerGroup.filter(
        (d) => d.completeDealerId === dealer.completeDealerId
      )[0]
    );

    const dealerGroup = this.state.user.dealerGroup;
    dealerGroup[index].status = dealer.status;

    this.state.user.setDealerGroup(dealerGroup);
  }

  isMainViewingSDLR = (dealer) => {
    // Returns true if a main dealer is currently viewing an SDLR
    return this.state.user.isLoggedInUserMDLR && dealer.dealerType === "SDLR";
  };

  render() {
    return this.state.renderApp ? (
      !this.state.user.salesCode && !this.state.dealerGroupError ? (
        invalidateSessionAndRedirect()
      ) : (
        <Router history={this.props.history}>
          <section className={styles.fullHeightLayout}>
            <div className={styles.fullHeightItem}>
              {!this.state.dealerGroupError &&
                !this.state.shouldNavigationBeRestricted && (
                  <GlobalNavBar
                    {...this.props}
                    urlPath={window.location.pathname}
                    user={this.state.user}
                    changeBrand={this.changeBrand}
                    i18n={this.props.i18n}
                  />
                )}
            </div>
            <div className={styles.fullHeightItem}>
              <Switch>
                <ProtectedRoute
                  exact
                  path="/dashboard"
                  component={withIdleTimer(DealerDashboard)}
                  perform={["dashBoardPage"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                    onDealerChanged: this.dealerChanged,
                    lang: this.lang,
                  }}
                  parentProps={this.props}
                  condition={true}
                />
                <ProtectedRoute
                  exact
                  path="/pricing/accessories"
                  component={withIdleTimer(AccessoriesWrapper)}
                  perform={["accessoriesPage"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                    changeBrand: this.changeBrand,
                    onDealerChanged: this.dealerChanged,
                    localizedInfo: this.localizedInfo,
                    lang: this.lang,
                  }}
                  parentProps={this.props}
                  condition={true}
                />
                <ProtectedRoute
                  exact
                  path="/customer-handling"
                  component={withIdleTimer(NACustomerOrders)}
                  perform={["customerHandlingPage"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                    localizedInfo: this.localizedInfo,
                    onDealerChanged: this.dealerChanged,
                    lang: this.lang,
                  }}
                  parentProps={this.props}
                  condition={true}
                />

                <Route path="/customer-handling/:orderId">
                  <OrderDetailsPage />
                </Route>

                <ProtectedRoute
                  exact
                  path="/business-settings"
                  component={withIdleTimer(BusinessSettings)}
                  perform={["businessSettingsPage"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  condition={true}
                  customprops={{
                    ...this.props,
                    isMainViewingSDLR: this.isMainViewingSDLR,
                    getPendingActions: this.getPendingActions.bind(this),
                    user: this.state.user,
                    lang: this.lang,
                    dealerGroup: this.state.user.dealerGroup,
                    localizedInfo: this.localizedInfo,
                    updateDealerPhoneAndEmail:
                      this.updateDealerPhoneAndEmail.bind(this),
                    updateDealerEmpMembership:
                      this.updateDealerEmpMembership.bind(this),
                    onDealerChanged: this.dealerChanged.bind(this),
                    permissions: this.state.user.permissions.rolePermissions,
                  }}
                />
                <ProtectedRoute
                  exact
                  path="/dealer-reporting"
                  component={withIdleTimer(NADealerReporting)}
                  perform={["dealerReporting:view"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                    lang: this.lang,
                  }}
                  parentProps={this.props}
                  condition={this.state.user.userType === "na_dealer"}
                />
                <ProtectedRoute
                  exact
                  path="/reporting"
                  component={withIdleTimer(Reporting)}
                  perform={["reportingPage"]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                    lang: this.lang,
                  }}
                  parentProps={this.props}
                  condition={this.state.user.userType === "na_dealer"}
                />
                <ProtectedRoute
                  path="/delivery-tools"
                  component={withIdleTimer(DeliveryTools)}
                  perform={[]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{ user: this.state.user, lang: this.lang }}
                  parentProps={this.props}
                  condition={
                    IS_USA_MARKET(this.state.user.market) &&
                    IS_FORD_BRAND(this.state.user.brand)
                  }
                />
                <Route
                  path="/ecommerce-releases"
                  component={withIdleTimer(() => (
                    <ReleaseNotes {...this.props} user={this.state.user} />
                  ))}
                />
                <ProtectedRoute
                  exact
                  path="/search/:keyword"
                  component={withIdleTimer(SearchResultsWrapper)}
                  perform={[]}
                  permissions={this.state.user.permissions.rolePermissions}
                  customprops={{
                    user: this.state.user,
                  }}
                  parentProps={this.props}
                  condition={true}
                />
                <Route
                  exact
                  path="/"
                  render={() =>
                    this.displayPage === ACCESSORIES_KEY ? (
                      <Redirect
                        to={{
                          pathname: "/pricing/accessories",
                        }}
                      />
                    ) : this.displayPage === MODEL_E_KEY ? (
                      <Redirect
                        to={{
                          pathname: "/customer-handling/model_e_orders",
                        }}
                      />
                    ) : this.displayPage === SEARCH ? (
                      <Redirect
                        to={{
                          pathname: `/search/${this.searchQuery}`,
                        }}
                      />
                    ) : this.displayPage === MODEL_E_ORDER_DETAILS_KEY ? (
                      <Redirect
                        to={{
                          pathname: `/dealer/${this.state.user.commonId}/customer-reservations/model-e-details/${this.searchQuery}`,
                        }}
                      />
                    ) : this.displayPage === DELIVERY_TOOLS ? (
                      <Redirect
                        to={{
                          pathname: "/delivery-tools",
                        }}
                      />
                    ) : this.displayPage === DELIVERY_TOOLS_QR ? (
                      <Redirect
                        to={{
                          pathname: "/delivery-tools",
                          search: "?qrcid=qr-deliverydaytool",
                        }}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/dashboard",
                        }}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/help"
                  component={withIdleTimer(() => (
                    <Help {...this.props} market={this.state.user.market} />
                  ))}
                />
                <Route
                  exact
                  path="/error"
                  render={(props) => <ErrorScreen {...props} />}
                />
                <Route
                  render={() => (
                    <PageError
                      code="404 Not Found"
                      body="This page does not exist.  Please go back and try again."
                    />
                  )}
                />
              </Switch>
            </div>
            <div className={styles.fullHeightItem}>
              {!this.state.shouldNavigationBeRestricted && (
                <span className="noPrint">
                  <ModalProvider>
                    <Footer
                      user={this.state.user}
                      footerTitle={this.props.t("Footer.baseTitle")}
                      helpSupportTitle={this.props.t("Footer.help")}
                      permissions={this.state.user.permissions}
                    />
                  </ModalProvider>
                </span>
              )}
            </div>
          </section>
        </Router>
      )
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(NADealerApp);
