import { cancellationReasons } from "../constants/CancellationReasonConstants";

function getMenuItems({ cancellationReasons }) {
  const menuItems = cancellationReasons.map((item) => ({
    text: item.displayText,
    value: item.displayText,
  }));

  return [
    {
      text: "Select one",
      value: "",
      disabled: true,
    },
    ...menuItems,
  ];
}

const useCancellationReasons = () => {
  return getMenuItems({ cancellationReasons });
};

export default useCancellationReasons;
