export const FETCH_TASKS = "FETCH_TASKS";
export const FILTER_TASKS = "FILTER_TASKS";
export const SET_TASKS = "SET_TASKS";

export const toggleButtonItems = [
  {
    label: "EV",
    value: "evTasks",
  },
  {
    label: "ICE and Hybrid",
    value: "iceTasks",
  },
];
