import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { RESERVATION_ACTION_URL, RESERVATION_BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class CustomerReservationsClient {
  static baseUrl = RESERVATION_BASE_URL;
  static actionUrl = RESERVATION_ACTION_URL;

  static getCustomerOrders(token, dealerCommonId, tab, lang, pageNumber, sort) {
    dealerCommonId = dealerCommonId.split("%7C").join("|");
    const url = `${this.baseUrl}/dealer/customer-reservations`;

    return axios
      .post(
        url,
        {
          commonIds: dealerCommonId,
          currentPage: pageNumber,
          sort: sort,
          lang: lang,
        },
        getHeaders(token)
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static actionOnOrder(token, dealerCommonId, dealerActionInfo, dealerUserId) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/customer-orders?dealerUserId=${dealerUserId}`;

    return axios.post(url, dealerActionInfo, getHeaders(token));
  }

  static acceptRequestAndDeposit(
    token,
    dealerCommonId,
    dealerActionInfo,
    dealerUserId
  ) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/accept-order-deposit?dealerUserId=${dealerUserId}`;

    return axios.post(url, dealerActionInfo, getHeaders(token));
  }

  static updateOrder(token, dealerCommonId, dealerActionInfo, dealerUserId) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/customer-orders?dealerUserId=${dealerUserId}`;

    return axios
      .post(url, dealerActionInfo, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerAction(
    token,
    dealerCommonId,
    dealerActionInfo,
    dealerUserId
  ) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/save-dealer-actions?dealerUserId=${dealerUserId}`;

    return axios
      .post(url, dealerActionInfo, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getAppointmentStatus(token, dealerCommonId, orderId) {
    const commonId = dealerCommonId
      ? dealerCommonId.split("|").join("%7C")
      : "";
    const url = `${this.actionUrl}/dealer/${commonId}/get-appointment-status?orderId=${orderId}`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getAppointmentStatusMultiple(token, dealerCommonId, orderIds) {
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/get-appointment-status-multiple`;

    return axios
      .post(url, orderIds, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateHandOver(token, handOverData, orderId) {
    const url = `${this.baseUrl}/dealer/customer-reservations/handover-update/${orderId}`;

    return axios
      .post(url, handOverData, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getCancelOrderReasons(
    token,
    lang,
    type,
    // purchaseRequest = false,
    // consentReasons = false,
    // reservationReasons = false,
    // vehicleHoldReasons = false,
    commonId = null
  ) {
    let url = `${this.baseUrl}/dealer/cancelOrderReasons?lang=${lang}`;

    if (type === "all") {
      url = url + `&purchaseRequest=false`;
    }

    if (type === "purchaseRequest") {
      url = url + `&purchaseRequest=true`;
    }

    if (type === "consentReasons") {
      url = url + `&consentReasons=true`;
    }

    if (type === "reservationReasons") {
      url = url + `&reservationReasons=true`;
    }
    if (type === "vehicleHoldReasons") {
      url = url + `&vehicleHoldReasons=true`;
    }

    if (commonId) {
      const dealerCommonId = commonId.split("|").join("%7C");
      url = url + `&commonId=${dealerCommonId}`;
    }

    return axios.get(url, getHeaders(token));
  }

  static getInventoryWindowSticker(token, dealerPACode, vin) {
    if (dealerPACode && vin) {
      const url = `${this.baseUrl}/dealer/window-sticker?dealerPACode=${dealerPACode}&vin=${vin}`;
      return axios
        .get(url, getHeaders(token))
        .then((response) => response.data)
        .catch((error) => {
          if (is401(error)) {
            AuthenticationFailureHandler.execute();
          }
          return Promise.reject(error);
        });
    }
  }

  static getWindowSticker(token, paCode, vin) {
    const url = `${this.baseUrl}/dealer/window-sticker?dealerPACode=${paCode}&vin=${vin}`;
    return axios.get(url, getHeaders(token));
  }

  static async fetchOrderDetails(
    token,
    orderId,
    commonId,
    isCrc,
    lang,
    market
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/${orderId}?lang=${lang}`;
    url = url + (isCrc ? "&market=" + market : "&dealerId=" + commonId);
    return await axios.get(encodeURI(url), getHeaders(token));
  }

  static async getCustomerOrderDetails(
    user,
    orderId,
    commonId,
    isCrc,
    lang,
    market
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/${orderId}?lang=${lang}`;
    url = url + (isCrc ? "&market=" + market : "&dealerId=" + commonId);
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async getPricingStack(token, orderId, market, commonId, lang) {
    const dealerCommonId = commonId ? commonId.split("%7C").join("|") : "";

    let url = `${
      this.baseUrl
    }/dealer/customer-reservations-pricing/${orderId}?lang=${lang ? "en" : ""}`;
    url = commonId ? url + ("&dealerId=" + dealerCommonId) : url;
    url = market ? url + ("&market=" + market) : url;
    return await axios.get(encodeURI(url), getHeaders(token));
  }

  static async getOrderPricingStack(user, orderId, market, commonId, lang) {
    const dealerCommonId = commonId ? commonId.split("%7C").join("|") : "";

    let url = `${
      this.baseUrl
    }/dealer/customer-reservations-pricing/${orderId}?lang=${lang ? "en" : ""}`;
    url = commonId ? url + ("&dealerId=" + dealerCommonId) : url;
    url = market ? url + ("&market=" + market) : url;
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async searchCustomerReservations(
    searchQuery,
    user,
    lang,
    commonIds,
    market,
    lastName = null,
    zipCode = null,
    isLastNameSearch
  ) {
    let url;

    if (lastName) {
      if (zipCode) {
        url = `${this.baseUrl}/dealer/customer-reservations/search?lastName=${lastName}&zipCode=${zipCode}&lang=${lang}`;
      } else {
        url = `${this.baseUrl}/dealer/customer-reservations/search?lastName=${lastName}&lang=${lang}`;
      }
    } else if (isLastNameSearch) {
      url = `${this.baseUrl}/dealer/customer-reservations/search?query=${searchQuery}&isLastNameSearch=${isLastNameSearch}&lang=${lang}`;
    } else {
      url = `${this.baseUrl}/dealer/customer-reservations/search?query=${searchQuery}&lang=${lang}`;
    }

    if (commonIds) {
      url = url + `&commonIds=${commonIds.split("|").join("%7C")}`;
    }

    if (market) {
      url = url + `&market=${market}`;
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async saveValuation(valuationRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/trade-in`;

    return axios
      .put(
        url,
        {
          expiryDate: valuationRequest.expiryDate,
          valuationAmount: valuationRequest.valuationAmount,
          appraisalId: valuationRequest.appraisalId,
          orderId: valuationRequest.orderId,
        },
        getHeaders(valuationRequest.user.token)
      )
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async sendToUVIS(token, uvisRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/uvis`;
    return axios.post(url, uvisRequest, getHeaders(token));
  }
}
