import React, { createRef, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../DeliveryTools.module.scss";
import { Box, Typography, Link } from "@mui/material";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import CheckIcon from "@mui/icons-material/Check";
import ddtqr from "./img/ddt_qr.jpg";
import iPad22x from "./img/ipad_final2@2x.jpg";
import iPad2x from "./img/ipad_final@2x.jpg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { withTranslation } from "react-i18next";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";
import { ModalContext } from "../../../../shared/components/EMPModal/ModalContext";
import DownloadInstructions from "../../../../shared/components/EMPModal/ModalFragments/DownloadInstructions";
import { Link as RouterLink } from "react-router-dom";

const analyticsPageName = "fv:emp:delivery tools";

const Hero = (props) => {
  const { t } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        columnGap: 4,
        py: 8,
        backgroundColor: "#f2f2f2",
        borderRadius: "32px",
      }}
    >
      <Box
        sx={{
          gridColumn: { xs: "2 / 12", sm: "2 / 12", md: "3 / 11" },
          mt: 4,
          zIndex: 0,
          textAlign: "center",
        }}
      >
        <Typography variant="h1" sx={{ mb: 10 }}>
          {t("DeliveryTools.hero.title")}
        </Typography>

        <Typography variant="body1" sx={{ mb: 7 }}>
          {t("DeliveryTools.hero.subTitle")}
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          {t("DeliveryTools.hero.scan")}
        </Typography>

        <Box
          sx={{
            mb: 4,
          }}
        >
          <Box>
            <img src={ddtqr} width="320" />
          </Box>
        </Box>

        <Typography variant="body1" component="div">
          {t("DeliveryTools.hero.download.0")}
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ display: "inline" }}
          >
            {" "}
            {t("DeliveryTools.hero.download.1")}{" "}
          </Typography>
          {t("DeliveryTools.hero.download.2")}
        </Typography>
      </Box>
    </Box>
  );
};

const Checklist = (props) => {
  const { text } = props;

  return (
    <Grid container spacing={10} justifyContent="center" sx={{ mb: 20 }}>
      {text.map((item) => {
        return (
          <Grid
            item
            key={item.title}
            wrap="nowrap"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            container
          >
            <Box mr={6} style={{ color: "#2575fc" }}>
              <CheckIcon color="inherit" />
            </Box>
            <Box>
              <Typography variant="h3" sx={{ mb: 4 }}>
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                sx={
                  item.linkToReporting
                    ? { display: "inline" }
                    : { display: "block" }
                }
              >
                {item.subtitle}
              </Typography>
              {item.linkToReporting && (
                <Link
                  component={RouterLink}
                  to="/dealer-reporting"
                  variant="body1"
                  underline="always"
                  color="text.primary"
                  sx={{ ml: 1 }}
                >
                  Find it here
                </Link>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Slideshow = (props) => {
  const { slides } = props;
  return (
    <Grid container alignItems="center">
      <Grid item sx={{ width: 480 }}>
        <Box>
          <Fade transitionDuration={500} duration={4000} arrows={false}>
            {slides.map((slide, index) => (
              <div key={index}>
                <img style={{ width: "100%" }} src={slide.image} alt="slide" />
              </div>
            ))}
          </Fade>
        </Box>
      </Grid>
    </Grid>
  );
};

const DownloadTool = ({ t, user }) => {
  const downloadButtonRef = createRef();
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    // initial page load
    AnalyticsService.trackPageLoadEvent(analyticsPageName, user);
  }, []);

  const modalOpenAnalytics = () => {
    AnalyticsService.trackOnClickEvent(
      analyticsPageName,
      "emp:delivery tools:content:view download instructions",
      "fv:emp:delivery tools:content action",
      user
    );
  };

  const checklist = [
    {
      title: t("DeliveryTools.checklist.1.title"),
      subtitle: t("DeliveryTools.checklist.1.subtitle"),
    },
    {
      title: t("DeliveryTools.checklist.2.title"),
      subtitle: t("DeliveryTools.checklist.2.subtitle"),
      linkToReporting: true,
    },
    {
      title: t("DeliveryTools.checklist.3.title"),
      subtitle: t("DeliveryTools.checklist.3.subtitle"),
    },
    {
      title: t("DeliveryTools.checklist.4.title"),
      subtitle: t("DeliveryTools.checklist.4.subtitle"),
    },
  ];

  const npsData = [
    {
      title: t("DeliveryTools.nps.1.number"),
      subtitle: t("DeliveryTools.nps.1.subtitle"),
    },
    {
      title: t("DeliveryTools.nps.2.number"),
      subtitle: t("DeliveryTools.nps.2.subtitle"),
    },
    {
      title: t("DeliveryTools.nps.3.number"),
      subtitle: t("DeliveryTools.nps.3.subtitle"),
    },
    {
      title: t("DeliveryTools.nps.4.number"),
      subtitle: t("DeliveryTools.nps.4.subtitle"),
    },
  ];

  const screenshots = [{ image: iPad2x }, { image: iPad22x }];

  return (
    <>
      <Hero t={t} buttonRef={downloadButtonRef}></Hero>
      <section className={styles.pageContainer}>
        <Grid container>
          <Grid item xs={12} container>
            <Typography variant="h2" sx={{ mt: 8, mb: 10 }}>
              {t("DeliveryTools.checklist.title")}
            </Typography>
            <Checklist text={checklist} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} container>
            <Typography variant="h2" sx={{ mt: 6, mb: 6 }}>
              {t("DeliveryTools.nps.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            {npsData.map((item) => (
              <Grid item xs={3} key={item.title}>
                <Grid
                  item
                  wrap="nowrap"
                  container
                  alignItems="center"
                  sx={{ mb: 6 }}
                >
                  <Box mr={4}>
                    <Typography variant="h1">{item.title}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">{item.subtitle}</Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("DeliveryTools.nps.caption")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ my: 13 }}>
          <Grid item xs={12}>
            <Slideshow slides={screenshots} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{ mb: 5 }}>
              {t("DeliveryTools.footer.title")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {t("DeliveryTools.footer.subtitle")}
            </Typography>
            <Box sx={{ mb: 20 }}>
              <EMPButton
                variant="contained"
                onClick={() => {
                  modalOpenAnalytics();
                  handleModal(
                    t("DeliveryTools.downloadInstructions.title"),
                    <DownloadInstructions />
                  );
                }}
              >
                {t("common.buttonCtas.learnHow")}
              </EMPButton>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default withTranslation("emp")(DownloadTool);
