import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { ACCESSORIES_PRICING_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

class BrandSettingsClient {
  static baseUrl = ACCESSORIES_PRICING_URL;

  static getDealerBrandAdjustments(user) {
    let url = `${this.baseUrl}/${user.generateResourcePath()}/adjustments`;
    const dealerCommonId = user.commonDealerId
      ? user.commonDealerId.split("|").join("%7C")
      : "";
    if (user.salesCode) {
      url += `?salesCode=` + user.salesCode;
    }
    if (user.commonDealerId) {
      url += user.salesCode
        ? `&commonDealerId=${dealerCommonId}`
        : `?commonDealerId=${dealerCommonId}`;
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.dealerBrandAdjustments;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerBrandAdjustments(user, data, dealerIds, dealerBrand) {
    const url = `${this.baseUrl}/dealer/adjustments/save?dealerIds=${dealerIds}&dealerBrand=${dealerBrand}`;
    return this.saveBrandAdjustments(user, data, url);
  }

  static saveBrandAdjustments(user, data, url) {
    return axios
      .post(url, data, getHeaders(user.token))
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default BrandSettingsClient;
