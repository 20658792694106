import React, { Component, useState } from "react";
import fordStyles from "./GlobalNavBar.module.scss";
import lincolnStyles from "./GlobalNavBar-Lincoln.module.scss";
import {
  AppBar,
  Grid,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { invalidateSessionAndRedirect } from "../../common/InvalidateSession";
import HasPermissionTo from "../../common/HasPermissionTo";
import {
  CHECK_RECALLS,
  DEALER_SETUP,
  RESOURCE_CENTER,
} from "../../common/Constants";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { FORD_BRAND, LINCOLN_BRAND } from "../../components/accessories/Brands";
import DashboardMessage from "../../components/dashboard/dealerDashBoard/DashboardMessage";
import EMPBranding from "../components/EMPBranding/EMPBranding";
import EMPDialog from "../components/EMPDialog/EMPDialog";
import EMPSelectField from "../components/EMPSelectField/EMPSelectField";
import * as AnalyticsService from "../services/analytics/AnalyticsService";
import EVTaxCreditLinksDialog from "../../components/customerHandling/modelEDetails/EVTaxCreditLinksDialog";

class GlobalNavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
    };
  }

  handleLogoutClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogoutClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogoutDialogOpen = () => {
    this.setState({ open: true });
  };

  handleLogoutDialogClose = () => {
    this.setState({ open: false });
  };

  isActive(currentUrl, styles) {
    if (this.props.urlPath.includes(currentUrl)) {
      return styles.active;
    }
  }

  updateSubNavTabs() {
    if (!this.props.isNsc) {
      const user = this.props.user;
      if (user) {
        user.updateBusinessSettingsTab && user.updateBusinessSettingsTab(0);
        user.updateDashboardTab && user.updateDashboardTab(0);
      }
    }
  }

  render() {
    const { user } = this.props;
    const name = user.name ? " (" + user.name + ")" : "";
    const styles = IS_FORD_BRAND(user.brand) ? fordStyles : lincolnStyles;

    function getLink(url, displayText) {
      return (
        <Link
          className={this.isActive(url, styles)}
          onClick={() => this.updateSubNavTabs()}
          to={{
            pathname: url,
          }}
        >
          <Typography
            variant="subtitle2"
            paragraph
            sx={{
              mb: 0,
              color: "text.primary",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            {this.props.t(displayText)}
          </Typography>
        </Link>
      );
    }

    return (
      <AppBar
        position="sticky"
        className={styles.globalNavBar}
        id="appHeader"
        data-testid="appHeader"
        sx={{ color: "text.primary", boxShadow: "none" }}
      >
        <HasPermissionTo
          perform={["dealerSearch"]}
          permissions={user.permissions.rolePermissions}
          condition={
            this.props.fromDealerSearch || !this.props.user.commonDealerId
          }
          render={() => {
            return (
              <div className={styles.infoBar}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={0}
                >
                  <Grid item xs="auto" sm={11}>
                    <EMPBranding
                      width="100px"
                      showLogo
                      brand={this.props.user.brand}
                    ></EMPBranding>
                  </Grid>
                  <Grid item xs sm={1}>
                    <FieldViewLogout
                      {...this.props}
                      styles={styles}
                      open={this.state.open}
                      handleLogoutDialogOpen={this.handleLogoutDialogOpen}
                      handleLogoutClose={this.handleLogoutClose}
                      handleLogoutDialogClose={this.handleLogoutDialogClose}
                      isFordBrand={IS_FORD_BRAND(this.props.user.brand)}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }}
          noRender={() => {
            return (
              <div className={styles.infoBar}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={0}
                >
                  <Grid item xs={6}>
                    <EMPBranding
                      width="100px"
                      showLogo
                      brand={this.props.user.brand}
                    ></EMPBranding>
                  </Grid>
                  <Grid item xs>
                    <Logout
                      {...this.props}
                      styles={styles}
                      handleLogoutClick={this.handleLogoutClick}
                      user={user}
                      name={name}
                      anchorEl={this.state.anchorEl}
                      handleLogoutClose={this.handleLogoutClose}
                      handleLogoutDialogOpen={this.handleLogoutDialogOpen}
                      open={this.state.open}
                      handleLogoutDialogClose={this.handleLogoutDialogClose}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }}
        />
        <Toolbar
          disableGutters
          variant="dense"
          sx={{ margin: 0, mx: [4, 4, 4, 14] }}
        >
          <Grid
            container
            alignItems="stretch"
            justifyContent="flex-start"
            className={styles.navContainer}
          >
            <Grid item className={styles.navItems}>
              <HasPermissionTo
                perform={["dealerSearch"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  this.props.fromDealerSearch || !this.props.user.commonDealerId
                }
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/dealer-search",
                      "GlobalNavBar.dealerSearch"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["fieldReporting"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  this.props.fromDealerSearch || !this.props.user.commonDealerId
                }
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/field-reporting",
                      "GlobalNavBar.reporting"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["dashBoardPage"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  (!this.props.fromDealerSearch &&
                    !!this.props.user.commonDealerId) ||
                  this.props.user.userType === "nsc"
                }
                render={() => (
                  <>
                    {getLink.call(this, "/dashboard", "GlobalNavBar.dashboard")}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["pricingPage"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <>{getLink.call(this, "/pricing", "GlobalNavBar.pricing")}</>
                )}
              />
              <HasPermissionTo
                perform={["accessoriesPage"]}
                condition={
                  !this.props.fromDealerSearch &&
                  !!this.props.user.commonDealerId
                }
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/pricing/accessories",
                      "GlobalNavBar.accessoriesPricing"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["customerHandlingPage"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  !this.props.fromDealerSearch &&
                  !!this.props.user.commonDealerId
                }
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      IS_CANADA_MARKET(this.props.user.market)
                        ? "/customer-handling/reservations_submitted"
                        : "/customer-handling",
                      "GlobalNavBar.customerHandling"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["ordersPage"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/dealer/customer-reservations",
                      "GlobalNavBar.orders"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["businessSettingsPage"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  (!this.props.fromDealerSearch &&
                    !!this.props.user.commonDealerId) ||
                  this.props.user.userType === "nsc"
                }
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/business-settings",
                      "GlobalNavBar.settings"
                    )}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["reportingPage"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  (!this.props.fromDealerSearch &&
                    !!this.props.user.commonDealerId) ||
                  this.props.user.userType === "nsc"
                }
                render={() => (
                  <>
                    {getLink.call(this, "/reporting", "GlobalNavBar.reporting")}
                  </>
                )}
              />
              <HasPermissionTo
                perform={["poReportingPage"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <>{getLink.call(this, "/", "GlobalNavBar.reporting")}</>
                )}
              />

              <HasPermissionTo
                perform={["crcOrdersPage"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <>{getLink.call(this, "/", "GlobalNavBar.orders")}</>
                )}
              />

              <HasPermissionTo
                perform={["dealerReporting:view"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  IS_USA_MARKET(this.props.user.market) &&
                  !this.props.fromDealerSearch
                }
                render={() => (
                  <>
                    {getLink.call(
                      this,
                      "/dealer-reporting",
                      "GlobalNavBar.reporting"
                    )}
                  </>
                )}
              />

              <HasPermissionTo
                perform={[]}
                permissions={user.permissions.rolePermissions}
                condition={
                  !this.props.fromDealerSearch &&
                  !!this.props.user.commonDealerId &&
                  IS_USA_MARKET(this.props.user.market) &&
                  IS_FORD_BRAND(this.props.user.brand)
                }
                render={() =>
                  getLink.call(this, "/delivery-tools", "Delivery Tools")
                }
              ></HasPermissionTo>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const FieldViewLogout = (props) => {
  const { styles, isFordBrand } = props;
  return (
    <>
      <div className={props.styles.logoutContainer} data-testid="logout-popup">
        <div
          className={styles.logoutBox}
          onClick={props.handleLogoutDialogOpen}
        >
          <Typography variant="subtitle2" sx={{ cursor: "pointer" }}>
            {isFordBrand
              ? props.t("Logout.logoutDealerSearch")
              : props.t("Logout.logoutCap")}
          </Typography>
        </div>
      </div>
      <LogoutDialogAction {...props} />
    </>
  );
};

const Logout = (props) => {
  const hasFordAndLincolnDealers = (dealerGroup) => {
    let fordCount = 0;
    let lincolnCount = 0;

    dealerGroup.forEach((dealer) => {
      if (dealer.commonId) {
        dealer.commonId.endsWith("F") && fordCount++;
        dealer.commonId.endsWith("L") && lincolnCount++;
      }
    });
    return fordCount > 0 && lincolnCount > 0;
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={props.styles.logoutContainer}
      >
        <HasPermissionTo
          perform={["franchiseSelector"]}
          permissions={props.user.permissions.rolePermissions}
          condition={
            props.user.dealerGroup &&
            props.user.dealerGroup.length > 1 &&
            hasFordAndLincolnDealers(props.user.dealerGroup)
          }
          render={() => (
            <Grid item xs={12} sm={3} md={3}>
              <ChangeBrand
                {...props}
                brand={props.user.brand}
                handleBrandChange={(event) =>
                  props.changeBrand(event.target.value)
                }
              />
            </Grid>
          )}
        />
        <Grid item xs="auto">
          <DealerAdminMenu {...props} />
        </Grid>
      </Grid>
      <LogoutDialogAction open={true} {...props} />
    </>
  );
};

const DealerAdminMenu = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const trackGlobalNavContentAction = (action) => {
    AnalyticsService.trackOnClickEvent(
      "",
      `emp:global nav:content:${action}`,
      "fv:emp:global nav:content action",
      props.user
    );
  };

  const meta =
    props.user.commonDealerId && props.user.commonDealerId.split("|");

  return (
    <>
      <div
        onClick={handleClick}
        className={props.styles.dealerBox}
        data-testid="dealer-box"
      >
        <HasPermissionTo
          perform={["NADealerDetails:view"]}
          permissions={props.user.permissions.rolePermissions}
          condition={
            props.user.userType === "na_dealer" ||
            props.user.userType === "field_view"
          }
          render={() => {
            return (
              <div className={props.styles.menuAlign}>
                <NADealerDetails
                  {...props}
                  styles={props.styles}
                  dealerType={props.dealerType}
                  name={props.name}
                />
                {!anchorElement ? (
                  <KeyboardArrowDown style={{ color: "#4d4d4d" }} />
                ) : (
                  <KeyboardArrowUp style={{ color: "#4d4d4d" }} />
                )}
              </div>
            );
          }}
          noRender={() => {
            return (
              <div className={props.styles.menuAlign}>
                <DealerDetails
                  user={props.user}
                  name={props.name}
                  styles={props.styles}
                />
                {!anchorElement ? (
                  <KeyboardArrowDown style={{ color: "#4d4d4d" }} />
                ) : (
                  <KeyboardArrowUp style={{ color: "#4d4d4d" }} />
                )}
              </div>
            );
          }}
        />
      </div>
      <Menu
        elevation={1}
        id="admin-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        disableAutoFocusItem={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {meta && props.user.userType === "field_view" && (
          <MenuItem>
            <Link
              style={{ textDecoration: "none", color: "#000000" }}
              underline="none"
              color="inherit"
              onClick={() => {
                props.changeDealer();
              }}
              to="/dealer-search"
            >
              <Typography variant="subtitle2">
                {props.t("GlobalNavBar.changeDealer")}
              </Typography>
            </Link>
          </MenuItem>
        )}
        <HasPermissionTo
          perform={["dealerSetupLink"]}
          permissions={props.user.permissions.rolePermissions}
          render={() => (
            <MenuItem
              component="a"
              target="_blank"
              href={DEALER_SETUP}
              disableRipple
              onClick={() => trackGlobalNavContentAction("dealer setup")}
            >
              <Typography variant="subtitle2">
                {props.t("GlobalNavBar.dealerSetup")}
              </Typography>
            </MenuItem>
          )}
        />
        <HasPermissionTo
          perform={["checkRecallsLink"]}
          permissions={props.user.permissions.rolePermissions}
          render={() => (
            <MenuItem
              component="a"
              target="_blank"
              href={CHECK_RECALLS}
              disableRipple
              onClick={() => trackGlobalNavContentAction("recalls")}
            >
              <Typography variant="subtitle2">
                {props.t("GlobalNavBar.recalls")}
              </Typography>
            </MenuItem>
          )}
        />
        <HasPermissionTo
          perform={["resourceCenterLink"]}
          permissions={props.user.permissions.rolePermissions}
          render={() => (
            <MenuItem
              component="a"
              target="_blank"
              href={RESOURCE_CENTER}
              disableRipple
              onClick={() => trackGlobalNavContentAction("resource center")}
            >
              <Typography variant="subtitle2">
                {props.t("GlobalNavBar.resourceCenter")}
              </Typography>
            </MenuItem>
          )}
        />
        <HasPermissionTo
          perform={["modelETab"]}
          permissions={props.user.permissions.rolePermissions}
          render={() => (
            <EVTaxCreditDialog {...props} closeMenu={handleClose} />
          )}
        />
        <PermissionsDialog
          {...props}
          closeMenu={handleClose}
          trackGlobalNavContentAction={trackGlobalNavContentAction}
        />
        <MenuItem
          disableRipple
          onClick={() => {
            invalidateSessionAndRedirect();
            trackGlobalNavContentAction("logout");
          }}
        >
          <Typography variant="subtitle2">
            {props.t("Logout.logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const PermissionsDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.closeMenu();
    props.trackGlobalNavContentAction("your permissions");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem disableRipple onClick={handleOpen}>
        <Typography variant="subtitle2">Your Permissions</Typography>
      </MenuItem>
      <EMPDialog
        open={open}
        dialogTitle="Your permissions"
        hasCloseIcon
        handleClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DashboardMessage {...props} userDetails={props.userDetails} />
      </EMPDialog>
    </>
  );
};

const LogoutDialogAction = (props) => {
  return (
    <EMPDialog
      open={props.open}
      handleClose={props.handleLogoutDialogClose}
      hasCloseIcon
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      dialogTitle={props.t("Logout.logout")}
      hasActionButton
      hasSecondaryActionButton
      actionButtonText={props.t("Logout.confirm")}
      onConfirm={invalidateSessionAndRedirect}
      onSecondaryAction={props.handleLogoutDialogClose}
      secondaryActionButtonText={props.t("Logout.decline")}
    >
      <p className={props.styles.logoutDialogText}>
        {props.t("Logout.confirmation")}
      </p>
    </EMPDialog>
  );
};

const EVTaxCreditDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.closeMenu();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem disableRipple onClick={handleOpen}>
        <Typography variant="subtitle2">
          {props.t("GlobalNavBar.evTaxCreditLink")}
        </Typography>
      </MenuItem>
      <EVTaxCreditLinksDialog
        open={open}
        hasCloseIcon={true}
        handleClose={handleClose}
        t={props.t}
      />
    </>
  );
};

const DealerDetails = (props) => {
  return (
    <div className={props.styles.dealerDetails} id="name">
      <Typography variant="subtitle2">
        Hi{`, ${props.user.fullName}`}
      </Typography>
      <Typography variant="body2">
        {props.user.userType === "dealer" ? props.name : ""}
      </Typography>
    </div>
  );
};

const NADealerDetails = (props) => {
  const meta =
    props.user.commonDealerId && props.user.commonDealerId.split("|");

  return (
    <div className={props.styles.dealerDetails}>
      <div className={props.styles.detailsMeta}>
        {meta && (
          <>
            <Typography variant="subtitle2">
              {`Hi, ${props.user.fullName.split(" ")[0]}`}
            </Typography>
            <Typography variant="body2">{props.user.dealerName}</Typography>

            <Typography variant="body2">
              {meta[1].concat(" | ").concat(meta[2])}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

const ChangeBrand = (props) => {
  const { brand, handleBrandChange } = props;

  const menuItems = [
    {
      text: props.t("GlobalNavBar.fordBrand"),
      value: FORD_BRAND,
    },
    {
      text: props.t("GlobalNavBar.lincolnBrand"),
      value: LINCOLN_BRAND,
    },
  ];
  return (
    <EMPSelectField
      testId="actual-brand"
      value={brand}
      onChange={handleBrandChange}
      menuItems={menuItems}
    />
  );
};

export default withTranslation("emp")(GlobalNavBar);
