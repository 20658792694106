import { useReducer, useEffect, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import DeliveryClient from "../../clients/DeliveryClient";
import { taskReducer } from "../reducers/taskReducer";
import {
  FETCH_TASKS,
  FILTER_TASKS,
  SET_TASKS,
} from "../constants/PreDeliveryTaskConstants";
import { UserContext } from "../context/userContext";

const initialState = {
  tasks: {},
  filteredTasks: [],
  loading: false,
  error: null,
};

const usePreDeliveryTasks = (initialVehicleType) => {
  const user = useContext(UserContext);
  const [state, dispatch] = useReducer(taskReducer, initialState);

  const { isFetching, error } = useQuery({
    queryKey: ["preDeliveryTasks"],
    queryFn: async () => {
      const response = await DeliveryClient.getPreDeliveryTasks(
        sessionStorage.getItem("adfs.token"),
        user.dealerId
      );
      return response.data;
    },
    retry: 1,
    onSuccess: (data) => {
      dispatch({
        type: SET_TASKS,
        payload: { data, taskType: initialVehicleType },
      });
    },
    onError: (error) => {
      dispatch({ type: FETCH_TASKS, payload: { error: error.message } });
    },
  });

  useEffect(() => {
    if (isFetching) {
      dispatch({ type: FETCH_TASKS });
    }
    if (error) {
      dispatch({ type: FETCH_TASKS, payload: { error: error.message } });
    }
  }, [isFetching, error]);

  const filterTasks = (taskType) => {
    const filtered = state.tasks[taskType] || [];
    dispatch({ type: FILTER_TASKS, payload: filtered });
  };

  const updateTasks = (data, currentVehicleType) => {
    dispatch({
      type: SET_TASKS,
      payload: { data, taskType: currentVehicleType },
    });
  };

  return {
    tasks: state.filteredTasks,
    loading: state.loading,
    error: state.error,
    filterTasks,
    updateTasks,
  };
};

export default usePreDeliveryTasks;
