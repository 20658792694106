import React from "react";
import { SelectValidator } from "react-material-ui-form-validator";
import MotionChevronDown from "../../Icons/MotionChevronDown";

const EMPValidatedSelectField = (props) => {
  const {
    testId,
    menuItems,
    value,
    disabled,
    onChange,
    validators,
    errorMessages,
    name,
  } = props;
  return (
    <SelectValidator
      fullWidth
      onChange={onChange}
      SelectProps={{
        disableUnderline: true,
        IconComponent: () => <MotionChevronDown />,
        native: true,
        name,
      }}
      inputProps={{ "data-testid": testId, disabled }}
      value={value}
      variant="standard"
      validators={validators}
      errorMessages={errorMessages}
    >
      {menuItems.map((item) => (
        <option
          data-testid={item.id}
          value={item.value}
          key={item.value}
          disabled={item.disabled}
        >
          {item.text}
        </option>
      ))}
    </SelectValidator>
  );
};
export default EMPValidatedSelectField;
