import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, DialogActions } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPTabs from "../../EMPTabs/EMPTabs";
import { ModalContext } from "../ModalContext";
import chrome from "../../../../components/deliveryTools/pages/downloadTool/img/chrome.jpg";
import safari from "../../../../components/deliveryTools/pages/downloadTool/img/safari.jpg";
import edge from "../../../../components/deliveryTools/pages/downloadTool/img/edge.jpg";

const ImageWithList = ({
  image,
  imageAlt = "",
  items = [],
  imageWidth = "100%",
  spacing = 3,
}) => {
  return (
    <Box>
      <Box sx={{ mb: spacing }}>
        <img
          src={image}
          alt={imageAlt}
          style={{
            width: imageWidth,
            display: "block",
            maxWidth: "300px",
          }}
        />
      </Box>

      <ol style={{ paddingLeft: "0px" }}>
        {items.slice(0, 4).map((item, index) => (
          <li key={index}>
            <Typography variant="body1">{item}</Typography>
          </li>
        ))}
      </ol>
    </Box>
  );
};

const DownloadInstructions = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const { handleModal } = useContext(ModalContext);
  const { path } = useRouteMatch();
  const chromeInstructions = [
    t("DeliveryTools.downloadInstructions.tab1.list.1"),
    t("DeliveryTools.downloadInstructions.tab1.list.2"),
    t("DeliveryTools.downloadInstructions.tab1.list.3"),
  ];

  const safariInstructions = [
    t("DeliveryTools.downloadInstructions.tab2.list.1"),
    t("DeliveryTools.downloadInstructions.tab2.list.2"),
    t("DeliveryTools.downloadInstructions.tab2.list.3"),
  ];

  const edgeInstructions = [
    t("DeliveryTools.downloadInstructions.tab3.list.1"),
    t("DeliveryTools.downloadInstructions.tab3.list.2"),
    t("DeliveryTools.downloadInstructions.tab3.list.3"),
  ];

  const tabItems = [
    {
      route: `${path}`,
      title: t("DeliveryTools.downloadInstructions.tab1.title"),
      value: 0,
      content: (
        <ImageWithList
          items={chromeInstructions}
          image={chrome}
          imageAlt={t("DeliveryTools.downloadInstructions.tab1.title")}
        />
      ),
    },
    {
      route: `${path}`,
      value: 1,
      title: "Safari",
      content: (
        <ImageWithList
          items={safariInstructions}
          image={safari}
          imageAlt="Safari"
        />
      ),
    },
    {
      route: `${path}`,
      value: 2,
      title: "Microsoft Edge",
      content: (
        <ImageWithList
          items={edgeInstructions}
          image={edge}
          imageAlt="Microsoft Edge"
        />
      ),
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={6}>
            <Typography variant="body1">
              {t("DeliveryTools.downloadInstructions.downloadNote")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={6}>
            <EMPTabs
              tabItems={tabItems}
              handleChange={setActiveTab}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          </Box>
        </Grid>
      </Grid>
      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => handleModal(false, false)}
            >
              {t("common.buttonCtas.done")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default DownloadInstructions;
