import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL, REPORTING_BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class NscReportingClient {
  static baseUrl = BASE_URL;
  static reportingUrl = REPORTING_BASE_URL;

  static downloadDealerActivationReportInCsv(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/activation-report`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadDealerReservationSummary(user, market, catalog) {
    let url = `${this.reportingUrl}/reporting/market-reservation-report?market=${market}`;
    if (catalog) url = url + `&catalog=${catalog}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
