export const pageLoadDataLayer = (
  pageName,
  paCode,
  deviceType,
  timeStampPL
) => {
  return {
    page: {
      pageName,
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      radUIVersion: deviceType,
      timeStampPL,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
  };
};

export const pageLoadDetailDataLayer = (
  pageName,
  tool,
  orderId,
  paCode,
  deviceType,
  timeStampPL
) => {
  const toolDescriptor = `${tool}:${orderId}`;

  return {
    page: {
      pageName,
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      tool,
      toolDescriptor,
      radUIVersion: deviceType,
      timeStampPL,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    event: {
      action: "tool",
    },
  };
};

export const modelEPageLoadDetailDataLayer = (
  pageName,
  orderId,
  deliveryStatus,
  paCode,
  deviceType,
  timeStampPL
) => {
  return {
    page: {
      pageName: pageName,
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      tool: `emp:model e:status:${deliveryStatus}`,
      toolDescriptor: `emp:model e:order id:${orderId}`,
      radUIVersion: deviceType,
      timeStampPL,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    event: {
      action: "tool",
    },
  };
};

export const customerHandlingPageLoadDetailDataLayer = (
  pageName,
  tool,
  toolDescriptor,
  paCode,
  deviceType,
  timeStampPL
) => {
  return {
    page: {
      pageName,
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      tool,
      toolDescriptor,
      radUIVersion: deviceType,
      timeStampPL,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    event: {
      action: "tool",
    },
  };
};

export const onClickDataLayer = (
  pageName,
  onclick,
  onclickLinkName,
  paCode,
  deviceType,
  timeStampCTA
) => {
  return {
    page: {
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      radUIVersion: deviceType,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    onclick: {
      onclick,
      onclickLinkName,
      timeStampCTA,
    },
  };
};

export const onClickDetailDataLayer = (
  pageName,
  tool,
  toolDescriptor,
  orderId,
  onclick,
  onclickLinkName,
  paCode,
  deviceType,
  timeStampCTA,
  modelYear,
  nameplate
) => {
  return {
    page: {
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      tool,
      toolDescriptor,
      radUIVersion: deviceType,
    },
    vehicle: {
      modelYear,
      nameplate,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    onclick: {
      onclick,
      onclickLinkName,
      timeStampCTA,
    },
    event: {
      action: "tool",
    },
  };
};

export const onClickDetailDataLayerWithPaymentType = (
  pageName,
  tool,
  toolDescriptor,
  orderId,
  onclick,
  onclickLinkName,
  paCode,
  deviceType,
  timeStampCTA,
  paymentType,
  modelYear,
  nameplate
) => {
  return {
    page: {
      siteSection: "emp:dealer connection",
      hierarchy: pageName,
      userLanguage: "eng",
      pageNameNoVehicle: pageName,
      client: "ford-us",
      site: "fordvehicles.com",
      tool,
      toolDescriptor,
      radUIVersion: deviceType,
    },
    vehicle: {
      modelYear,
      nameplate,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    onclick: {
      onclick,
      onclickLinkName,
      timeStampCTA,
    },
    event: {
      action: "tool",
    },
    cart: {
      paymentMethod: paymentType,
    },
  };
};

export const referralExitDataLayer = (
  orderId,
  onclick,
  onclickLinkName,
  paCode,
  deviceType,
  timeStampCTA
) => {
  return {
    page: {
      siteSection: "emp:dealer connection",
      hierarchy: "",
      userLanguage: "eng",
      pageNameNoVehicle: "",
      client: "ford-us",
      site: "fordvehicles.com",
      tool: "emp:order id",
      toolDescriptor: `emp:order id:${orderId}`,
      radUIVersion: deviceType,
    },
    user: {
      paCode,
      fordRegion: "",
      fdafCode: "",
      loginStatus: "logged in",
    },
    onclick: {
      onclick,
      onclickLinkName,
      timeStampCTA,
    },
    event: {
      action: "tool",
    },
  };
};
