import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Item from "../../../shared/item/Item";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../common/ValidationRules";
import {
  Accordion,
  AccordionSummary,
  FormControl,
  Button,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import RectangleEdit from "../../../shared/rectangleEdit/RectangleEdit";
import crStyles from "../../customerHandling/crStyles";
import NamePlateSettingsClient from "../../../clients/NamePlateSettingsClient";
import fordStyles from "./NamePlateSettings-Ford.module.scss";
import lincolnStyles from "./NamePlateSettings-Lincoln.module.scss";
import Clear from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { logMsg } from "../../../common/Logger";
import {
  displayError,
  ERROR_PROCESSING,
  is404,
  isProcessingError,
  PAGE_ERROR,
} from "../../../errors/ErrorConstants";
import SortingIcon from "../../../shared/sortingIcon/SortingIcon";
import { SortSubGroupData } from "../../../utils/SortUtil";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import PublishButton from "../PublishButton";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import { BRAND_NAMES } from "../../../common/Constants";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPSelectField from "../../../shared/components/EMPSelectField/EMPSelectField";
import CapitalizeFirstWord from "../../../utils/CapitalizeFirstWord";
import CapitalizeEachWord from "../../../utils/CapitalizeEachWord";

const nameplate = "NAMEPLATE";
let isFordBrand = true;
let styles = fordStyles;

class NamePlateSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      namePlateSettings: [],
      isDataLoaded: false,
      hasMarkupChanged: false,
      hasLaborRateChanged: false,
      markupEditEnabled: false,
      laborRateEditEnabled: false,
      enablePublishBtn: false,
      overAllMarkup: "",
      overAllLaborRate: "",
      originalMarkup: "",
      originalLaborRate: "",
      hasError: [],
      sortKey: null,
      sortDirection: null,
      overridesApplied: false,
      confirmDialog: false,
      modelYearGrouping: null,
      vehicleLines: [],
      modelYears: [],
      model: null,
      year: null,
      catalogId: null,
    };
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:accessories pricing:nameplate",
      this.props.user
    );
    ValidationRules();
    this.getAllVehicleLines();
  }

  onModelChange = (event) => {
    if (event) {
      event.preventDefault();
    }

    const model = event.target.value;
    const modelYears = this.state.modelYearGrouping[model];
    const year = modelYears[0].year;
    const catalogId = modelYears[0].catalogId;

    this.setState({ model, modelYears, year, catalogId }, () =>
      this.getNamePlateSettings()
    );
  };

  onYearChange = (event) => {
    if (event) {
      event.preventDefault();
    }
    const catalogId =
      this.state.modelYears &&
      this.state.modelYears.find(
        (vehicle) => String(vehicle.year) === event.target.value
      ).catalogId;

    this.setState(
      {
        year: event.target.value,
        catalogId: catalogId,
      },
      () => this.getNamePlateSettings()
    );
  };

  handleConfirm = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ confirmDialog: true });
  };

  handleClose = () => {
    this.setState({ confirmDialog: false });
  };

  handleSubmit = () => {
    this.props.hideOrShow(true);
    const updatedNameSettings = this.state.namePlateSettings;
    updatedNameSettings.forEach((group) => {
      delete group.expand;
      group.namePlateCategorySubGroup.forEach((subGroup) => {
        subGroup.markup = parseFloat(this.state.overAllMarkup);
        subGroup.laborRate = parseFloat(this.state.overAllLaborRate);

        if (this.state.laborRateEditEnabled && this.state.overAllLaborRate)
          subGroup.laborRateType = nameplate;

        if (this.state.markupEditEnabled && this.state.overAllMarkup)
          subGroup.markupType = nameplate;
      });
    });
    NamePlateSettingsClient.saveDealerBrandAdjustments(
      this.props.user,
      this.state.catalogId,
      updatedNameSettings,
      this.state.laborRateEditEnabled && this.state.overAllLaborRate !== "",
      this.state.markupEditEnabled && this.state.overAllMarkup !== ""
    )
      .then(() => {
        this.setOriginalData(updatedNameSettings);
        this.setState({
          enablePublishBtn: false,
          markupEditEnabled: false,
          laborRateEditEnabled: false,
          overridesApplied: true,
          confirmDialog: false,
        });
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.props.user.showWarning(false);
      });
  };

  getNamePlateSettings() {
    this.props.hideOrShow(true);
    let namePlateSettings = [];
    NamePlateSettingsClient.getDealerNamePlateAdjustments(
      this.props.user,
      this.state.catalogId
    )
      .then((data) => {
        namePlateSettings = data;
        const released = this.checkReleasedStatus(namePlateSettings);
        this.setOriginalData(namePlateSettings);
        this.setState({
          enablePublishBtn: !released,
        });
        this.confirmDialogText = {
          title: this.props.t("CategorySettings.dialogConfirmTitle"),
          bodyText: this.props.t("CategorySettings.dialogConfirmBody"),
          confirm: isFordBrand
            ? this.props.t("CategorySettings.dialogFordPublish")
            : this.props.t("CategorySettings.dialogLincolnConfirm"),
          cancel: isFordBrand
            ? this.props.t("CategorySettings.dialogFordGoBack")
            : this.props.t("CategorySettings.dialogLincolnCancel"),
        };
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  getAllVehicleLines() {
    this.props.hideOrShow(true);
    NamePlateSettingsClient.getAllVehicleLines(
      this.props.user,
      IS_FORD_BRAND(this.props.user.brand)
        ? BRAND_NAMES.FORD
        : BRAND_NAMES.LINCOLN
    )
      .then((modelYearGrouping) => {
        const vehicleLines = Object.keys(modelYearGrouping);
        const model = vehicleLines[0];
        const modelYears = modelYearGrouping[model];
        const year = modelYears[0].year;
        const catalogId = modelYears[0].catalogId;

        this.setState(
          {
            modelYearGrouping,
            vehicleLines,
            model,
            modelYears,
            year,
            catalogId,
          },
          () => this.getNamePlateSettings()
        );
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          isDataLoaded: true,
        });
      });
  }

  checkReleasedStatus(nameplateSettings) {
    let released = true;
    nameplateSettings.forEach((record) => {
      released &&
        record.namePlateCategorySubGroup.forEach((subGroup) => {
          if (!subGroup.released) {
            released = false;
            return released;
          }
        });
    });
    return released;
  }

  setOriginalData(namePlateSettings) {
    const overAllMarkup =
      namePlateSettings.length > 0
        ? namePlateSettings[0].namePlateCategorySubGroup[0].markup
        : "";
    const overAllLaborRate =
      namePlateSettings.length > 0
        ? namePlateSettings[0].namePlateCategorySubGroup[0].laborRate
        : "";

    namePlateSettings.forEach((record) => {
      record.expand = false;
    });

    this.setState({
      namePlateSettings,
      originalMarkup: overAllMarkup,
      overAllMarkup: overAllMarkup,
      originalLaborRate: overAllLaborRate,
      overAllLaborRate: overAllLaborRate,
    });
  }

  updateMarkupEditEnabled = () => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    this.setState(
      {
        overAllMarkup: this.state.originalMarkup,
        markupEditEnabled: !this.state.markupEditEnabled,
        hasMarkupChanged: !this.state.hasMarkupChanged,
      },
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
          overridesApplied: false,
        });
      }
    );
  };

  updateLaborRateEditEnabled = () => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    this.setState(
      {
        overAllLaborRate: this.state.originalLaborRate,
        laborRateEditEnabled: !this.state.laborRateEditEnabled,
        hasLaborRateChanged: !this.state.hasLaborRateChanged,
      },
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
          overridesApplied: false,
        });
      }
    );
  };

  sortColumns = (sortByCol) => {
    let sortDir = "ASC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;

    const sortInputData = this.state.namePlateSettings;

    SortSubGroupData(
      sortByCol,
      sortDir,
      "alphanumeric",
      sortInputData,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          namePlateSettings: sortedData,
        });
      }
    );
  };

  updateModel = (event) => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    let markup = this.state.overAllMarkup;
    let laborRate = this.state.overAllLaborRate;
    if ("markup" === event.target.name) {
      markup = event.target.value;
    } else {
      laborRate = event.target.value;
    }

    this.setState(
      {
        overAllMarkup: markup,
        overAllLaborRate: laborRate,
        hasMarkupChanged: true,
        hasLaborRateChanged: true,
      },
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
        });
      }
    );
  };

  enableOrDisableButton = () => {
    const markup = this.state.overAllMarkup;
    const laborRate = this.state.overAllLaborRate;
    let enableApplyBtn = false;

    if (
      (this.state.laborRateEditEnabled && laborRate !== "") ||
      (this.state.markupEditEnabled && markup !== "")
    ) {
      enableApplyBtn = true;
    }
    return enableApplyBtn;
  };

  flipIcon = (indexIcon) => {
    const namePlateSettings = this.state.namePlateSettings;
    namePlateSettings.forEach((record, index) => {
      if (indexIcon === index) {
        record.expand = !record.expand;
      }
    });
    this.setState({ namePlateSettings });
  };

  render() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    styles = isFordBrand ? fordStyles : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      this.state.isDataLoaded && (
        <>
          <ValidatorForm onSubmit={(e) => this.handleConfirm(e)}>
            <div className="pagePadding">
              <div className={styles.title}>
                {this.props.t("NamePlate.subHeader")}
              </div>
              <div className={styles.dropdownsWrapper}>
                <div className={styles.dropdownDiv}>
                  {isFordBrand && (
                    <div className={styles.subTitle}>
                      {this.props.t("NamePlate.model")}
                    </div>
                  )}
                  <ModelDropDown
                    {...this.props}
                    onModelChange={this.onModelChange}
                    model={this.state.model}
                    vehicleLines={this.state.vehicleLines}
                  />
                </div>
                <div className={styles.dropdownDiv}>
                  {isFordBrand && (
                    <div className={styles.subTitle}>
                      {this.props.t("NamePlate.year")}
                    </div>
                  )}
                  <YearDropDown
                    {...this.props}
                    onYearChange={this.onYearChange}
                    year={this.state.year}
                    modelYears={this.state.modelYears}
                  />
                </div>
              </div>
            </div>
            <NamePlateRowHeader
              {...this.props}
              updateMarkupEditEnabled={this.updateMarkupEditEnabled}
              updateLaborRateEditEnabled={this.updateLaborRateEditEnabled}
              updateModel={this.updateModel}
              sortColumns={this.sortColumns}
              sortKey={this.state.sortKey}
              sortDirection={this.state.sortDirection}
              markupEditEnabled={this.state.markupEditEnabled}
              laborRateEditEnabled={this.state.laborRateEditEnabled}
              overAllMarkup={this.state.overAllMarkup}
              overAllLaborRate={this.state.overAllLaborRate}
            />
            {this.state.namePlateSettings.map((namePlate, index) => {
              return (
                <Accordion
                  defaultExpanded={true}
                  key={namePlate.groupName}
                  onChange={() => this.flipIcon(index)}
                >
                  <AccordionSummary
                    style={
                      isFordBrand
                        ? crStyles.accessoriesHeaderBarBg
                        : crStyles.accessoriesHeaderBarBgLincoln
                    }
                    data-testid={"categoryPanel" + index}
                  >
                    <div className={styles.expansionBar}>
                      <Grid container>
                        <Grid item xs={10} style={crStyles.gridItem}>
                          {CapitalizeFirstWord(namePlate.groupName)} (
                          {namePlate.namePlateCategorySubGroup.length})
                        </Grid>
                        <Grid item xs={2} style={crStyles.dropdown}>
                          {namePlate.expand ? (
                            <ExpandMoreIcon
                              data-testid={"more" + namePlate.groupName}
                            />
                          ) : (
                            <ExpandLessIcon
                              data-testid={"less" + namePlate.groupName}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                  </AccordionSummary>
                  {namePlate.namePlateCategorySubGroup.length > 0 &&
                    namePlate.namePlateCategorySubGroup.map((record) => {
                      return (
                        <div
                          key={record.subGroupName}
                          className={styles.categoryRow}
                        >
                          <Grid container>
                            <Grid item xs={4} className={styles.brand}>
                              <div className={styles.brandName}>
                                {record.subGroupName}
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.brandValue}>
                                <p className={styles.brandText}>
                                  {" "}
                                  {this.state.overAllMarkup === "" ||
                                  !this.state.hasMarkupChanged
                                    ? record.markup
                                    : this.state.overAllMarkup}
                                  &#37;
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.brandValue}>
                                <p className={styles.brandText}>
                                  {CapitalizeFirstWord(record.markupType)}
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.brandValue}>
                                <p className={styles.brandText}>
                                  {" "}
                                  &#36;
                                  {this.state.overAllLaborRate === "" ||
                                  !this.state.hasLaborRateChanged
                                    ? record.laborRate
                                    : this.state.overAllLaborRate}
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.brandValue}>
                                <p className={styles.brandText}>
                                  {CapitalizeFirstWord(record.laborRateType)}
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                </Accordion>
              );
            })}
            <PublishButton
              {...this.props}
              styles={styles}
              overridesApplied={this.state.overridesApplied}
              enablePublishBtn={
                this.state.enablePublishBtn && this.state.catalogId
              }
            />
          </ValidatorForm>
          <ConfirmDialog
            open={this.state.confirmDialog}
            showWarningIcon={true}
            dialogProps={this.confirmDialogText}
            onConfirm={this.handleSubmit}
            onCancel={this.handleClose}
            boldTitle={true}
            onClose={this.handleClose}
            disableConfirm={
              !this.props.user.permissions.rolePermissions.hasOwnProperty(
                "accessoriesSubmit"
              )
            }
            {...this.props}
          />
        </>
      )
    );
  }
}

const NamePlateRowHeader = (props) => {
  return (
    <>
      <div className={styles.namePlateHeader}>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <div className={`${styles.alignLeft} ${styles.label}`}>
              <Item value={props.t("NamePlate.category")} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.markupTypeLabel}>
              <Item value={props.t("BrandSettings.markup")} />
              {!props.markupEditEnabled ? (
                <RectangleEdit
                  dataTestId="markupEditBtn"
                  onEditClick={props.updateMarkupEditEnabled}
                />
              ) : (
                <Button
                  size="small"
                  name="Cancel"
                  data-testid="cancelMarkupRate"
                  onClick={props.updateMarkupEditEnabled}
                >
                  <Clear />
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={2} onClick={() => props.sortColumns("markupType")}>
            <div className={styles.markupTypeLabel}>
              <Item value={props.t("CategorySettings.markUpType")} />
              <SortingIcon
                currentKey="markupType"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.markupTypeLabel}>
              <Item value={props.t("BrandSettings.laborRate")} />
              {!props.laborRateEditEnabled ? (
                <RectangleEdit
                  dataTestId="laborRateEditBtn"
                  onEditClick={props.updateLaborRateEditEnabled}
                />
              ) : (
                <Button
                  size="small"
                  name="Cancel"
                  data-testid="cancelLaborRateBtn"
                  onClick={props.updateLaborRateEditEnabled}
                >
                  <Clear />
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={2} onClick={() => props.sortColumns("laborRateType")}>
            <div className={styles.markupTypeLabel}>
              <Item value={props.t("CategorySettings.laborRateType")} />
              <SortingIcon
                currentKey="laborRateType"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.namePlateTextDiv}>
        <Grid container spacing={0}>
          <Grid item xs={4} />
          <Grid item xs={2}>
            {props.markupEditEnabled && (
              <div className={styles.laborRateRow}>
                <div className={styles.headerPercentage}>&#37;</div>
                <div className={styles.textBox}>
                  <TextValidator
                    value={props.overAllMarkup}
                    data-testid="markup"
                    name="markup"
                    placeholder="--"
                    validators={[
                      "required",
                      "matchRegexp:^^[+-d\\b.]+$",
                      "isSignedFloatWithTwoDecimalPlaces",
                    ]}
                    errorMessages={[
                      props.t("Error.requiredField"),
                      props.t("Error.invalidInput"),
                      props.t("Error.invalidInput"),
                      props.t("Error.invalidInput"),
                    ]}
                    onChange={(e) => props.updateModel(e)}
                  />
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            {props.laborRateEditEnabled && (
              <div className={styles.laborRateRow}>
                <div className={styles.headerCurrency}>&#36;</div>
                <div className={styles.textBox}>
                  <TextValidator
                    value={props.overAllLaborRate}
                    data-testid="laborRate"
                    name="laborRate"
                    placeholder="--"
                    validators={[
                      "required",
                      "matchRegexp:^[0-9\\b.]+$",
                      "isNumber",
                      "isNumericWithTwoDecimalPlaces",
                    ]}
                    errorMessages={[
                      props.t("Error.requiredField"),
                      props.t("Error.invalidInput"),
                      props.t("Error.invalidInput"),
                      props.t("Error.invalidInput"),
                    ]}
                    onChange={(e) => props.updateModel(e)}
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const ModelDropDown = (props) => {
  const menuItems = [];
  if (!isFordBrand) {
    menuItems.push({
      text: props.t("NamePlate.model"),
      value: "model",
      disabled: true,
    });
  }
  if (props.vehicleLines) {
    props.vehicleLines.forEach((vehicleLine) => {
      menuItems.push({
        text: CapitalizeEachWord(vehicleLine).replace("Mach-e", "Mach-E"),
        value: vehicleLine,
      });
    });
  }
  return (
    <FormControl id="menu" fullWidth={true}>
      <EMPSelectField
        testId="select"
        value={props.model}
        onChange={(event) => props.onModelChange(event)}
        menuItems={menuItems}
      />
    </FormControl>
  );
};

const YearDropDown = (props) => {
  const menuItems = [];
  if (!isFordBrand) {
    menuItems.push({
      text: props.t("NamePlate.year"),
      value: "year",
      id: "year",
      disabled: true,
    });
  }
  if (props.modelYears) {
    props.modelYears.forEach((modelYear) => {
      menuItems.push({
        text: modelYear.year,
        value: modelYear.year,
        id: modelYear.year,
      });
    });
  }
  return (
    <FormControl id="menu">
      <EMPSelectField
        testId="select"
        value={props.year}
        onChange={(event) => props.onYearChange(event)}
        menuItems={menuItems}
      />
    </FormControl>
  );
};

export default withTranslation("emp")(NamePlateSettings);
