import React, { useState, useRef, useContext } from "react";
import { Box, Typography, Grid } from "@mui/material";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPDataCard from "../../shared/components/EMPDataCard/EMPDataCard";
import EMPAccordion from "../../shared/components/EMPAccordion/EMPAccordion";
import { formatVehicleTrim } from "../../utils/EmpUtil";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import { base64OpenInNewTab } from "../../utils/FileDownloadUtil";
import EMPLoader from "../../shared/components/EMPLoader/EMPLoader";
import MotionChevronRight from "../../shared/Icons/MotionChevronRight";
import ProtectionPlan from "./ProtectionPlan";
import { ModalContext } from "../../shared/components/EMPModal/ModalContext";
import SimpleErrorMessage from "../../shared/components/EMPModal/ModalFragments/SimpleErrorMessage";
import { useTranslation } from "react-i18next";
import { trackOrderDetailsPageAction } from "../../shared/services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../shared/context/userContext";

const extractVehicleDetails = (content) => {
  const { vinNumber, trim, color } = content.customerReservation || {};
  return { vin: vinNumber, trim, color };
};

const Vehicle = ({ content, orderId }) => {
  const [expandProtectionPlans, setExpandProtectionPlans] = useState(true);
  const vehicleDetails = extractVehicleDetails(content);
  const { handleModal } = useContext(ModalContext);
  const vehicleContainer = useRef(null);
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const { dealerId } = user;
  const queryClient = useQueryClient();

  const getVehicleName = `${content.customerReservation.modelYear} ${content.customerReservation.nameplate}`;

  const { deliveryAppointment } = queryClient.getQueryData([
    "deliveryModule",
    { orderId },
  ]);

  const { isFetching, refetch } = useQuery({
    queryKey: ["windowSticker"],
    queryFn: async () =>
      await CustomerReservationsClient.getWindowSticker(
        sessionStorage.getItem("adfs.token"),
        dealerId,
        content.customerReservation.vinNumber
      ),
    staleTime: Infinity,
    enabled: false,
    retry: 1,
    select: ({ data }) => data,
  });

  const handleRefetchAndOpenInNewTab = async (refetch) => {
    const { data: windowStickerBlob } = await refetch();
    if (windowStickerBlob) {
      base64OpenInNewTab(windowStickerBlob, "application/pdf");
    } else {
      handleModal(
        t("common.somethingWentWrong"),
        <SimpleErrorMessage
          error={t("common.errorMessage3", { item: "Window Sticker" })}
        />
      );
    }
  };

  const vehicleData = [
    {
      heading: t("vehicle.vehicleInfo.fields.0"),
      content: vehicleDetails.vin || "--",
    },
    {
      heading: t("vehicle.vehicleInfo.fields.1"),
      content: formatVehicleTrim(vehicleDetails.trim) || "--",
    },
    {
      heading: t("vehicle.vehicleInfo.fields.2"),
      content: vehicleDetails.color || "--",
    },
  ];

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      columnGap={4}
      rowGap={6}
      sx={{ mt: 5, mb: 11 }}
      ref={vehicleContainer}
    >
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h3" sx={{ color: "text.primary" }}>
              {getVehicleName}
            </Typography>
          </Grid>
          <Grid item sx={{ ml: 5 }}>
            {isFetching ? (
              <EMPLoader loadingMessage="Loading Window Sticker" />
            ) : (
              <EMPButton
                onClick={() => {
                  handleRefetchAndOpenInNewTab(refetch);
                  trackOrderDetailsPageAction(
                    deliveryAppointment,
                    "vehicle:see window sticker",
                    orderId,
                    content.customerReservation.modelYear,
                    content.customerReservation.nameplate,
                    user
                  );
                }}
                rightIcon={<MotionChevronRight />}
              >
                {t("common.buttonCtas.viewWindowSticker")}
              </EMPButton>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPDataCard data={vehicleData} hasBackground testId="vehicle-data" />
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPAccordion
          heading={t("vehicle.protectionPlans.heading")}
          expanded={expandProtectionPlans}
          onChange={() => setExpandProtectionPlans(!expandProtectionPlans)}
        >
          <ProtectionPlan
            protectionPlansData={
              content?.customerReservation?.protectionPlansData
            }
          />
        </EMPAccordion>
      </Box>
    </Box>
  );
};

export default Vehicle;
