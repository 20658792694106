import React, { useState } from "react";
import styles from "./DeliveryTools.module.scss";
import EMPTabs from "../../shared/components/EMPTabs/EMPTabs";
import { useRouteMatch } from "react-router-dom";
import DownloadTool from "./pages/downloadTool/DownloadTool";
import PreDeliveryTasks from "./pages/dealerTasks/PreDeliveryTasks.js";
import DeliveryTimes from "./pages/deliveryTimes/DeliveryTimes.js";
import { withTranslation } from "react-i18next";
import { ModalProvider } from "../../shared/components/EMPModal/ModalContext";

const DeliveryTools = (props) => {
  const { user, history, t } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { path } = useRouteMatch();

  const tabItems = [
    {
      route: `${path}`,
      title: t("DeliveryTools.tabTitle1"),
      value: 0,
      content: <DownloadTool user={user} />,
    },
    {
      route: `${path}/times`,
      value: 1,
      title: t("DeliveryTools.tabTitle2"),
      content: <DeliveryTimes user={user} />,
    },
    {
      route: `${path}/tasks`,
      value: 2,
      title: t("DeliveryTools.tabTitle3"),
      content: <PreDeliveryTasks user={user} />,
    },
  ];

  return (
    <ModalProvider>
      <section className={styles.pageContainer}>
        <div className={styles.tabsContainer}>
          <EMPTabs
            tabItems={tabItems}
            navigation
            history={history}
            user={user}
            handleChange={setActiveTab}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </div>
      </section>
    </ModalProvider>
  );
};

export default withTranslation("emp")(DeliveryTools);
