/**
 * This method is used for sorting the given data.
 * @param sortByCol column name which needs to be sorted from the given array
 * @param sortDirection ASC or DSC
 * @param sortType 'alphanumeric' or 'date'
 * @param data which needs to be sorted
 * @param callback function which executes after sorting is completed
 * @returns {*} sorted data from callback function
 * @constructor
 */
const SortData = (sortByCol, sortDirection, sortType, data, callback) => {
  const copyData = JSON.parse(JSON.stringify(data));

  if (sortType === "alphanumeric") {
    //If its null or DSC it will be 1 or else -1
    const isReversed =
      sortDirection === null || sortDirection === "DSC" ? 1 : -1;

    copyData.sort(
      (a, b) => isReversed * (a[sortByCol] > b[sortByCol] ? -1 : 1)
    );
  }

  return callback(copyData);
};

export const SortSubGroupData = (
  sortByCol,
  sortDirection,
  sortType,
  data,
  callback
) => {
  const copyData = JSON.parse(JSON.stringify(data));
  if (sortType === "alphanumeric") {
    //If its null or DSC it will be 1 or else -1
    const isReversed =
      sortDirection === null || sortDirection === "DSC" ? 1 : -1;

    copyData.forEach((group) => {
      group.namePlateCategorySubGroup.sort(
        (a, b) => isReversed * (a[sortByCol] > b[sortByCol] ? -1 : 1)
      );
    });
  }
  return callback(copyData);
};

export const SortAccessoriesData = (
  sortByCol,
  sortDirection,
  sortType,
  data,
  callback
) => {
  const copyData = JSON.parse(JSON.stringify(data));
  if (sortType === "alphanumeric") {
    //If its null or DSC it will be 1 or else -1
    const isReversed =
      sortDirection === null || sortDirection === "DSC" ? 1 : -1;

    copyData.forEach((group) => {
      group[1].sort(
        (a, b) => isReversed * (a[sortByCol] > b[sortByCol] ? -1 : 1)
      );
    });
  }
  return callback(copyData);
};

export default SortData;
