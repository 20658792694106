import React, { useContext, useEffect } from "react";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import { ModalContext } from "../ModalContext";
import { useQueryClient } from "@tanstack/react-query";
import {
  doesAttestationExist,
  setAttestation,
} from "../../../services/localstorage/LocalStorageService";
import {
  trackTimeOfSaleReportPopupAction,
  trackTimeOfSaleReportPopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../../context/userContext";
import { Link } from "react-router-dom";
import { trackReferralExit } from "../../../services/analytics/AnalyticsService";

const TimeOfSaleReport = ({ orderId }) => {
  const { handleModal } = useContext(ModalContext);
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const queryClient = useQueryClient();
  const user = useContext(UserContext);
  const customLink = "https://www.irs.gov";

  useEffect(() => {
    trackTimeOfSaleReportPopupLoad(orderId, user);
  }, []);

  useEffect(() => {
    const existingAttestation = doesAttestationExist(orderId, "time-of-sale");

    if (existingAttestation) {
      setChecked(true);
      setDisabled(true);
    }
  }, [orderId]);

  const handleChange = (checkedBool) => {
    if (doesAttestationExist(orderId, "time-of-sale")) {
      return;
    } else {
      setChecked(checkedBool);
      setDisabled(!checkedBool);
    }
  };

  const saveAttestation = () => {
    setAttestation("time-of-sale", orderId, new Date());
    handleModal(false, false);
    queryClient.invalidateQueries("order", { orderId });
    trackTimeOfSaleReportPopupAction("confirm completed", orderId, user);
  };

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 6 }}>
        You must submit a time of sale report on the IRS website:{" "}
        <Link
          to={{
            pathname: customLink,
          }}
          target="_blank"
          referrer="noreferrer"
          onClick={() => trackReferralExit(customLink, orderId, user)}
        >
          {customLink}
        </Link>
      </Typography>
      <Box sx={{ mb: 10 }}>
        <EMPCheckbox
          label="I attest that I have completed this report and submitted it to the IRS."
          checked={checked}
          handleChange={(checkedBool) => handleChange(checkedBool)}
        />
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() => {
                handleModal(false, false);
                trackTimeOfSaleReportPopupAction("cancel", orderId, user);
              }}
            >
              Cancel
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => saveAttestation()}
              disabled={disabled}
            >
              Confirm Completed
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default TimeOfSaleReport;
