import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CANADA_FRENCH, CANADA_ENGLISH } from "../../common/Constants";
import LanguageUtil from "../../utils/LanguageUtil";
import { publish } from "../../common/PubSub";
import styles from "./Footer.module.scss";
import EMPBranding from "../components/EMPBranding/EMPBranding";
import { Typography, Box } from "@mui/material";
import { usePermission } from "../hooks/usePermission";
import { ModalContext } from "../components/EMPModal/ModalContext";
import HelpAndSupport from "../components/EMPModal/ModalFragments/HelpAndSupport";
import i18n from "i18next";

const Footer = (props) => {
  const { t } = useTranslation();
  const canSetLanguage = usePermission("languagePreference:write");
  const hasHelpAndSupportLink = usePermission("helpAndSupportLink");
  const hasHelpAndSupportModal = usePermission("helpAndSupportModal");
  const { handleModal } = useContext(ModalContext);

  const updateLanguage = (language) => {
    LanguageUtil.updateLanguage({ i18n }, language);
    publish("language", language);
  };

  return (
    <Box
      component="footer"
      sx={{
        py: 8,
        px: { xs: 4, sm: 4, md: 4, lg: 14 },
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: { xs: "flex-start", lg: "space-between" },
        width: "100%",
      }}
      data-testid="appFooter"
      className={styles.footerBox}
    >
      <div>
        <EMPBranding></EMPBranding>
      </div>
      <div className={styles.helpContainer}>
        <ul>
          {canSetLanguage && (
            <>
              <li>
                <Typography
                  variant="body2"
                  data-testid="english"
                  onClick={() => updateLanguage(CANADA_ENGLISH)}
                  sx={{ mb: 0, color: "#000000", cursor: "pointer" }}
                >
                  English
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body2"
                  data-testid="french"
                  sx={{ mb: 0, color: "#000000", cursor: "pointer" }}
                  onClick={() => updateLanguage(CANADA_FRENCH)}
                >
                  Français
                </Typography>
              </li>
            </>
          )}
          <li>
            <Link to="/ecommerce-releases">
              <Typography
                variant="body2"
                sx={{ mb: 0, color: "#000000", cursor: "pointer" }}
              >
                Upcoming Changes to eCommerce
              </Typography>
            </Link>
          </li>
          {hasHelpAndSupportLink && (
            <li>
              <Link to="/help">
                <Typography
                  variant="body2"
                  sx={{ mb: 0, color: "#000000", cursor: "pointer" }}
                >
                  {props.helpSupportTitle}
                </Typography>
              </Link>
            </li>
          )}
          {hasHelpAndSupportModal && (
            <li>
              <Typography
                variant="body2"
                sx={{ mb: 0, color: "#000000", cursor: "pointer" }}
                onClick={() =>
                  handleModal(
                    "Help & Support",
                    <HelpAndSupport
                      closeAction={() => handleModal(false, false)}
                    />
                  )
                }
              >
                {t("Footer.help")}
              </Typography>
            </li>
          )}
        </ul>
      </div>
    </Box>
  );
};

export default Footer;
