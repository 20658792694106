import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import AcceptPurchaseDeposit from "./AcceptPurchaseDeposit";
import RejectPurchaseDeposit from "./RejectPurchaseDeposit";
import { trackPurchaseDepositDecisionPopupLoad } from "../../../services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../../context/userContext";
import DecisionIntro from "./DecisionIntro";

const PurchaseDepositDecision = ({ orderId }) => {
  const [decision, setDecision] = useState(null);
  const { t } = useTranslation();
  const user = useContext(UserContext);

  if (decision === "accept") {
    return (
      <AcceptPurchaseDeposit
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else if (decision === "cancel") {
    return (
      <RejectPurchaseDeposit
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else {
    return (
      <DecisionIntro
        handleDecision={(decision) => setDecision(decision)}
        text={t("common.actions.reviewPurchaseDepositExtended")}
        primaryActionText={t("common.buttonCtas.acceptPurchaseDeposit")}
        secondaryActionText={t("common.buttonCtas.rejectPurchaseDeposit")}
        analyticsPageView={trackPurchaseDepositDecisionPopupLoad(orderId, user)}
      />
    );
  }
};

export default PurchaseDepositDecision;
