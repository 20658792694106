import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./SearchResultsTable.module.scss";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../common/HasPermissionTo";
import UiDateUtils from "../../utils/UiDateUtils";
import LowerCaseText from "../../utils/LowerCaseText";
import EMPObjectCard from "../../shared/components/EMPDataCard/EMPObjectCard";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { Link } from "react-router-dom/cjs/react-router-dom";

class SearchResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconUp: true,
    };
  }

  determineRegionalId() {
    const filteredDealer = this.props.user.dealerGroup.filter(
      (dealer) => dealer.commonId === this.props.commonDealerId
    );
    if (filteredDealer && filteredDealer.length === 1)
      return filteredDealer[0].regionalId;
  }

  translateOrderType(reservation) {
    //Purchase Request
    if (reservation.purchaseType || reservation.purchaseRequestDate) {
      return this.props.t("CustomerReservations.purchaseRequest");
    }
    //Order
    else if (LowerCaseText(reservation.orderType).includes("order")) {
      return this.props.t("CustomerReservations.order");
    } else return reservation.orderType;
  }

  render() {
    return (
      <div className={styles.tablePadding}>
        {this.props.reservations.map((reservation) => (
          <>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              columnGap={4}
              rowGap={4}
              alignItems="center"
              key={reservation.id}
            >
              <Box gridColumn={{ xs: "span 2" }}>
                <HasPermissionTo
                  perform={["reservationsRegionalId:view"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => (
                    <span className={styles.pre}>
                      {this.determineRegionalId() + "  |  "}
                    </span>
                  )}
                />
                <Typography variant="h4">{reservation.id}</Typography>
              </Box>
              <Box gridColumn={{ xs: "span 3" }}>
                <HasPermissionTo
                  perform={["crcOrderDetails:view"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => (
                    <EMPButton
                      variant="text"
                      id="viewDetailsButton"
                      testId="crcView"
                      buttonElement="button"
                      onClick={() =>
                        this.props.showOrderDetails(
                          this.props.commonDealerId,
                          reservation.id,
                          true
                        )
                      }
                    >
                      {this.props.t("CrcOrderSearch.viewDetails")}
                    </EMPButton>
                  )}
                  noRender={() => (
                    <Link
                      id="reservationLink"
                      to={`/customer-handling/${reservation.id}`}
                      className={styles.tableLink}
                    >
                      {this.props.t("CustomerReservations.view")}
                    </Link>
                  )}
                />
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: "span 12",
              }}
            >
              <EMPObjectCard
                data={{
                  customerName: {
                    heading: "Customer Name",
                    content: reservation.customerName || "--",
                  },
                  dealerName: {
                    heading: "Dealer Name",
                    content: reservation.dealerName || "--",
                  },
                  vehicle: {
                    heading: "Vehicle",
                    content: `${reservation.modelYear} ${reservation.nameplate}`,
                  },
                  date: {
                    heading: "Date",
                    content: UiDateUtils.formattedDate(
                      reservation.purchaseRequestDate
                    ),
                  },
                  orderType: {
                    heading: "Order Type",
                    content: this.translateOrderType(reservation),
                  },
                  status: {
                    heading: "Status",
                    content: reservation.businessStatus,
                  },
                }}
              />
            </Box>
          </>
        ))}
      </div>
    );
  }
}

export default withTranslation("emp")(SearchResultsTable);
