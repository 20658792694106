import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

class HelpAndSupportClient {
  static baseUrl = BASE_URL;

  static getFAQs(token) {
    const url = `${this.baseUrl}/faq`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default HelpAndSupportClient;
