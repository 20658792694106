import {
  FETCH_TASKS,
  FILTER_TASKS,
  SET_TASKS,
} from "../constants/PreDeliveryTaskConstants";

const taskReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TASKS:
      return {
        ...state,
        loading: !action.payload?.error,
        error: action.payload?.error || null,
      };
    case SET_TASKS: {
      const filteredTasks = action.payload?.data[action.payload.taskType];
      return {
        ...state,
        tasks: action.payload.data,
        filteredTasks,
        loading: false,
        error: null,
      };
    }
    case FILTER_TASKS: {
      return {
        ...state,
        filteredTasks: action.payload,
      };
    }
    default:
      return state;
  }
};

export { taskReducer, FETCH_TASKS, FILTER_TASKS, SET_TASKS };
