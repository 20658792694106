import React, { Component } from "react";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Search from "../components/search/Search";
import { withTranslation } from "react-i18next";
import Footer from "../shared/Footer/Footer";
import Crc from "./pure/Crc";
import withIdleTimer from "../common/WithIdleTimer";
import { Route, Router } from "react-router-dom";
import { ENGLISH } from "../common/Constants";
import { UserContext } from "../shared/context/userContext";
import OrderDetailsPage from "../orders/order-details/OrderDetailsPage";
import EMPFieldSupportBar from "../shared/components/EMPFieldSupportBar/EMPFieldSupportBar";
import { ModalProvider } from "../shared/components/EMPModal/ModalContext";
import styles from "./App.module.scss";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

class CRCApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      userDetails: {},
      user: new Crc(
        this.props.token,
        this.props.userDetails,
        this.props.history
      ),
    };
    this.currentCrc = new Crc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );

    this.setUserDetails = this.setUserDetails.bind(this);
  }

  componentDidMount() {
    this.props.history.push(`/`);
    this.props.i18n.changeLanguage(ENGLISH);
    this.setState({ renderApp: true });
  }

  setUserDetails(user, id) {
    this.setState({ user });
    this.props.history.push("/customer-handling/" + id);
  }

  render() {
    return (
      this.state.renderApp && (
        <UserContext.Provider value={this.state.user}>
          <Router history={this.props.history}>
            <section className={styles.fullHeightLayout}>
              <div className={styles.fullHeightItem}>
                <EMPFieldSupportBar />
                <GlobalNavBar
                  {...this.props}
                  urlPath={window.location.pathname}
                  user={this.currentCrc}
                />
              </div>
              <div className={styles.fullHeightItem}>
                <Route
                  exact
                  path="/"
                  component={withIdleTimer(() => (
                    <Search
                      {...this.props}
                      user={this.currentCrc}
                      setUserDetails={this.setUserDetails}
                    />
                  ))}
                />
                <Route path="/customer-handling/:orderId">
                  <OrderDetailsPage />
                </Route>

                <Route
                  exact
                  path="/dashboard"
                  render={() => (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.fullHeightItem}>
                <ModalProvider>
                  <Footer
                    user={this.currentCrc}
                    footerTitle={this.props.t("Footer.baseTitle")}
                    helpSupportTitle={this.props.t("Footer.help")}
                    permissions={this.props.userDetails.permissions}
                  />
                </ModalProvider>
              </div>
            </section>
          </Router>
        </UserContext.Provider>
      )
    );
  }
}

export default withTranslation("emp")(CRCApp);
