import React, { Component } from "react";
import styles from "./Search.module.scss";
import SubNav from "../../shared/subnav/SubNav";
import { LANGUAGE_MARKET_MAP } from "../../common/Constants";
import { AVAILABLE_MARKETS } from "./AvailableMarkets";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import LocalizedInfoClient from "../../clients/LocalizedInfoClient";
import { is400 } from "../../errors/ErrorConstants";
import HasPermissionTo from "../../common/HasPermissionTo";
import CustomerEmailOrFullNameAndZipSearch from "./CustomerEmailOrFullNameAndZipSearch";
import CustomerEmailOrOrderIdSearch from "./CustomerEmailOrOrderIdSearch";
import SearchResultsTable from "./SearchResultsTable";
import { IS_CANADA_MARKET } from "../../utils/EmpUtil";
import OrderDetailsPage from "../../orders/order-details/OrderDetailsPage";
import { Typography, Box } from "@mui/material";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      searchFullNameQuery: "",
      searchZipQuery: "",
      reservations: {},
      showOrderDetails: false,
      loaded: false,
      selectedMarket: undefined,
      renderApp: false,
      orderSearchMethod: "searchByEmail",
    };
    this.isNADealer = null;
  }

  componentDidMount() {
    const defaultUserMarket = this.props.user.market.toUpperCase();

    this.isNADealer =
      this.props.user.market.toUpperCase() === "CAN" ||
      this.props.user.market.toUpperCase() === "USA";

    this.setState({ selectedMarket: defaultUserMarket });
    this.fetchLocalizedInfo(defaultUserMarket);
  }

  handleZipCodeFormat = (
    key,
    { target: { value } },
    isCanadaMarket = false
  ) => {
    const formattedValue = isCanadaMarket
      ? value.replace(/[^A-Za-z0-9\s-]/g, "") // Canadian format: letters, numbers, spaces, hyphens
      : value.replace(/[^\d]/g, "").slice(0, 5); // US format: 5 digits only

    this.setState({ [key]: formattedValue });
  };

  splitString = (str) => {
    const parts = str.split("|");
    return parts;
  };

  showOrderDetails = (commonId, id, showDetails) => {
    if (this.isNADealer) {
      //market, dealerId, salesCode, dealerBrand
      const parts = this.splitString(commonId);

      const customUserObj = {
        market: parts[0],
        dealerId: parts[1],
        salesCode: parts[2],
        dealerBrand: parts[3],
      };

      this.props.setUserDetails(
        {
          ...this.props.user,
          ...customUserObj,
        },
        id
      );
      this.setState({ showOrderDetails: true });
    } else {
      this.setState({ showOrderDetails: showDetails });
    }
  };

  searchOrders = (e) => {
    this.props.hideOrShow(true);
    e.preventDefault();
    CustomerReservationsClient.searchCustomerReservations(
      this.state.searchQuery.trim(),
      this.props.user,
      LANGUAGE_MARKET_MAP[this.state.selectedMarket.toUpperCase()],
      null,
      this.state.selectedMarket
    )
      .then((response) => {
        this.parseReservations(response);
      })
      .catch((error) => {
        if (is400(error)) {
          this.setState({ reservations: {} });
        }

        this.props.hideOrShow(false);
        this.setState({ loaded: true });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  searchOrdersWithLastNameAndZipCode = (e) => {
    this.props.hideOrShow(true);
    e.preventDefault();

    const lastName =
      this.state.searchFullNameQuery &&
      this.state.searchFullNameQuery.split(" ")[1];

    CustomerReservationsClient.searchCustomerReservations(
      "",
      this.props.user,
      LANGUAGE_MARKET_MAP[this.state.selectedMarket.toUpperCase()],
      null,
      this.state.selectedMarket,
      lastName,
      IS_CANADA_MARKET(this.props.user.market)
        ? this.state.searchZipQuery.replace("-", "").replace(" ", "")
        : this.state.searchZipQuery
    )
      .then((response) => {
        this.parseReservations(response);
      })
      .catch((error) => {
        if (is400(error)) {
          this.setState({ reservations: {} });
        }
        this.props.hideOrShow(false);
        this.setState({ loaded: true });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  parseReservations(reservations) {
    const tempReservations = {};
    reservations.forEach((reservation) => {
      if (reservation.dealerId in tempReservations) {
        tempReservations[reservation.dealerId].push(reservation);
      } else {
        tempReservations[reservation.dealerId] = [];
        tempReservations[reservation.dealerId].push(reservation);
      }
    });

    this.setState({
      reservations: tempReservations,
      loaded: true,
    });
    this.props.hideOrShow(false);
  }

  updateSearchQuery = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  changeMarket = (e) => {
    this.setState({
      selectedMarket: e.target.value,
      reservations: {},
      searchQuery: "",
      loaded: false,
    });
    this.fetchLocalizedInfo(e.target.value);
  };

  fetchLocalizedInfo = (marketID) => {
    LocalizedInfoClient.getLocalizedInfo(this.props.user, marketID).then(() => {
      this.setState({ renderApp: true });
    });
  };

  toggleRadio = (e) => {
    const searchMethod = e.target.name || e.target.value;

    if (searchMethod === "searchByNameAndZip") {
      this.setState({
        orderSearchMethod: searchMethod,
        searchQuery: "",
      });
    } else if (searchMethod === "searchByEmail") {
      this.setState({
        orderSearchMethod: searchMethod,
        searchFullNameQuery: "",
        searchZipQuery: "",
      });
    }
  };

  render() {
    return (
      this.state.renderApp &&
      (!this.state.showOrderDetails ? (
        <div>
          <section className={styles.pagePadding}>
            <SubNav
              showCustomDropdown={!this.isNADealer}
              value={this.state.selectedMarket}
              dropDownValues={AVAILABLE_MARKETS}
              onChange={this.changeMarket}
              title="Orders"
              {...this.props}
            />
            <div>
              <HasPermissionTo
                perform={["emailAndZipCodeOrderSearch"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <CustomerEmailOrFullNameAndZipSearch
                      {...this.props}
                      searchQuery={this.state.searchQuery}
                      searchFullNameQuery={this.state.searchFullNameQuery}
                      searchZipQuery={this.state.searchZipQuery}
                      orderSearchMethod={this.state.orderSearchMethod}
                      searchOrders={this.searchOrders}
                      searchOrdersWithLastNameZipCode={
                        this.searchOrdersWithLastNameAndZipCode
                      }
                      toggleRadio={this.toggleRadio}
                      updateSearchQuery={this.updateSearchQuery}
                      handleZipCodeFormat={this.handleZipCodeFormat}
                    />
                  );
                }}
                noRender={() => {
                  return (
                    <CustomerEmailOrOrderIdSearch
                      {...this.props}
                      searchQuery={this.state.searchQuery}
                      searchOrders={this.searchOrders}
                      toggleRadio={this.toggleRadio}
                      updateSearchQuery={this.updateSearchQuery}
                    />
                  );
                }}
              />
            </div>
            {Object.keys(this.state.reservations).length !== 0 ? (
              <Box sx={{ mt: 10 }}>
                <Typography variant="h3">
                  {this.props.t("CrcOrderSearch.searchResults")}
                </Typography>
                {Object.keys(this.state.reservations).map(
                  (commonDealerId, index) => {
                    return (
                      <SearchResultsTable
                        {...this.props}
                        showOrderDetails={this.showOrderDetails}
                        key={commonDealerId}
                        isNADealer={this.isNADealer}
                        index={index}
                        fromCrc
                        commonDealerId={commonDealerId}
                        totalNumberOfResults={
                          this.state.reservations[commonDealerId].length
                        }
                        reservations={this.state.reservations[commonDealerId]}
                      />
                    );
                  }
                )}
              </Box>
            ) : (
              this.state.loaded && (
                <div id="noOrders" className={styles.noOrders} key="none-found">
                  {this.props.t("CrcOrderSearch.noOrdersFoundErrorMessage")}
                </div>
              )
            )}
          </section>
        </div>
      ) : (
        <OrderDetailsPage />
      ))
    );
  }
}

export default Search;
