import React from "react";
import fordStyles from "./InactiveDealerNotification.module.scss";
import lincolnStyles from "./InactiveDealerNotification-Lincoln.module.scss";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import { ReactComponent as Warning } from "../../../imgs/warning-blue.svg";

let isFordBrand = true;
let styles = fordStyles;

const InactiveDealerNotification = ({ user, t }) => {
  const isDeactivated = () => {
    if (!user.dealerGroup) return false;
    const dealer = user.dealerGroup.filter(
      (dealer) => dealer.commonId === user.commonId
    )[0];
    if (!dealer) return false;
    const isDeactivated = dealer["isDeactivated"];

    return isDeactivated && isDeactivated.toLowerCase() === "true";
  };

  if (!isDeactivated()) return null;

  const updateStyles = (isFordBrand = true) => {
    styles = isFordBrand ? fordStyles : lincolnStyles;
  };

  if (user && user.brand) {
    isFordBrand = IS_FORD_BRAND(user.brand);
    updateStyles(isFordBrand);
  }

  const shiftUp = () => {
    const header = document.getElementById("appHeader");
    if (!header) return false;
    return header.clientHeight > 70;
  };

  return (
    <div className={`${styles.container} ${shiftUp() ? styles.shiftUp : ""}`}>
      <div className={styles.leftContent}>
        <Warning className={styles.warningIcon} />
        <span className={styles.actionRequired}>
          {t("InactiveDealerNotification.actionRequired")}
        </span>
      </div>
      <div className={styles.middleContent}>
        {t("InactiveDealerNotification.description")}
      </div>
      <div className={styles.rightContent}>
        <span className={styles.actionRequested}>
          {t("InactiveDealerNotification.actionRequested")}
        </span>
        <a
          className={styles.goToDealerPortal}
          href={t("InactiveDealerNotification.dealerPortalUrl")}
        >
          {t("InactiveDealerNotification.goToDealerPortal")}
        </a>
      </div>
    </div>
  );
};

export default withTranslation("emp")(InactiveDealerNotification);
