export default class UiDateUtils {
  static formattedDate(date) {
    return date === undefined || date === null ? "" : date.split("-").join("/");
  }

  static formatYYYYMMDDToEUWithoutTime(date) {
    if (date) {
      date = date.split("T")[0];
      if (date) {
        const dates = date.split("-");
        return dates[2] + "/" + dates[1] + "/" + dates[0];
      } else return "";
    } else return "";
  }

  static formatDateMmDdYyyy(date) {
    return (
      date &&
      (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getDate().toString().padStart(2, "0") +
        "/" +
        date.getFullYear()
    );
  }

  static formatDateYYYYMMDD(year, month, day) {
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  static formatDateDDMMYYYWithOutDashes(year, month, day) {
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const formattedDate = day.toString() + month.toString() + year.toString();
    return formattedDate;
  }

  static saveDateFormat(date) {
    return date === null ? "" : date.split("/").join("-");
  }

  static formatDateddmmyyyy(str) {
    if (typeof str === "undefined" || str === null) {
      return "Not Specified";
    }

    // Validate that the string is in the expected format (6+ digits)
    if (typeof str !== "string" || str.length < 6 || !/^\d+$/.test(str)) {
      return "Invalid Format";
    }

    // Extract day, month, year parts and format with slashes
    const day = str.slice(0, 2);
    const month = str.slice(2, 4);
    const year = str.slice(4);

    return `${day}/${month}/${year}`;
  }

  static formatDateDDMMYYYY(date) {
    return (
      date &&
      ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
    );
  }

  static getDateInYYYYMMDD(date) {
    return (
      date &&
      date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()
    );
  }

  static formatTodayDate(str) {
    const year = str.substring(0, 4);
    const month = str.substring(5, 7);
    const day = str.substring(8, 10);
    return new Date(year, month - 1, day);
  }

  static formatDateDDMMYYYYToMMDDYYYY(dateStr, currentSeparator, newSeparator) {
    if (dateStr) {
      const dates = dateStr.split(currentSeparator);
      if (dates.length === 3)
        return dates[1] + newSeparator + dates[0] + newSeparator + dates[2];
    }
    return null;
  }
  static formatyyyymmddToDDMMYYY(dateStr, currentSeparator) {
    if (dateStr) {
      const dates = dateStr.split(currentSeparator);
      if (dates.length === 3) return dates[2] + dates[1] + dates[0];
    }
    return null;
  }
  static addDays(days) {
    const today = new Date();
    return today.setDate(today.getDate() + days);
  }
  static addMonths(months) {
    const today = new Date();
    return today.setMonth(today.getMonth() + months);
  }
  static formatyyyymmddTOddmmyyyy(dateStr, currentSeparator, newSeperator) {
    if (dateStr) {
      const dates = dateStr.split(currentSeparator);
      if (dates.length === 3)
        return dates[2] + newSeperator + dates[1] + newSeperator + dates[0];
    }
    return null;
  }
  static nthNumber(number) {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  //Tuesday, April 22nd 2023 9:00am
  static formatToDayDateTime(dateStr) {
    const date = new Date(dateStr);

    return (
      date &&
      date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
        " " +
        date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
    );
  }
}
