import React, { Component } from "react";
import styles from "./Reporting.module.scss";
import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import { base64FileDownload, fileDownload } from "../../utils/FileDownloadUtil";
import { logMsg } from "../../common/Logger";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import {
  CA_BRONCO_CATALOG_ID,
  CA_BRONCO_SPORT_CATALOG_ID,
  CA_F150_CATALOG_ID,
  CANADA_FRENCH,
} from "../../common/Constants";
import NscReportingClient from "../../clients/NscReportingClient";
import DealerReportingClient from "../../clients/DealerReportingClient";
import { IS_CANADA_MARKET, NOTNULL_OR_NOTUNDEFINED } from "../../utils/EmpUtil";
import UpperCaseText from "../../utils/UpperCaseText";
import BrandSettingsClient from "../../clients/BrandSettingsClient";
import ReportFilter from "./ReportFilter";
import UiDateUtils from "../../utils/UiDateUtils";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.commonReport = [
      {
        name: "Reporting.report1Title",
        key: "report1",
        downloadLink: this.downloadDealerActivationReport.bind(this),
      },
    ];
    this.displayFilters = false;
    this.state = {
      reports: this.getReports(),
      dateRangeMethod: "auto",
      dateRange: this.props.t("fieldReporting.dropDowns.last30Days"),
      dateRanges: [
        this.props.t("fieldReporting.dropDowns.last30Days"),
        this.props.t("fieldReporting.dropDowns.last90Days"),
        this.props.t("fieldReporting.dropDowns.yearToDate"),
      ],
      fromDate: new Date(),
      toDate: new Date(),
    };
  }

  componentDidMount() {
    this.dealerBrandSettingsCheck();
  }

  handleFromDateChange = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  handleToDateChange = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  handleDateRangeChange = (e) => {
    this.setState({ dateRange: e.target.value });
  };

  toggleRadio = (e) => {
    this.setState({ dateRangeMethod: e.target.value });
  };

  getReports = () => {
    if (this.props.user.market.toLowerCase() === "can") {
      if (
        this.props.userDetails &&
        this.props.userDetails.roles &&
        this.props.userDetails.roles.includes("EMP_ADMIN")
      ) {
        return [
          ...this.commonReport,
          {
            name: "Reporting.report4Title",
            downLoadName: "Reporting.downloadDealerReportBronco",
            key: "report2",
            catalog: CA_BRONCO_CATALOG_ID,
            downloadLink: this.downloadDealerReservationSummary.bind(this),
          },
          {
            name: "Reporting.report5Title",
            downLoadName: "Reporting.downloadDealerReportBroncoSport",
            key: "report3",
            catalog: CA_BRONCO_SPORT_CATALOG_ID,
            downloadLink: this.downloadDealerReservationSummary.bind(this),
          },
          {
            name: "Reporting.report7Title",
            downLoadName: "Reporting.downloadDealerReportF150",
            key: "report4",
            catalog: CA_F150_CATALOG_ID,
            downloadLink: this.downloadDealerReservationSummary.bind(this),
          },
        ];
      } else if (
        this.props.userDetails &&
        this.props.userDetails.roles &&
        this.props.userDetails.roles.length === 1 &&
        this.props.userDetails.roles[0] === "EMP_ECOMM_ACCESSORIES"
      ) {
        return [];
      } else {
        this.displayFilters = UpperCaseText(this.props.user.market) === "CAN";
        return [
          {
            name: "Reservations",
            downLoadName: "Reservations",
            key: "report4",
            downloadLink: this.downloadDealerReservationReport.bind(this),
          },
        ];
      }
    } else {
      return [
        ...this.commonReport,
        {
          name: "Reporting.report2Title",
          downLoadName: "Reporting.downloadDealerReport",
          key: "report2",
          downloadLink: this.downloadDealerReservationSummary.bind(this),
        },
      ];
    }
  };

  getTodayDate() {
    const today = new Date();
    return `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  }

  redirectToAccessoryPricingSearch() {
    this.props.history.push({
      pathname: `/pricing/accessories`,
      state: { redirectToAccSearch: true },
    });
  }

  dealerBrandSettingsCheck() {
    BrandSettingsClient.getDealerBrandAdjustments(this.props.user)
      .then((data) => {
        const labourRate = data.laborRate;
        const markup = data.markup;
        if (
          NOTNULL_OR_NOTUNDEFINED(labourRate) &&
          NOTNULL_OR_NOTUNDEFINED(markup)
        ) {
          const accessoryPricingReport = {
            key: "report5",
            name: this.props.t("dealerReporting.report5Name"),
            downloadLink: this.redirectToAccessoryPricingSearch.bind(this),
          };
          this.setState({
            reports: [...this.state.reports, accessoryPricingReport],
          });
        }
      })
      .catch((error) => {
        logMsg("reason", error);
      });
  }

  downloadDealerReservationReport = (report) => {
    const reqData = {
      dateRange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    const dealerGroup =
      IS_CANADA_MARKET(this.props.user.market) &&
      this.props.user &&
      this.props.user.dealerGroup &&
      this.props.user.dealerGroup.filter(
        (d) => this.props.user.commonId === d.commonId
      );
    const lang =
      dealerGroup &&
      dealerGroup.length > 0 &&
      dealerGroup[0].language &&
      dealerGroup[0].language.toUpperCase() === "F"
        ? CANADA_FRENCH
        : this.props.user.lang;
    DealerReportingClient.downloadDealerReservationReportInCsv(
      this.props.user,
      lang,
      "",
      true,
      reqData,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      });
  };

  downloadDealerActivationReport = (report) => {
    this.props.hideOrShow(true);
    NscReportingClient.downloadDealerActivationReportInCsv(this.props.user)
      .then((res) => {
        const filename = this.props.t(report.name) + ".csv";
        const BOM = "\uFEFF";
        const response = BOM + res;
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadDealerReservationSummary = (report) => {
    this.props.hideOrShow(true);
    NscReportingClient.downloadDealerReservationSummary(
      this.props.user,
      this.props.user.market,
      report.catalog
    )
      .then((res) => {
        const filename = report.name + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  render() {
    return (
      <div id="reporting">
        <section className="pageWrapper">
          <div id="reportingHeader">
            <SubNav
              dashboard={false}
              title={this.props.t("Reporting.subnav1")}
              {...this.props}
            />
          </div>
          <div className="pagePaddingLeft pagePaddingTop">
            <InfoIconWithMessage
              message={
                this.displayFilters
                  ? this.props.t("Reporting.subHeaderWithFilter")
                  : this.props.t("Reporting.bodyCopy")
              }
            />
          </div>

          {this.displayFilters && (
            <div className="pagePaddingLeft pagePaddingTop">
              <ReportFilter
                dateRangeMethod={this.state.dateRangeMethod}
                dateRange={this.state.dateRange}
                dateRanges={this.state.dateRanges}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                toggleRadio={this.toggleRadio}
                handleDateRangeChange={this.handleDateRangeChange}
                handleFromDateChange={this.handleFromDateChange}
                handleToDateChange={this.handleToDateChange}
              />
            </div>
          )}

          <div>
            <Grid container className={styles.dataContainer}>
              <Grid
                item
                className={`${styles.headerContainer} ${styles.rowDiv} pagePaddingLeft pagePaddingRight`}
              >
                <div>{this.props.t("Reporting.columnTitle1")}</div>
              </Grid>
              {this.state.reports.map((report) => (
                <React.Fragment key={report.key}>
                  <Grid
                    container
                    className={`${styles.rowDiv} pagePaddingLeft pagePaddingRight`}
                  >
                    <Grid item md={10}>
                      <div className={styles.colValues}>
                        {this.props.t(report.name)}
                      </div>
                    </Grid>
                    {report.key !== "report5" && (
                      <Grid item md={2} className={`${styles.end}`}>
                        <EMPButton
                          onClick={() => report.downloadLink(report)}
                          variant="text"
                          leftIcon={<CloudDownloadIcon />}
                        >
                          {this.props.t("Reporting.download")}
                        </EMPButton>
                      </Grid>
                    )}
                    {report.key === "report5" && (
                      <Grid item md={2} className={`${styles.end}`}>
                        <EMPButton
                          onClick={() => report.downloadLink(report)}
                          variant="text"
                          leftIcon={<VisibilityIcon />}
                        >
                          {this.props.t("dealerReporting.view")}
                        </EMPButton>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("emp")(Reporting);
