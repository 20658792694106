import { ACCESSORIES_PRICING_URL } from "../utils/EmpUtil";
import axios from "axios";
import { getHeaders } from "../utils/AuthorizationUtil";
import { is401 } from "../errors/ErrorConstants";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";

export default class AccessoriesSearchClient {
  static baseUrl = ACCESSORIES_PRICING_URL;

  static getCategories(user, model, year) {
    const url = `${this.baseUrl}/accessories/search/categories?model=${model}&year=${year}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.categories;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static searchAccessories(user, model, year, category) {
    const url = `${
      this.baseUrl
    }/accessories/search?dealerCommonId=${user.getEncodedCommonId()}&model=${model}&year=${year}&category=${category}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.accessoriesOptions;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static searchAccessoriesByDateRange(user, days) {
    const url = `${
      this.baseUrl
    }/accessories/search/accessories?dealerCommonId=${user.getEncodedCommonId()}&dateRange=${days}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.accessoriesAddedByDateRange;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getAllVehicleLinesByModelAndYear(user, brand) {
    const url = `${this.baseUrl}/accessories/search/categories/vehicleLines?brand=${brand}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
