export const cancellationReasons = [
  {
    code: "PR_CUSTOMER_BOUGHT_OTHER_FORD_VEHICLE",
    displayText: "Customer bought other Ford vehicle",
  },
  {
    code: "PR_CUSTOMER_MOVED",
    displayText: "Customer moved",
  },
  {
    code: "PR_CUSTOMER_BOUGHT_VEHICLE_FROM_OTHER_BRAND",
    displayText: "Customer bought vehicle from other brand",
  },
  {
    code: "PR_CUSTOMER_DECIDED_NOT_TO_PURCHASE",
    displayText: "Customer decided not to purchase",
  },
  {
    code: "PR_VEHICLE_NO_LONGER_AVAILABLE",
    displayText: "Vehicle no longer available",
  },
  {
    code: "PR_UNRESPONSIVE_CUSTOMER",
    displayText: "Unresponsive customer",
  },
  {
    code: "PR_OTHER",
    displayText: "Other",
  },
];
