import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import EMPChip from "../EMPChip/EMPChip";
import SharedToolTip from "../../sharedToolTip/SharedToolTip";

const EMPObjectCard = ({ data, hasBackground, twoByTwo }) => {
  const spanValue = twoByTwo
    ? { xs: "span 6" }
    : { xs: "span 6", sm: "span 3", md: "span 2" };
  const columnValue = Object.keys(data).length > 3 ? 12 : 6;

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          border: hasBackground ? "none" : "1px solid #B2B2B2",
          borderRadius: 2,
          background: hasBackground
            ? "linear-gradient(135deg, #F5F6F7 0%, #FFFFFF 100%)"
            : "transparent",
          height: "inherit",
          padding: hasBackground ? "8px 56px" : 0,
        }}
        display="grid"
        gridTemplateColumns={`repeat(${columnValue}, 1fr)`}
        columnGap={4}
        rowGap={0}
        data-testid="object-card-grid-container"
      >
        {Object.keys(data).map((item, index) => {
          const isFirstItem = index === 0 || index === 6;
          return (
            <React.Fragment key={data[item].heading}>
              <Box
                data-testid="object-card-wrapper"
                gridColumn={spanValue}
                sx={{
                  padding: () => {
                    if (
                      isFirstItem ||
                      (twoByTwo && index % 2 === 0) ||
                      (twoByTwo && index % 2 !== 0)
                    ) {
                      return "16px 0px 16px 16px";
                    }
                    if (!twoByTwo && !isFirstItem) {
                      return {
                        xs: "16px",
                        sm: "16px",
                        md: "16px 16px 16px 0px",
                      };
                    }
                    return "16px";
                  },
                }}
              >
                <Typography variant="caption">
                  {data[item].heading}{" "}
                  {data[item].hasTooltip && (
                    <SharedToolTip body={data[item].toolTipMessage} />
                  )}
                </Typography>

                {data[item].highlight ? (
                  <Box>
                    <EMPChip
                      label={data[item].content}
                      labelColor={data[item].color}
                    />
                  </Box>
                ) : (
                  <Typography variant={hasBackground ? "body1" : "body2"}>
                    {data[item].content}
                  </Typography>
                )}
              </Box>
              {twoByTwo
                ? (index + 1) % 2 === 0 &&
                  index + 1 < Object.keys(data).length && (
                    <Divider
                      sx={{ gridColumn: `span ${columnValue}`, my: 0 }}
                    />
                  )
                : (index + 1) % 6 === 0 &&
                  index + 1 < Object.keys(data).length && (
                    <Divider
                      sx={{ gridColumn: `span ${columnValue}`, my: 0 }}
                    />
                  )}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default EMPObjectCard;
