import React from "react";
import { invalidateSessionAndRedirect } from "./InvalidateSession";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import styles from "../shared/globalNavBar/GlobalNavBar.module.scss";
import { withTranslation } from "react-i18next";

export default function withIdleTimer(Component) {
  const DIALOG_OPEN_TIMEOUT = 10000 * 84;
  const WARNING_COUNTDOWN_SECONDS = 60;

  return withTranslation("emp")(
    class Timer extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          open: false,
          dialogueCountdown: WARNING_COUNTDOWN_SECONDS,
        };

        this.countDown = this.countDown.bind(this);
      }

      componentDidMount() {
        this.events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress",
        ];

        for (const i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
      }

      handleLogoutDialogOpen = () => {
        this.setState({ open: true });
        this.startTimer();
      };

      handleLogoutDialogClose = () => {
        clearInterval(this.timer);
        this.setState({
          open: false,
          dialogueCountdown: WARNING_COUNTDOWN_SECONDS,
        });
      };

      resetTimeout = () => {
        this.warnTimeout && clearTimeout(this.warnTimeout);
        this.warnTimeout = setTimeout(
          this.handleLogoutDialogOpen,
          DIALOG_OPEN_TIMEOUT
        );
      };

      setTimeout = () =>
        (this.warnTimeout = setTimeout(
          this.handleLogoutDialogOpen,
          DIALOG_OPEN_TIMEOUT
        ));

      startTimer() {
        if (this.state.dialogueCountdown > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
      }

      countDown() {
        const seconds = this.state.dialogueCountdown - 1;
        this.setState({
          dialogueCountdown: seconds,
        });

        if (seconds === 0) {
          clearInterval(this.timer);
          invalidateSessionAndRedirect();
        }
      }

      render() {
        const dialogText = this.props.t("Logout.confirmStaySignedIn");
        const seconds =
          this.state.dialogueCountdown > 60
            ? "60"
            : this.state.dialogueCountdown !== 0 &&
              this.state.dialogueCountdown;

        return (
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleLogoutDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span className={styles.logoutConfirmationFont}>
                    {dialogText.replace("%s", seconds)}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleLogoutDialogClose} color="primary">
                  {this.props.t("Logout.staySignedIn")}
                </Button>
              </DialogActions>
            </Dialog>
            <Component {...this.props} />
          </div>
        );
      }
    }
  );
}
