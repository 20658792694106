import { CARD_STATUS_ORDER } from "../constants/ActionCardConstants";

const filterActionCards = (state, statuses) => {
  const statusMappings = [
    { key: "acceptRejectStatus", prefix: "accept-reject-status" },
    { key: "purchaseDepositStatus", prefix: "purchase-deposit-status" },
    { key: "deliveryStatus", prefix: "delivery-status" },
    { key: "tradeInStatus", prefix: "trade-in-status" },
    { key: "timeOfSaleReport", prefix: "time-of-sale-report" },
    { key: "evPowerPromise", prefix: "ev-power-promise" },
    { key: "routeOneStatus", prefix: "route-one-status" },
  ];

  return [...state].filter((directive) =>
    statusMappings.some(
      (mapping, index) =>
        directive.id === `${mapping.prefix}-${statuses[index][mapping.key]}`
    )
  );
};

const updateCardData = (card, payload) => {
  const updates = {
    "accept-reject-status-accepted": () => ({
      ...card,
      completedDate: payload[0].actionDate,
      user: payload[0].actionUserId,
      cancellationReason: payload[0].cancellationReason,
    }),
    "accept-reject-status-canceled": () => ({
      ...card,
      completedDate: payload[0].actionDate,
      user: payload[0].actionUserId,
      cancellationReason: payload[0].cancellationReason,
    }),
    "accept-reject-status-cancelled": () => ({
      ...card,
      completedDate: payload[0].actionDate,
      user: payload[0].actionUserId,
      cancellationReason: payload[0].cancellationReason,
    }),
    "purchase-deposit-status-accepted": () => ({
      ...card,
      completedDate: payload[1].purchaseDepositDate,
    }),
    "purchase-deposit-status-rejected": () => ({
      ...card,
      completedDate: payload[1].purchaseDepositDate,
    }),
    "delivery-status-confirmed": () => ({
      ...card,
      text: `Delivery appointment is confirmed for ${payload[2].deliveryDate}`,
    }),
    "delivery-status-completed": () => ({
      ...card,
      text: `This order was delivered to the customer on ${payload[2].deliveryDate}`,
      completedDate: payload[2].deliveryDate,
    }),
    "trade-in-status-completed": () => ({
      ...card,
      completedDate: payload[3].tradeInDate,
    }),
    "time-of-sale-report-completed": () => ({
      ...card,
      completedDate: payload[4].timeOfSaleReportDate,
    }),
    "ev-power-promise-completed": () => ({
      ...card,
      completedDate: payload[5].evPowerPromiseDate,
    }),
    "route-one-status-created": () => ({
      ...card,
      text: `RouteOne`,
    }),
  };

  return updates[card.id] ? updates[card.id]() : card;
};

const checkForCancel = (state) => {
  const canceledCard = state.find((card) =>
    [
      "accept-reject-status-canceled",
      "accept-reject-status-cancelled",
    ].includes(card.id)
  );
  const refundCard = state.find(
    (card) => card.id === "purchase-deposit-status-refund"
  );

  if (canceledCard && refundCard) return [canceledCard, refundCard];
  if (canceledCard) return [canceledCard];
  return [...state];
};

const checkForRouteOne = (state) => {
  const createdCard = state.find((card) =>
    ["route-one-status-created"].includes(card.id)
  );

  if (createdCard) return [createdCard];
  return [...state];
};

const actionCardReducer = (state, action) => {
  const handlers = {
    SET_ACTION_CARDS: () => filterActionCards(state, action.payload.statuses),
    SORT_ACTION_CARDS: () =>
      [...state].sort(
        (a, b) => CARD_STATUS_ORDER[a.status] - CARD_STATUS_ORDER[b.status]
      ),
    UPDATE_COMPLETED_DATE: () =>
      state.map((card) => updateCardData(card, action.payload)),
    CHECK_FOR_CANCEL: () => checkForCancel(state),
    CHECK_FOR_ROUTEONE: () => checkForRouteOne(state),
  };

  return handlers[action.type] ? handlers[action.type]() : state;
};

export { actionCardReducer };
