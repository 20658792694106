import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import CancelPurchaseRequest from "./CancelPurchaseRequest";
import AcceptPurchaseRequest from "./AcceptPurchaseRequest";
import { trackPurchaseRequestDecisionPopupLoad } from "../../../services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../../context/userContext";
import DecisionIntro from "./DecisionIntro";

const PurchaseRequestDecision = ({ orderId }) => {
  const [decision, setDecision] = useState(null);
  const { t } = useTranslation();
  const user = useContext(UserContext);

  if (decision === "accept") {
    return (
      <AcceptPurchaseRequest
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else if (decision === "cancel") {
    return (
      <CancelPurchaseRequest
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else {
    return (
      <DecisionIntro
        handleDecision={(decision) => setDecision(decision)}
        text="Confirm or cancel this customer's purchase request. You can modify this later in the Pricing tab."
        primaryActionText={t("common.buttonCtas.acceptPurchaseRequest")}
        secondaryActionText={t("common.buttonCtas.cancelPurchaseRequest")}
        analyticsPageView={trackPurchaseRequestDecisionPopupLoad(orderId, user)}
      />
    );
  }
};

export default PurchaseRequestDecision;
