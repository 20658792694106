/**
 * Maps vehicle model names to standardized formatted values
 * Used for analytics tracking and display normalization
 * @type {Object}
 */
const VehicleNameplateMap = {
  // Ford Models
  gt: "ford gt",
  mustang: "ford mustang",
  escape: "ford escape",
  "escape hybrid": "ford escape",
  "escape plug-in hybrid": "ford escape",
  bronco: "ford bronco",
  "bronco sport": "ford bronco sport",
  explorer: "ford explorer",
  edge: "ford edge",
  "mustang mach-e": "ford mach-e",
  expedition: "ford expedition",
  maverick: "ford maverick",
  ranger: "ford ranger",
  "f-150": "ford f-150",
  "super duty": "ford super duty",
  "f-150 lightning": "ford f-150 lightning",
  "transit connect": "ford transit connect",
  transit: "ford transit",

  // Lincoln Models
  navigator: "lincoln navigator",
  aviator: "lincoln aviator",
  corsair: "lincoln corsair",
  nautilus: "lincoln nautilus",

  // Function to get mapped nameplate or return original if not found
  getFormattedNameplate(nameplate) {
    if (!nameplate) return "";

    // Convert to string, trim and standardize case
    const standardName = String(nameplate).trim().toLowerCase();
    return this[standardName] || standardName;
  },
};

export default VehicleNameplateMap;
