import React, { Component } from "react";
import { Typography, Grid, Box, MenuItem } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../common/ValidationRules";
import { withTranslation } from "react-i18next";
import { logMsg } from "../../../common/Logger";
import {
  displayError,
  ERROR_PROCESSING,
  is404,
  isProcessingError,
  PAGE_ERROR,
} from "../../../errors/ErrorConstants";
import SortData from "../../../utils/SortUtil";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import CategorySettingsClient from "../../../clients/CategorySettingsClient";
import CategorySettingsRowHeader from "./CategorySettingsRowHeader";
import CategoryDropDown from "./CategoryDropDown";
import PublishButton from "../PublishButton";
import fordStyles from "../namePlateSettings/NamePlateSettings-Ford.module.scss";
import lincolnStyles from "../namePlateSettings/NamePlateSettings-Lincoln.module.scss";
import { BRAND_NAMES } from "../../../../src/common/Constants.js";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import CapitalizeEachWord from "../../../utils/CapitalizeEachWord";
import CapitalizeFirstWord from "../../../utils/CapitalizeFirstWord";

const select = "select";
const category = "CATEGORY";
let isFordBrand = true;
let styles = fordStyles;

class CategorySettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortKey: null,
      sortDirection: null,
      confirmDialog: false,
      selectedCategory: select,
      categoryDropDownValues: [],
      categorySettings: [],
      isDataLoaded: false,
      markupEditEnabled: false,
      laborRateEditEnabled: false,
      enablePublishBtn: false,
      overAllMarkup: "",
      overAllLaborRate: "",
      originalMarkup: "",
      hasError: [],
      overridesApplied: false,
      originalLaborRate: "",
      categorySettingsByGroupName: [],
      showCategoryChangeDialog: false,
    };
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:accessories pricing:category",
      this.props.user
    );
    ValidationRules();
    this.getCategorySettings();
  }

  handleConfirm = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ confirmDialog: true });
  };

  handleSubmit = () => {
    this.props.hideOrShow(true);
    const selectedCategoryGroupName = JSON.stringify(
      this.state.selectedCategory
    );
    const updatedCategorySettings = this.state.categorySettings.filter(
      (dealer) => JSON.stringify(dealer.groupName) === selectedCategoryGroupName
    );
    updatedCategorySettings.map((group) => {
      return group.categorySettingsSubGroup.forEach((subGroup) => {
        if (parseFloat(this.state.overAllMarkup)) {
          subGroup.markup = parseFloat(this.state.overAllMarkup);
        }
        if (parseFloat(this.state.overAllLaborRate)) {
          subGroup.laborRate = parseFloat(this.state.overAllLaborRate);
        }

        if (this.state.markupEditEnabled && this.state.overAllMarkup) {
          subGroup.markupType = category;
        }

        if (this.state.laborRateEditEnabled && this.state.overAllLaborRate) {
          subGroup.laborRateType = category;
        }
      });
    });
    CategorySettingsClient.saveDealerCategorySettings(
      this.props.user,
      updatedCategorySettings,
      this.state.laborRateEditEnabled && this.state.overAllLaborRate !== "",
      this.state.markupEditEnabled && this.state.overAllMarkup !== ""
    )
      .then(() => {
        const categorySettingsByGroupName =
          this.filterCategorySettingsByCatalogId(
            updatedCategorySettings.filter(
              (dealer) =>
                JSON.stringify(dealer.groupName) === selectedCategoryGroupName
            )
          );
        const filteredGroupData = [
          {
            groupName: selectedCategoryGroupName,
            categorySettingsSubGroup: categorySettingsByGroupName[0],
          },
        ];
        this.setOriginalData(filteredGroupData);
        this.setState(
          {
            enablePublishBtn: false,
            markupEditEnabled: false,
            laborRateEditEnabled: false,
            confirmDialog: false,
            overridesApplied: true,
          },
          () => this.props.hideOrShow(false),
          this.props.user.showWarning(false)
        );
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.props.user.showWarning(false);
      });
  };

  handleClose = () => {
    this.setState({ confirmDialog: false, showCategoryChangeDialog: false });
  };

  getCategorySettings() {
    this.props.hideOrShow(true);

    let categorySettings = [];
    CategorySettingsClient.getDealerCategorySettings(
      this.props.user,
      IS_FORD_BRAND(this.props.user.brand)
        ? BRAND_NAMES.FORD
        : BRAND_NAMES.LINCOLN
    )
      .then((data) => {
        categorySettings = data;
        const categoryDropDownValues = categorySettings.map(
          (category) => category.groupName
        );
        this.setState({
          isDataLoaded: true,
          categoryDropDownValues,
          categorySettings,
        });
        this.confirmDialogText = {
          title: this.props.t("CategorySettings.dialogConfirmTitle"),
          bodyText: this.props.t("CategorySettings.dialogConfirmBody"),
          confirm: isFordBrand
            ? this.props.t("CategorySettings.dialogFordPublish")
            : this.props.t("CategorySettings.dialogLincolnConfirm"),
          cancel: isFordBrand
            ? this.props.t("CategorySettings.dialogFordGoBack")
            : this.props.t("CategorySettings.dialogLincolnCancel"),
        };
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  updateMarkupEditEnabled = () => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    this.setState(
      (prevState) => ({
        overAllMarkup: this.state.originalMarkup,
        overAllLaborRate: this.state.overAllLaborRate,
        markupEditEnabled: !prevState.markupEditEnabled,
      }),
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
          overridesApplied: false,
        });
      }
    );
  };

  updateLaborRateEditEnabled = () => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    this.setState(
      (prevState) => ({
        overAllLaborRate: this.state.originalLaborRate,
        laborRateEditEnabled: !prevState.laborRateEditEnabled,
      }),
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
          overridesApplied: false,
        });
      }
    );
  };

  updateModel = (event) => {
    this.props.user.showWarning(this.state.enablePublishBtn);
    let markup = this.state.overAllMarkup;
    let laborRate = this.state.overAllLaborRate;
    if ("markup" === event.target.name) {
      markup = event.target.value;
    } else {
      laborRate = event.target.value;
    }

    this.setState(
      {
        overAllMarkup: markup,
        overAllLaborRate: laborRate,
      },
      () => {
        this.setState({
          enablePublishBtn: this.enableOrDisableButton(),
        });
      }
    );
  };

  enableOrDisableButton = () => {
    const markup = this.state.overAllMarkup;
    const laborRate = this.state.overAllLaborRate;
    let enableApplyBtn = false;

    if (
      (this.state.laborRateEditEnabled && laborRate !== "") ||
      (this.state.markupEditEnabled && markup !== "")
    ) {
      enableApplyBtn = true;
    }
    return enableApplyBtn;
  };

  setOriginalData(categorySettingsByGroupName) {
    this.setState({
      categorySettingsByGroupName,
      originalMarkup: this.state.originalMarkup,
      overAllMarkup: this.state.overAllMarkup,
      originalLaborRate: this.state.originalLaborRate,
      overAllLaborRate: this.state.overAllLaborRate,
      isDataLoaded: true,
    });
  }

  filterCategorySettingsByCatalogId = (data) => {
    return data.map((group) => {
      const uniqueCatalogGroup = [];
      group.categorySettingsSubGroup.forEach((item) => {
        let isDuplicateCatalogId = false;
        uniqueCatalogGroup.forEach((uniqueItem) => {
          if (item.catalogId === uniqueItem.catalogId) {
            isDuplicateCatalogId = true;
          }
        });

        if (!isDuplicateCatalogId) {
          uniqueCatalogGroup.push(item);
        }
      });
      return uniqueCatalogGroup;
    });
  };

  filterCategorySettings = (event) => {
    const selectedCategoryGroup = this.state.selectedCategory;
    const categorySettingsByGroupName = this.filterCategorySettingsByCatalogId(
      this.state.categorySettings.filter((dealer) => dealer.groupName === event)
    );
    const filteredGroupData = [
      {
        groupName: selectedCategoryGroup,
        categorySettingsSubGroup: categorySettingsByGroupName[0],
      },
    ];
    this.setOriginalData(filteredGroupData);
  };
  updateCategory = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.targetCategory = event.target.value;
    if (this.state.enablePublishBtn) {
      this.setState({
        showCategoryChangeDialog: true,
      });
    } else {
      this.changeCategory();
    }
  };

  changeCategory = () => {
    this.setState(
      {
        selectedCategory: this.targetCategory,
        showCategoryChangeDialog: false,
        markupEditEnabled: false,
        enablePublishBtn: false,
        overridesApplied: false,
        overAllMarkup: "",
      },
      () => {
        this.filterCategorySettings(this.targetCategory);
      }
    );
  };
  sortColumns = (sortByCol) => {
    let sortDir = "ASC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;

    const sortInputData =
      this.state.categorySettingsByGroupName[0].categorySettingsSubGroup;
    const selectedGroupName = this.state.selectedCategory;

    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      sortInputData,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          categorySettingsByGroupName: [
            {
              groupName: JSON.stringify(selectedGroupName),
              categorySettingsSubGroup: sortedData,
            },
          ],
        });
      }
    );
  };

  render() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    styles = isFordBrand ? fordStyles : lincolnStyles;

    const selectedCategory = JSON.stringify(this.state.selectedCategory);
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);
    this.props.user.showWarning(this.state.enablePublishBtn);

    return this.state.isDataLoaded ? (
      <>
        <ValidatorForm onSubmit={(e) => this.handleConfirm(e)}>
          <div className={styles.pagePadding}>
            <Box>
              <Typography variant="h1" sx={{ my: 4 }}>
                Category Settings
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ my: 4 }}>
                {this.props.t("CategorySettings.subHeader")}
              </Typography>
            </Box>
            <CategoryDropDown
              {...this.props}
              styles={styles}
              MenuItem={MenuItem}
              selectedCategory={this.state.selectedCategory}
              categoryDropDownValues={this.state.categoryDropDownValues}
              updateCategory={this.updateCategory}
            />
          </div>
          {selectedCategory !== select &&
            this.state.categorySettingsByGroupName.length > 0 && (
              <CategorySettingsRowHeader
                {...this.props}
                updateMarkupEditEnabled={this.updateMarkupEditEnabled}
                updateLaborRateEditEnabled={this.updateLaborRateEditEnabled}
                updateModel={this.updateModel}
                sortKey={this.state.sortKey}
                styles={styles}
                sortColumns={this.sortColumns}
                sortDirection={this.state.sortDirection}
                markupEditEnabled={this.state.markupEditEnabled}
                laborRateEditEnabled={this.state.laborRateEditEnabled}
                overAllMarkup={this.state.overAllMarkup}
                overAllLaborRate={this.state.overAllLaborRate}
              />
            )}
          {selectedCategory !== select &&
            this.state.categorySettingsByGroupName.length > 0 && (
              <CategorySettingsTableBody
                categorySettingsByGroupName={
                  this.state.categorySettingsByGroupName
                }
                overAllMarkup={this.state.overAllMarkup}
                overAllLaborRate={this.state.overAllLaborRate}
              />
            )}

          {selectedCategory !== select &&
            this.state.categorySettingsByGroupName.length > 0 && (
              <PublishButton
                {...this.props}
                styles={styles}
                overridesApplied={this.state.overridesApplied}
                enablePublishBtn={this.state.enablePublishBtn}
              />
            )}
        </ValidatorForm>
        <ConfirmDialog
          open={this.state.confirmDialog || this.state.showCategoryChangeDialog}
          showWarningIcon={true}
          dialogProps={
            this.state.confirmDialog
              ? this.confirmDialogText
              : this.props.confirmDialogProps
          }
          onConfirm={
            this.state.confirmDialog ? this.handleSubmit : this.changeCategory
          }
          onCancel={this.handleClose}
          boldTitle={true}
          onClose={this.handleClose}
          disableConfirm={
            !this.props.user.permissions.rolePermissions.hasOwnProperty(
              "accessoriesSubmit"
            )
          }
          {...this.props}
        />
      </>
    ) : null;
  }
}

const CategorySettingsTableBody = (props) => {
  return props.categorySettingsByGroupName.map((category) => {
    return (
      category.categorySettingsSubGroup.length > 0 &&
      category.categorySettingsSubGroup.map((record) => {
        return (
          <div key={record.namePlate} className={styles.categoryRow}>
            <Grid container>
              <Grid item xs={4} className={styles.brand}>
                <div className={styles.brandName}>
                  {CapitalizeEachWord(record.namePlate).replace(
                    "Mach-e",
                    "Mach-E"
                  )}
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.brandValue}>
                  <p className={styles.brandText}>
                    {" "}
                    {props.overAllMarkup === ""
                      ? record.markup
                      : props.overAllMarkup}
                    &#37;
                  </p>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.brandValue}>
                  <p className={styles.brandText}>
                    {CapitalizeFirstWord(record.markupType)}
                  </p>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.brandValue}>
                  <p className={styles.brandText}>
                    &#36;
                    {props.overAllLaborRate === ""
                      ? record.laborRate
                      : props.overAllLaborRate}
                  </p>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.brandValue}>
                  <p className={styles.brandText}>
                    {CapitalizeFirstWord(record.laborRateType)}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      })
    );
  });
};

export default withTranslation("emp")(CategorySettings);
