import React, { Component } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "../styles/sharedStyles.scss";
import Nsc from "./pure/Nsc";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Footer from "../shared/Footer/Footer";
import InvalidRoute from "../components/accessDenied/InvalidRoute";
import Reporting from "../components/reporting/Reporting";
import BusinessSettings from "../components/businessSetting/BusinessSettings";
import { logMsg } from "../common/Logger";
import withIdleTimer from "../common/WithIdleTimer";
import AccessDeniedNotOnboardedNsc from "../components/accessDenied/AccessDeniedNotOnboardedNsc";
import HasPermissionTo from "../common/HasPermissionTo";
import NscDashboard from "../components/dashboard/nscDashBoard/NscDashboard";
import LocalizedInfoClient from "../clients/LocalizedInfoClient";
import ErrorScreen from "../errors/ErrorScreen";
import { withTranslation } from "react-i18next";
import { ModalProvider } from "../shared/components/EMPModal/ModalContext";

class NSCApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderApp: false,
      shouldNavigationBeRestricted: false,
    };
    this.user = new Nsc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );
    this.localizedInfo = {};

    // Listening for route change
    this.props.history.listen(() => this.setNavigationRestriction());
    this.navigateToRoute = this.navigateToRoute.bind(this);
    this.onReservationSave = this.onReservationSave.bind(this);
  }

  componentDidMount() {
    this.getLocalizedInfo();
  }

  onReservationSave() {
    this.onBoardingStatus = "ACCEPTED";
    this.navigateToRoute("dashboard");
  }

  navigateToRoute(link) {
    this.props.history.push(`/${link}`);
  }

  setNavigationRestriction() {
    if (this.onBoardingStatus !== "ACCEPTED") {
      this.setState({ shouldNavigationBeRestricted: true });
    } else {
      const isAllowedPath = this.isCurrentPathAllowedToShowHeaderAndFooter(
        this.props.history.location.pathname
      );
      this.setState({ shouldNavigationBeRestricted: !isAllowedPath });
    }
  }

  isCurrentPathAllowedToShowHeaderAndFooter(currentPathName) {
    const staticAllowedPaths = [
      "/dashboard",
      "/pricing",
      "/pricing/vehicles",
      "/pricing/accessories",
      "/pricing/deposit",
      "/business-settings",
      "/reporting",
      "/",
    ];

    return staticAllowedPaths.includes(currentPathName);
  }

  checkIsUserOnboarded() {
    this.isOnboarded()
      .then((val) => {
        this.onBoardingStatus = val.onboarded ? "ACCEPTED" : "PENDING";
        this.setNavigationRestriction();
      })
      .finally(() => {
        this.user.hasNscCompletedBasicPAs = true;
        this.setState({ renderApp: true });
      });
  }

  async isOnboarded() {
    return Promise.resolve({ onboarded: true });
  }

  getLocalizedInfo() {
    this.props.hideOrShow(true);
    LocalizedInfoClient.getLocalizedInfo(this.user)
      .then((response) => {
        this.localizedInfo = Object.assign(
          {},
          { ...this.localizedInfo },
          { ...response }
        );
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        this.checkIsUserOnboarded();
        this.props.hideOrShow(false);
      });
  }

  render() {
    const ProtectedRoute = ({ component: Component, perform, ...rest }) => (
      <HasPermissionTo
        perform={perform}
        permissions={this.user.permissions.rolePermissions}
        render={() => (
          <Route
            {...rest}
            render={() =>
              this.onBoardingStatus === "ACCEPTED" ? (
                <Component {...rest.customprops} {...this.props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        )}
      />
    );
    return (
      this.state.renderApp && (
        <Router history={this.props.history}>
          <div className="appWrapper" id="appWrapper">
            {!this.state.shouldNavigationBeRestricted && (
              <GlobalNavBar
                {...this.props}
                user={this.user}
                urlPath={window.location.pathname}
                navigateToRoute={this.navigateToRoute}
              />
            )}

            <Switch>
              <ProtectedRoute
                exact
                path="/dashboard"
                component={withIdleTimer(NscDashboard)}
                perform={["dashBoardPage"]}
                customprops={{
                  user: this.user,
                }}
              />

              <ProtectedRoute
                exact
                path="/reporting"
                component={withIdleTimer(Reporting)}
                perform={["reportingPage"]}
                customprops={{
                  user: this.user,
                }}
              />
              <ProtectedRoute
                exact
                path="/business-settings"
                component={withIdleTimer(BusinessSettings)}
                perform={["businessSettingsPage"]}
                customprops={{
                  user: this.user,
                  localizedInfo: this.localizedInfo,
                }}
              />

              <Route
                exact
                path="/denied-nsc"
                component={AccessDeniedNotOnboardedNsc}
              />
              <Route
                exact
                path="/"
                render={() =>
                  this.onBoardingStatus === "ACCEPTED" ? (
                    <Redirect
                      to={{
                        pathname: "/dashboard",
                      }}
                    />
                  ) : (
                    ""
                  )
                }
              />
              <Route
                exact
                path="/error"
                render={(props) => <ErrorScreen {...props} />}
              />
              <Route component={withIdleTimer(InvalidRoute)} />
            </Switch>
            {!this.state.shouldNavigationBeRestricted && (
              <ModalProvider>
                <Footer
                  user={this.user}
                  footerTitle={this.props.t("Footer.title")}
                  helpSupportTitle={this.props.t("Footer.help")}
                  permissions={this.props.userDetails.permissions}
                />
              </ModalProvider>
            )}
          </div>
        </Router>
      )
    );
  }
}

export default withTranslation("emp")(NSCApp);
