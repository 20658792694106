import React from "react";
import styles from "./InfoIcon.module.scss";

const InfoIcon = () => <span className={styles.infoIcon}>i</span>;

const InfoIconWithMessage = (props) => (
  <div className={styles.header}>
    <span className={styles.infoIcon}>i</span>
    <p>
      {props.message && Array.isArray(props.message)
        ? props.message.map((message) => (
            <span key={message}>{message}</span>
          ))
        : props.message}
    </p>
  </div>
);

export { InfoIcon, InfoIconWithMessage };
