import React from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import fordStylesOverride from "./ReportPreviewStylesOverride";
import lincolnStylesOverride from "./ReportPreviewLincolnStylesOverride";
import fordStyles from "./ReportPreview-Ford.module.scss";
import lincolnStyles from "./ReportPreview-Lincoln.module.scss";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

let styles = fordStyles;
let isFordBrand = true;
let stylesOverride = fordStylesOverride;

const updateStyles = (props) => {
  isFordBrand = IS_FORD_BRAND(props.user.dealerBrand);
  stylesOverride = isFordBrand ? fordStylesOverride : lincolnStylesOverride;
  document.body.style.backgroundColor = isFordBrand ? "#ffffff" : "#f6f7f7";
  styles = isFordBrand ? fordStyles : lincolnStyles;
};

const PurchaseRequestPreview = (props) => {
  updateStyles(props);

  const dealerName =
    props.dealerInfo && props.dealerInfo[0]
      ? props.dealerInfo[0].dealerName + " - "
      : "";

  return (
    <div className={styles.tablePadding}>
      <div className={styles.info}>
        {props.t("fieldReporting.table.pleaseSelectReport")}
      </div>
      <div className={styles.dealerName}>
        {props.t("fieldReporting.table.purchaseRequestDetails", {
          filterString: `${props.selectionTypes.model}, ${props.selectionTypes.modelYear}`,
        })}
      </div>
      <div className={styles.dealerInfo}>
        {isFordBrand
          ? UpperCaseText(dealerName) +
            UpperCaseText(props.selectionTypes.filterType) +
            props.selectionTypes.dateRange
          : UpperCaseText(dealerName) +
            props.selectionTypes.filterType +
            props.selectionTypes.dateRange}
      </div>
      <div className={styles.info}>
        <span>{props.t("fieldReporting.table.modelGroupingReportInfo")}</span>
        <EMPButton
          testId="downloadReport"
          disabled={!props.renderPreview}
          onClick={() => props.downloadFieldReport()}
          variant="text"
          leftIcon={<CloudDownloadIcon />}
          submitButton
        >
          {props.t("fieldReporting.download")}
        </EMPButton>
      </div>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell style={stylesOverride.cellWhiteBackground}>
              <div>{props.t("fieldReporting.table.description")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.active")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.canceled")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.total")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellWhiteBackground}>
              <div>{props.t("fieldReporting.table.purchaseReqAttn")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellWhiteBackground}>
              <div>{props.t("fieldReporting.table.purchasesAccepted")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellWhiteBackground}>
              <div>{props.t("fieldReporting.table.purchasesWithTrade")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.downPaymentsAccepted")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.downPaymentsRefunded")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.downPaymentsRejected")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellWhiteBackground}>
              <div>{props.t("fieldReporting.table.vehiclesOnHold")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.holdDepositsAccepted")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.holdDepositsRefunded")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.holdDepositsRejected")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.unconvertedOrders")}</div>
            </TableCell>
            <TableCell style={stylesOverride.cellGreyBackground}>
              <div>
                {props.t("fieldReporting.table.conversionRatePurchase")}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data !== null &&
            props.data.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.isTotal
                      ? props.selectionTypes.totalType
                      : item.description}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.active}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.cancelled}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.total}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.purchaseReqAttention}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.purchasesAccepted}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.purchaseWithTrade}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.downPaymentsAccepted}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.downPaymentsRefunded}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.downPaymentsRejected}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.vehiclesOnHold}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.holdDepositsAccepted}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.holdDepositsRefunded}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.holdDepositsRejected}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.unconvertedOrders}
                  </TableCell>
                  <TableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.otpRate}%
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation("emp")(PurchaseRequestPreview);
