import React, { useEffect } from "react";
import { Box, Typography, DialogActions, Grid } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";

const DecisionIntro = ({
  analyticsPageView,
  handleDecision,
  text,
  primaryActionText,
  secondaryActionText,
}) => {
  useEffect(() => {
    if (analyticsPageView) {
      analyticsPageView();
    }
  }, []);

  const handleClick = (decision) => {
    return handleDecision(decision);
  };

  return (
    <div>
      <Box>
        <Typography variant="body1" sx={{ mb: 6 }}>
          {text}
        </Typography>
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item>
            <EMPButton variant="outlined" onClick={() => handleClick("cancel")}>
              {secondaryActionText}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => handleClick("accept")}
            >
              {primaryActionText}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default DecisionIntro;
