import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import EMPChip from "../EMPChip/EMPChip";
import { styled } from "@mui/material/styles";
import EMPButton from "../EMPButton/EMPButton";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card, {
  name: "EMPActionCard",
  slot: "root",
})(() => ({
  background: "linear-gradient(135deg, #F5F6F7 0%, #FFFFFF 100%)",
  height: "100%",
}));

const StyledCardContent = styled(CardContent, {
  name: "EMPActionCard",
  slot: "content",
})(({ theme }) => ({
  padding: theme.spacing(0),
  display: "flex",
  flexDirection: "column",
  "&:last-child": {
    padding: theme.spacing(0),
  },
}));

const EMPActionCard = ({
  testId,
  hasActionButton,
  onClick,
  title,
  text,
  buttonText,
  buttonType,
  status,
  completedDate,
}) => {
  const { t } = useTranslation();
  let chipLabel;
  let chipColor;

  // Set chipLabel and chipColor based on status
  if (status === "action-required") {
    chipLabel = t("common.actionStatus.actionRequired");
    chipColor = "warning";
  } else if (status === "action-completed") {
    chipLabel = `${
      completedDate
        ? `${t("common.actionStatus.completedOn")} ${completedDate}`
        : t("common.actionStatus.completed")
    }`;
    chipColor = "success";
  } else if (status === "action-not-available") {
    chipLabel = t("common.actionStatus.notAvailableYet");
    chipColor = "default";
  }
  return (
    <StyledCard data-testid={testId} elevation={0}>
      <StyledCardContent sx={{ height: "100%" }}>
        {chipLabel && chipColor && (
          <Box sx={{ mb: 4 }}>
            <EMPChip
              label={chipLabel}
              labelColor={chipColor}
              testId="test-chip"
            />
          </Box>
        )}
        <Box>
          <Typography
            variant="h6"
            variantMapping={{ h6: "p" }}
            sx={{ color: "text.secondary", mb: 1 }}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {text}
          </Typography>
        </Box>
        {hasActionButton && (
          <Box sx={{ mt: 6, flex: 1, display: "flex", alignItems: "flex-end" }}>
            <Box
              sx={{
                flex: "0 0 auto",
              }}
            >
              <EMPButton
                variant={buttonType}
                testId={`${testId}-button`}
                onClick={onClick}
              >
                {buttonText}
              </EMPButton>
            </Box>
          </Box>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default EMPActionCard;
