import { Divider, Grid, Typography } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import React from "react";
import { withTranslation } from "react-i18next";
import { IS_CANADA_MARKET } from "../../utils/EmpUtil";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPValidatedInputField from "../../shared/components/EMPValidatedInputField/EMPValidatedInputField";

const CustomerEmailOrFullNameAndZipSearch = (props) => {
  return (
    <>
      <Grid container>
        <Grid item>
          <Typography variant="h1" sx={{ my: 4 }}>
            {props.t("CrcOrderSearch.orderSearch")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 6 }}>
            {props.t("CrcOrderSearch.subNav")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <ValidatorForm onSubmit={props.searchOrders}>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={12} sm={10}>
                <Typography variant="h3" sx={{ mb: 4 }}>
                  {props.t("CrcOrderSearch.searchByEmailOrOrderId")}
                </Typography>
                <EMPValidatedInputField
                  placeholder={props.t("CrcOrderSearch.customerEmailOrOrderId")}
                  value={props.searchQuery}
                  testId="crcEmailInput"
                  name="searchByEmail"
                  onChange={(e) => props.updateSearchQuery("searchQuery", e)}
                  onFocus={(e) => props.toggleRadio(e)}
                />
              </Grid>
              <Grid item>
                <EMPButton
                  variant="contained"
                  disabled={props.orderSearchMethod !== "searchByEmail"}
                  id="emailSearchBtn"
                  testId="searchBtn"
                  buttonElement="button"
                  submitButton
                >
                  {props.t("CrcOrderSearch.search")}
                </EMPButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 6 }} textAlign="center">
            <Typography variant="body1">
              {props.t("CrcOrderSearch.or")}
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ValidatorForm onSubmit={props.searchOrdersWithLastNameZipCode}>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={12} sm={10} md={10}>
                <Typography variant="h3" sx={{ mb: 4 }}>
                  {props.t("CrcOrderSearch.searchByFullName")}
                </Typography>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <EMPValidatedInputField
                      placeholder={props.t("CrcOrderSearch.firstAndLastName")}
                      testId="crcFullNameInput"
                      name="searchByNameAndZip"
                      value={props.searchFullNameQuery}
                      onChange={(e) =>
                        props.updateSearchQuery("searchFullNameQuery", e)
                      }
                      validators={[
                        "required",
                        "matchRegexp:^[a-zA-Z \\'\\,\\.\\-]+$",
                      ]}
                      errorMessages={[
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                      ]}
                      onFocus={(e) => {
                        props.toggleRadio(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {!IS_CANADA_MARKET(props.user.market) ? (
                      <EMPValidatedInputField
                        value={props.searchZipQuery}
                        testId="crcZipInput"
                        name="searchByNameAndZip"
                        placeholder={props.t("CrcOrderSearch.zip")}
                        onChange={(e) =>
                          props.handleZipCodeFormat("searchZipQuery", e)
                        }
                        validators={[
                          "minNumber:0",
                          "isNumber",
                          "matchRegexp:^\\d{5}(?:[- ]?\\d{4})?$",
                        ]}
                        errorMessages={[
                          props.t("CrcOrderSearch.invalid"),
                          props.t("CrcOrderSearch.invalid"),
                          props.t("CrcOrderSearch.invalid"),
                        ]}
                        onFocus={(e) => props.toggleRadio(e)}
                      ></EMPValidatedInputField>
                    ) : (
                      <EMPValidatedInputField
                        value={props.searchZipQuery}
                        testId="crcZipInput"
                        name="searchByNameAndZip"
                        placeholder="A1A 1A1"
                        onChange={(e) =>
                          props.handleZipCodeFormat("searchZipQuery", e, true)
                        }
                        onFocus={(e) => props.toggleRadio(e)}
                        validators={[
                          "required",
                          "matchRegexp:^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\\d[ABCEGHJ-NPRSTV-Zabceghj-nnprstv-z][ -]?\\d[ABCEGHJ-NPRSTV-Zabcegh-nprstv-z]\\d$",
                        ]}
                        errorMessages={[
                          props.t("CrcOrderSearch.invalid"),
                          props.t("CrcOrderSearch.invalid"),
                          props.t("CrcOrderSearch.invalid"),
                        ]}
                      ></EMPValidatedInputField>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <EMPButton
                  variant="contained"
                  disabled={props.orderSearchMethod !== "searchByNameAndZip"}
                  id="fullNameAndZipSearchBtn"
                  testId="fullNameAndZipSearchBtn"
                  buttonElement="button"
                  submitButton
                >
                  {props.t("CrcOrderSearch.search")}
                </EMPButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Grid>
    </>
  );
};
export default withTranslation("emp")(CustomerEmailOrFullNameAndZipSearch);
