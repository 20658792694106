import UpperCaseText from "./UpperCaseText";
import {
  PURCHASE_TYPES,
  PROD_SCHEDULER_URL,
  QA_SCHEDULER_URL,
} from "../common/Constants";

const CANCELLED_STATUSES = [
  "DEALER_CANCELLED",
  "ORDER_CANCELLED",
  "RESERVATION_CANCELLED",
];

const ACCEPT_ELIGIBLE_PURCHASE_TYPES = [
  PURCHASE_TYPES.THIRD_PARTY, //Pre arranged finance
  PURCHASE_TYPES.CREDIT_APPLICATION, //Credit app approved
  PURCHASE_TYPES.SIGN_ONLINE, //eSign
  PURCHASE_TYPES.DISCUSS_WITH_DEALER, //Discuss at Dealer
  PURCHASE_TYPES.SIGN_OFFLINE, //Sign offline
  PURCHASE_TYPES.CASH, //Cash
  PURCHASE_TYPES.CONTINUE_AT_DEALER, //Continue at Dealer
  PURCHASE_TYPES.SIGN_AT_DEALER,
  PURCHASE_TYPES.ABANDONED_CHECKOUT,
];

const ORA_STATUSES = ["NO_DEALER_ACTION", "ORDER_CONFIRMED", "AMENDED"];

const RESERVATIONS_SUBMITTED_STATUSES = [
  "CREATED",
  "RESERVATION_CANCELLED",
  "AMENDED",
  "DEALER_CANCELLED",
  "ORDER_CANCELLED",
];

const ORDERS_SUBMITTED_STATUSES = [
  "DEALER_REJECTED",
  "RESERVATION_CANCELLED",
  "ORDER_CANCELLED",
  "ORDERED",
  "DEALER_ACCEPTED",
  "CONTRACT_REQUESTED",
  "CONTRACT_REQUEST_FAILED",
  "CONTRACT_FAILED",
  "ORDER_CONTRACTED",
  "CONTRACT_CANCEL_REQUESTED",
  "CONTRACT_CANCEL_FAILED",
  "CONVERTED_TO_OFFLINE",
  "CONTRACT_CANCEL_REQUEST_FAILED",
  "DEALER_CANCELLED",
  "CREATED",
];

const IS_CANADA_MARKET = (market) => {
  return UpperCaseText(market) === "CAN";
};

const IS_USA_MARKET = (market) => {
  return UpperCaseText(market) === "USA";
};

const IS_NA_MARKET = (market) => {
  return UpperCaseText(market) === "USA" || UpperCaseText(market) === "CAN";
};

const IS_CRC_USER = (user) => {
  return UpperCaseText(user) === "CRC";
};

const IS_FORD_BRAND = (brand) => {
  return "F" === brand;
};

const IS_MODEL_E_ORDER = (journeyType) => {
  return journeyType && "MODEL-E" === journeyType.toUpperCase();
};

const HAS_VALUE = (input) => {
  return input !== null && input !== undefined;
};

const NULL_OR_UNDEFINED = (input) => {
  return input === null || input === undefined;
};

const NOTNULL_OR_NOTUNDEFINED = (input) => {
  return !NULL_OR_UNDEFINED(input);
};

const FORMAT_COMMON_ID = (commonDealerId) => {
  return commonDealerId.split("|").join("%7C");
};

const IS_FIELD_VIEW_USER = (user) => {
  return UpperCaseText(user) === "FIELD_VIEW";
};

const formatDate = (date) => {
  if (!date) {
    return;
  }
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}/${day}/${year}`;
};

const upperCaseText = (text) => {
  return text ? text.toUpperCase() : "";
};

const capitalizeText = (text) => {
  if (!text) return "";

  const formattedText = text.toLowerCase().split("_").join(" ");
  return formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
};

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber === "") {
    return "";
  }

  // Remove all non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Extract area code, central office code, and station code
  const areaCode = cleaned.slice(0, 3);
  const centralOfficeCode = cleaned.slice(3, 6);
  const stationCode = cleaned.slice(6, 10);

  // Format the phone number
  return `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
};

const formatToSimpleDate = (date) => {
  const dateStr = new Date(date);
  const month = String(dateStr.getMonth() + 1).padStart(2, "0");
  const day = String(dateStr.getDate()).padStart(2, "0");
  const year = dateStr.getFullYear();

  return `${month}/${day}/${year}`;
};

const GET_SCHEDULER_URL = (env) => {
  const schedulerUrls = {
    prod: PROD_SCHEDULER_URL,
    stg: QA_SCHEDULER_URL,
    edu: QA_SCHEDULER_URL,
    qa: QA_SCHEDULER_URL,
    dev: QA_SCHEDULER_URL,
    local: QA_SCHEDULER_URL,
  };

  return schedulerUrls[env];
};

const BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.BASE_URL : "";

const RESERVATION_BASE_URL =
  typeof window !== "undefined" && window.RESERVATION_SERVICE
    ? window.RESERVATION_SERVICE.BASE_URL
    : "";

const RESERVATION_ACTION_URL =
  typeof window !== "undefined" && window.RESERVATION_SERVICE
    ? window.RESERVATION_SERVICE.ACTION_URL
    : "";

const REPORTING_BASE_URL =
  typeof window !== "undefined" && window.REPORTING_SERVICE
    ? window.REPORTING_SERVICE.BASE_URL
    : "";

const SHARED_SERVICE_URL =
  typeof window !== "undefined" && window.EMP
    ? window.EMP.SHARED_SERVICE_URL
    : "";

const ENV = typeof window !== "undefined" && window.EMP ? window.EMP.ENV : "";

const ACCESSORIES_PRICING_URL =
  typeof window !== "undefined" && window.PRICING
    ? window.PRICING.ACCESSORIES_PRICING_URL
    : "";

const AUTHORIZE_BASE_URL =
  typeof window !== "undefined" && window.AUTH_SERVICE
    ? window.AUTH_SERVICE.BASE_AUTHORIZE_URL
    : "";

const TRANSLATION_BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.TRANSLATION_URL : "";

const formatVehicleTrim = (str) => {
  if (str === null) {
    return;
  }

  if (str === "GT") {
    return "GT";
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export {
  CANCELLED_STATUSES,
  ORA_STATUSES,
  RESERVATIONS_SUBMITTED_STATUSES,
  ORDERS_SUBMITTED_STATUSES,
  IS_FORD_BRAND,
  IS_CANADA_MARKET,
  IS_USA_MARKET,
  IS_FIELD_VIEW_USER,
  BASE_URL,
  SHARED_SERVICE_URL,
  RESERVATION_BASE_URL,
  RESERVATION_ACTION_URL,
  REPORTING_BASE_URL,
  ACCESSORIES_PRICING_URL,
  AUTHORIZE_BASE_URL,
  TRANSLATION_BASE_URL,
  HAS_VALUE,
  ACCEPT_ELIGIBLE_PURCHASE_TYPES,
  NOTNULL_OR_NOTUNDEFINED,
  NULL_OR_UNDEFINED,
  ENV,
  FORMAT_COMMON_ID,
  IS_NA_MARKET,
  IS_CRC_USER,
  IS_MODEL_E_ORDER,
  GET_SCHEDULER_URL,
  formatDate,
  upperCaseText,
  capitalizeText,
  formatPhoneNumber,
  formatVehicleTrim,
  formatToSimpleDate,
};
