import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./EMPInteractiveList.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// available props
// hasListAction: bool,
// listActionText: string,
// listActionOnClick: function,
// primaryActionOnClick: function,
// secondaryActionOnClick: function,
// items: array of objects
// taskName: string,
// taskDetails: string,
// hasActions: bool,
// primaryActionText: string,
// secondaryActionText: string,

const useStyles = makeStyles({
  buttonRoot: {
    justifyContent: "center",
    border: "1px solid #9C9C9C",
    borderRadius: 8,
    marginBottom: "16px",
    padding: "30px 16px",

    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#066FEF",
    },
  },
  root: {
    display: "flex",
    border: "1px solid #9C9C9C",
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "stretch",
    padding: 16,
    marginBottom: "16px",
  },
  container: {
    display: "flex",
    border: "1px solid #9C9C9C",
    borderRadius: 8,
    marginBottom: "16px",
    padding: 16,
  },
  secondaryAction: {
    flex: "0 1 auto",
    margin: 0,
    padding: 0,
    border: "none",
  },
  secondaryActionRoot: {
    position: "relative",
    top: "unset",
    left: "unset",
    right: "unset",
    transform: "none",
    flex: "0 0 auto",
  },
  textRoot: {
    margin: 0,
  },
  body1: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "inherit",
  },
  multiline: {
    margin: 0,
  },
  primary: {
    fontSize: "20px",
    lineHeight: "24px",
    color: "#00095B",
  },
  secondary: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#4D4D4D",
    marginTop: "8px",
  },
});

const EMPInteractiveList = ({
  hasListAction,
  listActionText,
  listActionOnClick,
  primaryActionOnClick,
  secondaryActionOnClick,
  items,
  disableListAction,
  hasActions,
  primaryActionText,
  secondaryActionText,
  testId,
  appear,
}) => {
  const classes = useStyles();

  return (
    <>
      {hasListAction && (
        <ListItem
          classes={{ root: classes.buttonRoot }}
          disabled={disableListAction}
          onClick={listActionOnClick}
        >
          <Link
            data-testid="list-action-button"
            component="button"
            variant="body1"
            underline="always"
          >
            {listActionText}
          </Link>
        </ListItem>
      )}
      <TransitionGroup component={List} data-testid={testId} appear={appear}>
        {items &&
          items.map(({ id, taskName, taskDescription, taskUrl }) => (
            <CSSTransition
              key={id}
              timeout={appear ? 500 : 0}
              classNames={appear ? "item" : ""}
              appear={appear}
            >
              <ListItem
                data-testid="interactive-list-item"
                key={id}
                classes={{
                  root: classes.root,
                  container: classes.container,
                  secondaryAction: classes.secondaryAction,
                }}
              >
                <ListItemText
                  primary={taskName}
                  secondary={taskDescription}
                  classes={{
                    root: classes.textRoot,
                    primary: classes.primary,
                    secondary: classes.secondary,
                    multiline: classes.multiline,
                  }}
                ></ListItemText>
                {hasActions && (
                  <ListItemSecondaryAction
                    classes={{ root: classes.secondaryActionRoot }}
                  >
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Link
                          data-testid="primary-action-link"
                          component="button"
                          variant="body1"
                          onClick={() =>
                            primaryActionOnClick({
                              id,
                              taskName,
                              taskDescription,
                              taskUrl,
                            })
                          }
                          underline="always"
                        >
                          {primaryActionText}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          data-testid="secondary-action-link"
                          component="button"
                          variant="body1"
                          onClick={() => secondaryActionOnClick(id)}
                          underline="always"
                        >
                          {secondaryActionText}
                        </Link>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </>
  );
};

export default EMPInteractiveList;
