import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Box, DialogActions, Grid, Typography } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import { ModalContext } from "../ModalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { UserContext } from "../../../context/userContext";
import { DEALER_ACTIONS } from "../../../../common/Constants";
import { useTranslation } from "react-i18next";
import EMPLoader from "../../EMPLoader/EMPLoader";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";
import {
  trackAcceptPurchaseRequestPopupAction,
  trackAcceptPurchaseRequestPopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import { usePermission } from "../../../hooks/usePermission";
import { useCanAutoAcceptDeposit } from "../../../hooks/useCanAutoAcceptDeposit";

const AcceptPurchaseRequest = ({ orderId, backButton, handleBack }) => {
  const { handleModal } = useContext(ModalContext);
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand, userId } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const isFieldSupport = useFieldSupportStatus();
  const canAutoAcceptDeposit = useCanAutoAcceptDeposit(orderId);
  const canAcceptPurchaseRequest = usePermission(
    "acceptPurchaseRequestNA:write"
  );

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  const { paymentType, orderConfirmedDate, todayDate } = customerReservation;

  useEffect(() => {
    trackAcceptPurchaseRequestPopupLoad(orderId, user);
  }, []);

  const orderCreatedDate = new Date(orderConfirmedDate).toISOString();
  const checkboxListItems = [
    "common.actions.acceptModal.checkboxList.0",
    "common.actions.acceptModal.checkboxList.1",
    "common.actions.acceptModal.checkboxList.2",
    "common.actions.acceptModal.checkboxList.3",
    "common.actions.acceptModal.checkboxList.4",
    "common.actions.acceptModal.checkboxList.5",
  ];

  const handleChange = (checkedBool) => {
    setChecked(checkedBool);

    if (isFieldSupport) {
      return;
    } else {
      setDisabled(!checkedBool);
    }
  };

  const ERROR_MESSAGES = {
    DEFAULT:
      "An unexpected error occurred. Please use the back button and try again.",
    PURCHASE_REQUEST_MESSAGE:
      "Purchase Request acceptance failed. Please try again.",
    PURCHASE_DEPOSIT_MESSAGE:
      "Purchase request has been accepted. Purchase deposit was not automatically accepted. Please accept the purchase deposit manually.",
    ORDER_ACCEPTANCE: "Order acceptance failed: Unknown error",
    DEPOSIT_ACCEPTANCE: "Deposit acceptance failed: Unknown error",
  };

  const parseErrorData = (errorData) => {
    if (!errorData) return null;
    try {
      const parsed =
        typeof errorData === "string" ? JSON.parse(errorData) : errorData;
      return parsed.errors?.[0]?.message;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to parse error message:", e);
      return null;
    }
  };

  const handleOtherErrorData = (otherErrorData) => {
    if (!otherErrorData) return null;
    if (
      typeof otherErrorData === "string" &&
      otherErrorData === ERROR_MESSAGES.ORDER_ACCEPTANCE
    ) {
      return ERROR_MESSAGES.PURCHASE_REQUEST_MESSAGE;
    }
    if (
      typeof otherErrorData === "string" &&
      otherErrorData === ERROR_MESSAGES.DEPOSIT_ACCEPTANCE
    ) {
      return ERROR_MESSAGES.PURCHASE_DEPOSIT_MESSAGE;
    }
    return otherErrorData;
  };

  const handleErrorMessage = () => {
    if (!submitStatus.error?.response) {
      return ERROR_MESSAGES.DEFAULT;
    }

    const { error, data } = submitStatus.error.response;

    if (error) return error;

    const parsedMessage = parseErrorData(data?.message);
    if (parsedMessage) return parsedMessage;

    const otherError = handleOtherErrorData(data?.error);
    if (otherError) return otherError;

    return ERROR_MESSAGES.DEFAULT;
  };

  const submitStatus = useMutation({
    mutationFn: (actionData) => {
      if (canAutoAcceptDeposit) {
        return CustomerReservationsClient.acceptRequestAndDeposit(
          sessionStorage.getItem("adfs.token"),
          userCommonId,
          actionData,
          userId
        );
      } else {
        return CustomerReservationsClient.actionOnOrder(
          sessionStorage.getItem("adfs.token"),
          userCommonId,
          actionData,
          userId
        );
      }
    },
    onSuccess: () => {
      trackAcceptPurchaseRequestPopupAction(
        paymentType,
        orderId,
        orderCreatedDate,
        user
      );

      handleModal(false, false);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      queryClient.invalidateQueries({
        queryKey: ["notifications", { orderId }],
      });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        // eslint-disable-next-line no-console
        console.log(`400 Error: ${errorMessage}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  if (submitStatus.status === "loading") {
    return (
      <Box sx={{ minHeight: contentHeight, py: 10 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <EMPLoader loadingMessage="Submitting..." />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (submitStatus.status === "error") {
    return (
      <>
        <Box sx={{ minHeight: contentHeight, pb: 4 }}>
          <Typography variant="body1">{handleErrorMessage()}</Typography>
        </Box>
        <DialogActions sx={{ padding: "16px 0" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {!canAutoAcceptDeposit && (
                <EMPButton
                  variant="text"
                  onClick={() => handleBack()}
                  testId="modal-cancel-back-button"
                >
                  Back
                </EMPButton>
              )}
            </Grid>
            <Grid item></Grid>
          </Grid>
        </DialogActions>
      </>
    );
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 6 }}>
        {t("common.actions.acceptModal.message")}
      </Typography>
      <Box sx={{ mb: 10 }}>
        <ul style={{ paddingLeft: "12px" }}>
          {checkboxListItems.map((item) => (
            <li key={item}>
              <Typography variant="body2">{t(item)}</Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box sx={{ mb: 4 }}>
        <EMPCheckbox
          label={t("common.actions.acceptModal.checkboxConfirm")}
          checked={checked}
          handleChange={(checkedBool) => handleChange(checkedBool)}
        />
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() =>
                backButton ? handleBack() : handleModal(false, false)
              }
              disabled={submitStatus.isLoading}
              testId="modal-cancel-back-button"
            >
              {backButton ? "Back" : t("common.cancel")}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() =>
                submitStatus.mutate({
                  reservationId: orderId,
                  acceptedDate: todayDate,
                  orderCreatedDate: orderCreatedDate,
                  dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_ACCEPTED,
                })
              }
              disabled={
                disabled ||
                submitStatus.status === "loading" ||
                submitStatus.status === "error" ||
                !canAcceptPurchaseRequest
              }
            >
              {t("common.buttonCtas.acceptPurchaseRequest")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export default AcceptPurchaseRequest;
