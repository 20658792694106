import React, { useContext, useEffect } from "react";
import EMPButton from "../../EMPButton/EMPButton";
import { Grid, DialogActions, Typography, Box } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useTranslation } from "react-i18next";
import HelpAndSupportClient from "../../../../clients/HelpAndSupportClient";
import { base64FileDownload } from "../../../../utils/FileDownloadUtil";
import * as AnalyticsService from "../../../services/analytics/AnalyticsService";
import { UserContext } from "../../../context/userContext";

const HelpAndSupport = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  useEffect(() => {
    AnalyticsService.trackPageLoadEvent(`fv:emp:help and support:popup`, user);
  }, []);

  const trackUserAction = (action) => {
    AnalyticsService.trackOnClickEvent(
      `fv:emp:help and support:popup`,
      `emp:help and support:popup:content:${action}`,
      `fv:emp:help and support:popup:content action`,
      user
    );
  };

  const downloadFile = () => {
    HelpAndSupportClient.getFAQs(sessionStorage.getItem("adfs.token"))
      .then((response) => {
        base64FileDownload(response, "application/pdf", "USA-FAQs.pdf");
        trackUserAction("pdf download");
      })
      .catch();
  };

  const mailToTechSupportEmail = `mailto:${encodeURIComponent(
    t("HelpAndSupport.techSupportEmail")
  )}`;

  const mailToSupportEmail = `mailto:${encodeURIComponent(
    t("HelpAndSupport.supportEmail")
  )}`;

  const supportWebsite = t("HelpAndSupport.supportWebsite");

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="h6">
              {t("HelpAndSupport.resources")}
            </Typography>
            <Typography variant="body1">
              {t("HelpAndSupport.resourcesDescription")}
            </Typography>
            <EMPButton
              variant="text"
              buttonElement="button"
              onClick={() => downloadFile()}
              leftIcon={<GetAppIcon />}
            >
              {t("HelpAndSupport.downloadFAQs")}
            </EMPButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h6">
              {t("HelpAndSupport.supportContactHeader")}
            </Typography>
            <div>
              <Typography variant="body1">
                {t("HelpAndSupport.emailTitle")}{" "}
                <a
                  href={mailToSupportEmail}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#066FEF" }}
                  onClick={() => trackUserAction("click to email")}
                >
                  {t("HelpAndSupport.supportEmail")}
                </a>
              </Typography>

              <Typography variant="body1">
                {t("HelpAndSupport.phoneTitle")}{" "}
                {t("HelpAndSupport.supportPhone")}
              </Typography>
              <Typography variant="body1">
                {t("HelpAndSupport.hoursTitle")}{" "}
                {t("HelpAndSupport.supportHours")}
              </Typography>
              <Typography variant="body1">
                {t("HelpAndSupport.websiteTitle")}{" "}
                <a
                  href={supportWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#066FEF" }}
                  onClick={() =>
                    trackUserAction("click to website resource center")
                  }
                >
                  {t("HelpAndSupport.supportWebsiteLink")}
                </a>
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h6">
              {t("HelpAndSupport.technicalHelp")}
            </Typography>
            <Typography variant="body1">
              {t("HelpAndSupport.technicalHelpDescription")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h6">
              {t("HelpAndSupport.technicalSupportContactHeader")}
            </Typography>
            <div>
              <Typography variant="body1">
                {t("HelpAndSupport.emailTitle")}{" "}
                <a
                  href={mailToTechSupportEmail}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#066FEF" }}
                  onClick={() => trackUserAction("click to email")}
                >
                  {t("HelpAndSupport.techSupportEmail")}
                </a>
              </Typography>
              <Typography variant="body1">
                {t("HelpAndSupport.phoneTitle")}{" "}
                {t("HelpAndSupport.techSupportPhone")}
              </Typography>
              <Typography variant="body1">
                {t("HelpAndSupport.hoursOfOperation")}{" "}
                {t("HelpAndSupport.techSupportHours")}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default HelpAndSupport;
