import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import styles from "../NACustomerOrders.module.scss";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import TabsPagination from "./TabsPagination";
import ExpandableTableRow from "../../../shared/components/EMPExpandableTableRow/EMPExpandableTableRow";
import GetDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import {
  VENDOR_CATEGORY_COMPANY,
  VENDOR_CATEGORY_DEALER,
  VENDOR_CATEGORY_RRC,
} from "../../../common/Constants";
import { useQuery } from "@tanstack/react-query";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import EMPLoader from "../../../shared/components/EMPLoader/EMPLoader";
import DealerReportingClient from "../../../clients/DealerReportingClient";
import DealerDeliveryToolClient from "../../../clients/DealerDeliveryToolClient";

const ModelEOrders = (props) => {
  const [openRows, setOpenRows] = useState({});
  const [allRowsExpanded, setAllRowsExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    AnalyticsService.trackPageLoadEvent("fv:emp:customer handling", props.user);
  }, [props.user]);

  const {
    isLoading: loadingOrders,
    data: orders,
    isError: ordersError,
    isFetching,
  } = useQuery({
    queryKey: [
      "orders",
      { tab: "model_e_orders", page, commonId: props.user.commonDealerId },
    ],
    queryFn: () =>
      CustomerReservationsClient.getCustomerOrders(
        props.user.token,
        props.user.commonDealerId,
        "model_e_orders",
        props.user.lang,
        page,
        "byDate"
      ),
    staleTime: 60 * 1000,
  });

  const gatherOrderIdList = (orders) => {
    const orderIds = orders.map((order) => order.id).join(",");
    return DealerReportingClient.getCustomerNames(props.user, orderIds);
  };

  const { isFetching: namesLoading, data: names } = useQuery({
    queryKey: [
      "customerNames",
      { tab: "model_e_orders", page, commonId: props.user.commonDealerId },
    ],
    queryFn: () => gatherOrderIdList(orders.reservations),
    enabled: !!orders,
    select: ({ data }) => data.customerNameMap,
    staleTime: 60 * 1000,
  });

  const {
    isFetching: loadingAppointments,
    data: appointmentData,
    isError: appointmentsError,
  } = useQuery({
    queryKey: ["appointments", props.user.commonDealerId],
    queryFn: () =>
      DealerDeliveryToolClient.getDeliveryAppointments(
        props.token,
        props.user.dealerId
      ),
    select: ({ data }) => {
      return data.appointmentsByOrderId;
    },
    staleTime: Infinity,
  });

  const handleRow = (id) => {
    const isOpen = !!openRows[id];

    setOpenRows({
      ...openRows,
      [id]: !isOpen,
    });
  };

  const controlAllRows = (rows, value) => {
    const allRows = {};
    rows.forEach((row) => (allRows[row.id] = value));

    setAllRowsExpanded(value);
    setOpenRows({
      ...openRows,
      ...allRows,
    });
  };

  const getAppointmentDate = (appointment) =>
    appointment && appointment.date ? appointment.date : null;

  const validAppointment = (order, key) => {
    return (
      appointmentData &&
      appointmentData[order.id] &&
      appointmentData[order.id][key]
    );
  };

  const modelELabel = (order) => {
    const isModelE =
      order.journeyType === "Model-E" || order.journeyType === "Model-e";

    if (!isModelE) return null;

    const labelMap = [
      {
        condition: UpperCaseText(order.vendorCategory) === VENDOR_CATEGORY_RRC,
        label: "RRC",
        testId: "rrc-order-label",
      },
      {
        condition:
          UpperCaseText(order.vendorCategory) === VENDOR_CATEGORY_DEALER,
        label: "In stock",
        testId: "dealer-order-label",
      },
      {
        condition:
          UpperCaseText(order.vendorCategory) === VENDOR_CATEGORY_COMPANY,
        label: "From plant",
        testId: "from-plant-label",
      },
    ];

    const matchedLabel = labelMap.find((item) => item.condition);

    return matchedLabel ? (
      <Typography variant="body1" data-testid={matchedLabel.testId}>
        {matchedLabel.label}
      </Typography>
    ) : null;
  };

  return (
    <div className={styles.modeleTableWrapper}>
      <Grid
        container
        className={styles.expandContainer}
        justifyContent="flex-end"
      >
        <Grid
          item
          data-testid="collapse-button"
          onClick={() => controlAllRows(orders.reservations, !allRowsExpanded)}
        >
          {`${allRowsExpanded ? "Collapse" : "Expand"} all rows`}
        </Grid>
      </Grid>

      <TableContainer component={Paper} square>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{props.t("ModelEOrders.inventorySource")}</TableCell>
              <TableCell>{props.t("ModelEOrders.customer")}</TableCell>
              <TableCell>{props.t("ModelEOrders.delivery")}</TableCell>
              <TableCell>{props.t("ModelEOrders.Action")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingOrders || loadingAppointments || isFetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <EMPLoader loadingMessage="Loading orders" />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : ordersError || appointmentsError ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <div>
                        Something went wrong. Please refresh the page to try
                        again.
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              orders.reservations.map((order, index) => {
                return (
                  <ExpandableTableRow
                    id={"row" + index}
                    key={order.id}
                    order={order}
                    orderLabel={modelELabel(order)}
                    status={GetDeliveryStatus(
                      validAppointment(order, "deliveryAppointment")
                    )}
                    appointmentDate={getAppointmentDate(
                      validAppointment(order, "deliveryAppointment")
                    )}
                    customerName={
                      namesLoading ? (
                        <Skeleton animation={false} width={100} />
                      ) : (
                        names[order.id]
                      )
                    }
                    itemNumber={index}
                    onShow={() => handleRow(order.id)}
                    isOpen={!!openRows[order.id]}
                    rowLink={`/customer-handling/${order.id}`}
                    data-testid="expandableTableRow"
                  ></ExpandableTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!props.hidePagination && orders && (
        <TabsPagination
          numberOfPages={orders.pagination.numberOfPages}
          currentPage={orders.pagination.currentPage}
          handleSpecificPage={(newPage) => setPage(newPage)}
          handlePrevious={() => setPage((old) => Math.max(old - 1, 0))}
          handleNext={() => setPage((old) => old + 1)}
        />
      )}
    </div>
  );
};

export default withTranslation("emp")(ModelEOrders);
