import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FINANCE_PRODUCT_TYPE } from "../../common/Constants";

export function useCanAutoAcceptDeposit(orderId) {
  const [autoAccept, setAutoAccept] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const { customerReservation } = queryClient.getQueryData([
      "order",
      { orderId },
    ]);

    if (!customerReservation) {
      return setAutoAccept(false);
    }

    const { purchaseInfo } = customerReservation;

    if (purchaseInfo?.acceptRejectStatus === "accepted") {
      return setAutoAccept(false);
    }

    const autoAcceptablePaymentTypes = [
      FINANCE_PRODUCT_TYPE.OTHER,
      FINANCE_PRODUCT_TYPE.CASH,
    ];

    if (purchaseInfo) {
      const isPurchaseTypeValid =
        Boolean(purchaseInfo.purchaseType) &&
        autoAcceptablePaymentTypes.some(
          (type) =>
            type.toLowerCase() === purchaseInfo.purchaseType.toLowerCase()
        );

      setAutoAccept(isPurchaseTypeValid);
    } else {
      setAutoAccept(false);
    }
  }, [orderId]);

  return autoAccept;
}
