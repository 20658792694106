import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import EMPAccordion from "../../../../shared/components/EMPAccordion/EMPAccordion";
import EMPInteractiveList from "../../../../shared/components/EMPInteractiveList/EMPInteractiveList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeliveryClient from "../../../../clients/DeliveryClient";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";
import EMPTextAreaField from "../../../../shared/components/EMPTextAreaField/EMPTextAreaField";
import EMPInputField from "../../../../shared/components/EMPInputField/EMPInputField";
import { withTranslation } from "react-i18next";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";
import EMPToggleButtons from "../../../../shared/components/EMPToggleButtons/EMPToggleButtons";
import usePreDeliveryTasks from "../../../../shared/hooks/usePreDeliveryTasks";
import { toggleButtonItems } from "../../../../shared/constants/PreDeliveryTaskConstants";

const initialFormState = {
  taskType: "",
  taskName: "",
  taskDescription: "",
  taskUrl: "",
};

const vehicleTypeMap = {
  evTasks: "ev",
  iceTasks: "ice",
};

const Loader = ({ t }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <CircularProgress size={24} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align="center">
          {t("DeliveryTasks.loadingTasks")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ConfirmationDialog = ({
  open,
  type,
  dialogTitle,
  handleClose,
  id,
  user,
  t,
  updateTasks,
  vehicleType,
}) => {
  const queryClient = useQueryClient();
  const deleteTask = useMutation({
    mutationFn: (id) =>
      DeliveryClient.removePreDeliveryTask(user.token, { id }),
    onSuccess: ({ data }) => {
      handleClose();
      queryClient.setQueryData(["preDeliveryTasks"], data);
      updateTasks(data);
      AnalyticsService.trackOnClickEvent(
        `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}`,
        `emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}:content:save`,
        `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}:content action`,
        user
      );
    },
  });

  const trackOpen = () => {
    AnalyticsService.trackPageLoadEvent(
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}`,
      user
    );
  };

  const secondaryAction = () => {
    handleClose();
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}`,
      `emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}:content:cancel`,
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:${vehicleTypeMap[vehicleType]}:content action`,
      user
    );
  };

  const checkToDisableRemoveButton = () => {
    return (
      deleteTask.isLoading ||
      deleteTask.isError ||
      !user.permissions?.rolePermissions?.hasOwnProperty(
        "preDeliveryTasksSubmit"
      )
    );
  };

  return (
    <EMPDialog
      open={open}
      dialogTitle={dialogTitle}
      hasCloseIcon
      handleClose={handleClose}
      hasActionButton
      actionButtonText={t("DeliveryTasks.removeTask")}
      onConfirm={() => deleteTask.mutate(id)}
      actionLoading={deleteTask.isLoading}
      disableActionButton={checkToDisableRemoveButton()}
      hasSecondaryActionButton
      secondaryActionButtonText={t("common.cancel")}
      onSecondaryAction={secondaryAction}
      onEnter={trackOpen}
    >
      <Box mb={2}>
        <Typography variant="body1">
          {t("DeliveryTasks.deleteConfirmationText")}
        </Typography>
      </Box>
      {deleteTask.isError && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="error">
            {`${t("common.error")}: ${deleteTask.error.message}`}
            <br />
            {t("DeliveryTasks.taskCouldNotBeRemoved")}
          </Typography>
        </Grid>
      )}
    </EMPDialog>
  );
};

const TasksList = ({
  classes,
  tasks,
  actionOnClick,
  userAddedTaskLimit,
  type,
  primaryActionOnClick,
  secondaryActionOnClick,
  t,
  appear,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  tasks && tasks.sort((a, b) => b.id - a.id);

  const defaultTasks = tasks && tasks.filter((task) => task.isDefault);
  const userAddedTasks = tasks && tasks.filter((task) => !task.isDefault);

  const descriptions = {
    vehicle: {
      heading: t("DeliveryTasks.vehicleTasksHeading"),
      subHeading: t("DeliveryTasks.vehicleTasksSubHeading"),
    },
    dealer: {
      heading: t("DeliveryTasks.dealerTasksHeading"),
      subHeading: t("DeliveryTasks.dealerTasksSubHeading"),
    },
  };

  return (
    <Box>
      <EMPAccordion
        heading={descriptions[type].heading}
        subHeading={descriptions[type].subHeading}
        expanded={isExpanded}
        onChange={(_, expanded) => setIsExpanded(expanded)}
      >
        <Box mb={2}>
          <Typography variant="body1" classes={classes}>
            You can add up to {userAddedTaskLimit} {type} tasks.
          </Typography>
        </Box>
        <Box>
          <EMPInteractiveList
            testId={`${type}-user-list`}
            hasListAction
            listActionText={`Add new ${type} task`}
            listActionOnClick={actionOnClick}
            items={
              userAddedTasks && userAddedTasks.length > 0 && userAddedTasks
            }
            disableListAction={
              userAddedTasks && userAddedTasks.length >= userAddedTaskLimit
            }
            hasActions
            primaryActionText={t("DeliveryTasks.editTask")}
            primaryActionOnClick={primaryActionOnClick}
            secondaryActionText={t("DeliveryTasks.removeTask")}
            secondaryActionOnClick={secondaryActionOnClick}
            appear={appear}
          ></EMPInteractiveList>
        </Box>

        {defaultTasks && defaultTasks.length > 0 && (
          <>
            <Box mt={5} mb={2}>
              <Typography variant="body1" classes={classes}>
                {t("DeliveryTasks.requiredTasks")}
              </Typography>
            </Box>
            <Box>
              <EMPInteractiveList
                items={defaultTasks}
                testId={`${type}-default-list`}
                disableAppear
              ></EMPInteractiveList>
            </Box>
          </>
        )}
      </EMPAccordion>
    </Box>
  );
};

const TaskEditor = ({
  user,
  open,
  handleClose,
  action,
  type,
  formData,
  t,
  vehicleType,
  updateTasks,
}) => {
  const [payload, setPayload] = useState({
    ...initialFormState,
  });

  const queryClient = useQueryClient();

  function hasKeys(obj) {
    return Object.keys(obj).length > 0;
  }

  useEffect(() => {
    if (action === "edit" && hasKeys(formData)) {
      setPayload(formData);
    }
  }, [formData, action]);

  const trackOpenAndClearForm = () => {
    AnalyticsService.trackPageLoadEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${vehicleTypeMap[vehicleType]}`,
      user
    );

    collectFormData("taskType", type);
  };

  const confirmAction = (payload) => {
    const mappedVehicleType = vehicleTypeMap[vehicleType];

    const updatedPayload = {
      vehicleType: mappedVehicleType,
      ...payload,
    };

    action === "edit"
      ? updateTaskMutation.mutate(updatedPayload)
      : addTaskMutation.mutate(updatedPayload);
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}`,
      `emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}:content:save`,
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}:content action`,
      user
    );
  };

  const secondaryAction = () => {
    const mappedVehicleType = vehicleTypeMap[vehicleType];
    handleClose();
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}`,
      `emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}:content:cancel`,
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:${mappedVehicleType}:content action`,
      user
    );
  };

  const addTaskMutation = useMutation({
    mutationFn: (payload) =>
      DeliveryClient.addPreDeliveryTask(user.token, user.dealerId, payload),
    retry: 0,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["preDeliveryTasks"], data);
      updateTasks(data);
      handleClose();
    },
  });

  const updateTaskMutation = useMutation({
    mutationFn: (payload) =>
      DeliveryClient.updatePreDeliveryTask(user.token, user.dealerId, payload),
    retry: 1,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["preDeliveryTasks"], data);
      updateTasks(data);
      handleClose();
    },
  });

  const checkToDisableAddOrEditButton = () => {
    return (
      !hasTaskDetails(payload) ||
      addTaskMutation.isLoading ||
      updateTaskMutation.isLoading ||
      !user.permissions?.rolePermissions?.hasOwnProperty(
        "preDeliveryTasksSubmit"
      )
    );
  };

  const collectFormData = (fieldKey, value) => {
    setPayload((prevState) => {
      return { ...prevState, [fieldKey]: value };
    });
  };

  const isValidUrlOrEmpty = (url) => {
    const urlRegex = new RegExp("^(http|https)://", "i");
    if (url === "") {
      return true;
    } else {
      return urlRegex.test(url);
    }
  };

  const hasTaskDetails = (task) =>
    task.hasOwnProperty("taskName") &&
    task.taskName !== "" &&
    task.hasOwnProperty("taskDescription") &&
    task.taskDescription !== "" &&
    task.hasOwnProperty("taskUrl") &&
    isValidUrlOrEmpty(task.taskUrl);

  return (
    <EMPDialog
      dialogTitle={`${action === "edit" ? "Edit" : "Add new"} ${type} task`}
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      onEnter={trackOpenAndClearForm}
      onExit={() => {
        addTaskMutation.reset();
        updateTaskMutation.reset();
        setPayload(initialFormState);
      }}
      hasActionButton
      actionButtonText="Save"
      onConfirm={() => confirmAction(payload)}
      hasSecondaryActionButton
      secondaryActionButtonText="Cancel"
      onSecondaryAction={() => secondaryAction()}
      actionLoading={addTaskMutation.isLoading || updateTaskMutation.isLoading}
      disableActionButton={checkToDisableAddOrEditButton()}
    >
      <Box mb={2}>
        <Typography variant="body1">
          {`You can ${action} your own ${type} task here.`}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <Box mb={2}>
            <EMPTextAreaField
              testId="task-name"
              label={t("DeliveryTasks.formLabels.taskName")}
              required
              hasCharacterCount
              maxCharacters={50}
              minRows={2}
              value={payload.taskName}
              onChange={(e) => collectFormData("taskName", e.target.value)}
            ></EMPTextAreaField>
          </Box>
          <Box mb={2}>
            <EMPTextAreaField
              testId="task-description"
              label={t("DeliveryTasks.formLabels.taskDetails")}
              required
              hasCharacterCount
              maxCharacters={50}
              minRows={3}
              value={payload.taskDescription}
              onChange={(e) =>
                collectFormData("taskDescription", e.target.value)
              }
            ></EMPTextAreaField>
          </Box>
          <Box mb={2}>
            <InputLabel disableAnimation shrink={false}>
              {t("DeliveryTasks.formLabels.additionalInfo")}
            </InputLabel>
            <EMPInputField
              testId="task-url"
              value={payload.taskUrl}
              helperText={
                !isValidUrlOrEmpty(payload.taskUrl)
                  ? t("DeliveryTasks.helperText1")
                  : t("DeliveryTasks.helperText2")
              }
              onChange={(e) => collectFormData("taskUrl", e.target.value)}
              error={!isValidUrlOrEmpty(payload.taskUrl)}
            />
          </Box>
        </Grid>
        {addTaskMutation.isError && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="error">
              {`${t("common.error")}: ${addTaskMutation.error.message}`}
              <br />
              {t("DeliveryTasks.taskCouldNotBeSaved")}
            </Typography>
          </Grid>
        )}
        {updateTaskMutation.isError && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="error">
              {`${t("common.error")}: ${updateTaskMutation.error.message}`}
              <br />
              {t("DeliveryTasks.taskCouldNotBeEdited")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </EMPDialog>
  );
};

const PreDeliveryTasks = ({ user, t }) => {
  const [vehicleType, setVehicleType] = useState("evTasks");
  const [isApiUpdate, setIsApiUpdate] = useState(false);
  const { tasks, loading, error, filterTasks, updateTasks } =
    usePreDeliveryTasks(vehicleType);

  const [editorOpen, setEditorOpen] = useState({
    open: false,
    action: "",
    type: "",
    payload: {},
  });

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState({
    open: false,
    type: "",
    id: null,
    vehicleType: "",
  });

  const handleVehicleTypeChange = (value) => {
    setIsApiUpdate(false);
    setVehicleType(value);
    setTimeout(() => filterTasks(value), 100);
  };

  const handleTasksUpdate = (newData, currentVehicleType) => {
    setIsApiUpdate(true);
    updateTasks(newData, currentVehicleType);
  };

  useEffect(() => {
    const mappedVehicleType = vehicleTypeMap[vehicleType];

    AnalyticsService.trackPageLoadEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${mappedVehicleType}`,
      user
    );
  }, [user, vehicleType]);

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={12} sm={10} md={8}>
            <Box mt={2} mb={5}>
              <Typography variant="body1">
                {t("DeliveryTasks.pageSubHeading")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={5}>
              {loading ? (
                <Loader t={t} />
              ) : error ? (
                <Typography variant="subtitle1" color="error">
                  {t("common.error")}
                  <br />
                  {t("DeliveryTasks.listFailedToLoad")}
                </Typography>
              ) : (
                <>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={4}
                  >
                    <Box
                      gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4" }}
                    >
                      <Box mb={4}>
                        <EMPToggleButtons
                          groupHeading="Tasks for vehicle type"
                          value={vehicleType}
                          onChange={(value) => handleVehicleTypeChange(value)}
                          items={toggleButtonItems}
                        />
                      </Box>
                    </Box>
                    <Box gridColumn="span 12"></Box>
                    <Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 8",
                      }}
                    >
                      <TasksList
                        appear={isApiUpdate}
                        t={t}
                        type="vehicle"
                        tasks={tasks.vehiclePreDeliveryTasks}
                        actionOnClick={() =>
                          setEditorOpen({
                            open: true,
                            action: "add",
                            type: "vehicle",
                            payload: {},
                          })
                        }
                        userAddedTaskLimit={10}
                        primaryActionOnClick={(payload) =>
                          setEditorOpen({
                            open: true,
                            action: "edit",
                            type: "vehicle",
                            payload,
                          })
                        }
                        secondaryActionOnClick={(id) =>
                          setConfirmationDialogOpen({
                            open: true,
                            type: "vehicle",
                            id,
                            vehicleType,
                          })
                        }
                      ></TasksList>
                      <TasksList
                        appear={isApiUpdate}
                        t={t}
                        type="dealer"
                        tasks={tasks.dealerPreDeliveryTasks}
                        actionOnClick={() =>
                          setEditorOpen({
                            open: true,
                            action: "add",
                            type: "dealer",
                            payload: {},
                          })
                        }
                        userAddedTaskLimit={10}
                        primaryActionOnClick={(payload) =>
                          setEditorOpen({
                            open: true,
                            action: "edit",
                            type: "dealer",
                            payload,
                          })
                        }
                        secondaryActionOnClick={(id) =>
                          setConfirmationDialogOpen({
                            open: true,
                            type: "dealer",
                            id,
                            vehicleType,
                          })
                        }
                      ></TasksList>
                      <TaskEditor
                        t={t}
                        user={user}
                        open={editorOpen.open}
                        type={editorOpen.type}
                        action={editorOpen.action}
                        formData={editorOpen.payload}
                        vehicleType={vehicleType}
                        handleClose={() =>
                          setEditorOpen({ open: false, type: "", payload: {} })
                        }
                        updateTasks={(newData) =>
                          handleTasksUpdate(newData, vehicleType)
                        }
                      ></TaskEditor>
                      <ConfirmationDialog
                        updateTasks={(newData) =>
                          handleTasksUpdate(newData, vehicleType)
                        }
                        t={t}
                        user={user}
                        open={confirmationDialogOpen.open}
                        type={confirmationDialogOpen.type}
                        dialogTitle={t(
                          "DeliveryTasks.deleteConfirmationHeading"
                        )}
                        id={confirmationDialogOpen.id}
                        vehicleType={confirmationDialogOpen.vehicleType}
                        handleClose={() =>
                          setConfirmationDialogOpen({
                            open: false,
                            type: "",
                            id: null,
                            vehicleType,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm="auto" md={3} lg={5}></Grid>
      </Grid>
    </section>
  );
};

export default withTranslation("emp")(PreDeliveryTasks);
