// Purchase Request Statuses
export const ACCEPT_REJECT_STATUS_CREATED = "accept-reject-status-created";
export const ACCEPT_REJECT_STATUS_ACCEPTED = "accept-reject-status-accepted";
export const ACCEPT_REJECT_STATUS_CANCELED = "accept-reject-status-canceled";
export const ACCEPT_REJECT_STATUS_CANCELLED = "accept-reject-status-cancelled";
// Purchase Deposit Statuses
export const PURCHASE_DEPOSIT_STATUS_NOT_SCHEDULED =
  "purchase-deposit-status-not-scheduled";
export const PURCHASE_DEPOSIT_STATUS_AUTO_ACCEPTED =
  "purchase-deposit-status-auto-accept";
export const PURCHASE_DEPOSIT_STATUS_CREATED =
  "purchase-deposit-status-created";
export const PURCHASE_DEPOSIT_STATUS_ACCEPTED =
  "purchase-deposit-status-accepted";
export const PURCHASE_DEPOSIT_STATUS_REJECTED =
  "purchase-deposit-status-rejected";
export const PURCHASE_DEPOSIT_STATUS_REFUND = "purchase-deposit-status-refund";
// Delivery Statuses
export const DELIVERY_STATUS_NOT_SCHEDULED = "delivery-status-not-scheduled";
export const DELIVERY_STATUS_REQUESTED = "delivery-status-requested";
export const DELIVERY_STATUS_CONFIRMED = "delivery-status-confirmed";
export const DELIVERY_STATUS_COMPLETED = "delivery-status-completed";
// Trade In Statuses
export const TRADE_IN_STATUS_NOT_SCHEDULED = "trade-in-status-not-scheduled";
export const TRADE_IN_STATUS_EVALUATE = "trade-in-status-evaluate";
export const TRADE_IN_STATUS_COMPLETED = "trade-in-status-completed";
// Time of Sale Statuses
export const TIME_OF_SALE_ATTEST = "time-of-sale-report-attest";
export const TIME_OF_SALE_COMPLETED = "time-of-sale-report-completed";
// EV Power Promise Statuses
export const EV_POWER_PROMISE_ATTEST = "ev-power-promise-attest";
export const EV_POWER_PROMISE_COMPLETED = "ev-power-promise-completed";
// RouteOne Status
export const ROUTEONE_STATUS_CREATED = "route-one-status-created";
// Card Sort Order
export const CARD_STATUS_ORDER = {
  "action-required": 1,
  "action-not-available": 2,
  "action-completed": 3,
};
