import React, { useState } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
} from "@mui/material";

export default function EMPToggleButtons({ groupHeading, items, onChange }) {
  const [alignment, setAlignment] = useState(items[0]?.value);

  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      onChange(newAlignment);
    }
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="caption">{groupHeading}</Typography>
      </Box>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        fullWidth
        size="large"
      >
        {items.map((item) => (
          <ToggleButton value={item.value} key={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}
