import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import stylesOverride from "./DealerSearchResultStylesOverride";
import styles from "./DealerSearchResults.module.scss";
import { withTranslation } from "react-i18next";
import { publish } from "../../common/PubSub";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import { BRAND_NAMES, LINCOLN_ENGLISH } from "../../common/Constants";
import TitleCase from "../../utils/TitleCase";
import EMPEmptyState from "../../shared/components/EMPEmptyState/EMPEmptyState";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import MotionChevronRight from "../../shared/Icons/MotionChevronRight";

const DealerSearchResults = (props) => {
  const market = props.user.market;
  const getDealerTypeText = (dealerType) => {
    if (dealerType.toUpperCase() === "HUB") {
      return props.t("fieldView.mainDealer");
    }
    if (dealerType.toUpperCase() === "BRAN") {
      return props.t("fieldView.branchDealer");
    }
  };

  const formatPostalCode = (postalCode) => {
    if (postalCode) {
      return postalCode.substring(0, 5);
    }
  };

  function onSubmit(dealer) {
    props.updateCurrentDealer(dealer);
    props.updateBrand(dealer.commonId.charAt(dealer.commonId.length - 1));
    publish("brandChanged", dealer.commonId.charAt(dealer.commonId.length - 1));
    if (IS_USA_MARKET(props.user.market)) {
      if (!IS_FORD_BRAND(props.user.brand)) {
        props.i18n.changeLanguage(LINCOLN_ENGLISH);
      } else {
        props.i18n.changeLanguage("en");
      }
    }
    props.user.setLanguageCode(props.i18n.language);
    props.history.push("/dashboard");
  }

  return (
    <div>
      <TableContainer component={Paper} square>
        <Table>
          <TableHead className={styles.tableHead}>
            <TableRow
              style={
                props.dealerBrand === "F"
                  ? stylesOverride.tableHeaderFord
                  : stylesOverride.tableHeaderLincoln
              }
            >
              <TableCell>
                <Typography variant="subtitle2">
                  {props.t("fieldView.dealerName")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  {props.t("fieldView.salesCode")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  {props.t("fieldView.paCode")}
                </Typography>
              </TableCell>
              {IS_CANADA_MARKET(market) && (
                <>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {props.t("fieldView.dealerType")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {props.t("fieldView.brand")}
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell>
                <Typography variant="subtitle2">
                  {props.t("fieldView.dealerAddress")}
                </Typography>
              </TableCell>
              <TableCell style={stylesOverride.cellHeader} />
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {props.dealers.map((dealer, index) => (
              <TableRow id={"row" + index} key={dealer.paCode}>
                <TableCell style={stylesOverride.cellBody}>
                  <Typography variant="body2">{dealer.dealerName}</Typography>
                </TableCell>
                <TableCell style={stylesOverride.cellBody}>
                  <Typography variant="body2">
                    {dealer.salesCode || <EMPEmptyState />}
                  </Typography>
                </TableCell>
                <TableCell style={stylesOverride.cellBody}>
                  <Typography variant="body2">
                    {dealer.paCode || <EMPEmptyState />}
                  </Typography>
                </TableCell>
                {IS_CANADA_MARKET(market) && (
                  <>
                    <TableCell style={stylesOverride.cellBody}>
                      <Typography variant="body2">
                        {dealer.dealerType ? (
                          getDealerTypeText(dealer.dealerType)
                        ) : (
                          <span style={{ paddingLeft: "20%" }}>-</span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell style={stylesOverride.cellBody}>
                      <Typography variant="body2">
                        {dealer.commonId ? (
                          IS_FORD_BRAND(
                            dealer.commonId.charAt(dealer.commonId.length - 1)
                          ) ? (
                            TitleCase(BRAND_NAMES.FORD)
                          ) : (
                            TitleCase(BRAND_NAMES.LINCOLN)
                          )
                        ) : (
                          <EMPEmptyState />
                        )}
                      </Typography>
                    </TableCell>
                  </>
                )}
                <TableCell style={stylesOverride.cellBody}>
                  {dealer.address && dealer.address.street !== null ? (
                    <>
                      <Typography variant="body2">
                        {dealer.address.street}
                      </Typography>
                      <Typography variant="body2">
                        {dealer.address.city},{" "}
                        {dealer.address.state || dealer.address.province}{" "}
                        {IS_USA_MARKET(market)
                          ? formatPostalCode(dealer.address.postalCode)
                          : dealer.address.postalCode}
                        {dealer.address.country
                          ? `, ${dealer.address.country}`
                          : ""}
                      </Typography>
                    </>
                  ) : (
                    <EMPEmptyState />
                  )}
                </TableCell>
                <TableCell style={stylesOverride.dealer}>
                  <EMPButton
                    testId="viewDealerLink"
                    variant="text"
                    rightIcon={<MotionChevronRight />}
                    onClick={() => onSubmit(dealer)}
                  >
                    {props.t("fieldView.viewDealer")}
                  </EMPButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default withTranslation("emp")(DealerSearchResults);
